<template>
	<div>
		<explorer-view :explorer="modal.explorer" />
		<base-button class="choose-btn" @click="modal.chooseFile()">
			{{lang.choose_file}}
		</base-button>
	</div>
</template>

<script>
import BaseButton from '@a/components/atoms/BaseButton/BaseButton'
import ExplorerView from '~/fileManager/admin/components/ExplorerView/ExplorerView'

export default {
	components: {
		ExplorerView,
		BaseButton
	},
	props: ['modal'],
	computed: {
		lang () {
			return this.$app.translator.active.translations['file-manager']
		}
	}
}
</script>

<style scoped lang="scss">
.choose-btn {
	margin: 2rem 0;
}
</style>
