import { ListItem } from '~/fileManager/admin/core/fileManager/ListItem'

class ListFileItem extends ListItem {
	async download (app) {
		const service = app.getService('rext')

		const itemDownloadingStatus = this.explorer.createFileDownloadingStatus(this)
		const bufferData = await service.getFile(itemDownloadingStatus)

		const fileUrl = window.URL.createObjectURL(new Blob([bufferData]))
		const fileLink = document.createElement('a')

		fileLink.href = fileUrl
		fileLink.download = this.item.name
		document.body.appendChild(fileLink)

		fileLink.click()

		document.body.removeChild(fileLink)

		URL.revokeObjectURL(fileUrl)
	}
}

export { ListFileItem }
