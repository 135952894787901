<template>
	<thead class="thead">
		<tr class="table-row">
			<th class="table-item">
				<base-svg class="icon" v-if="!areAllItemsChecked" name="explorer_checkbox" @click="chooseAll" />
				<base-svg class="icon" v-else name="explorer_checkbox_checked" @click="unchooseAll" />
			</th>
			<th class="table-item unsortable">{{lang.headers.view}}</th>
			<th class="table-item" :class="`${header}-header`" v-for="header in headers" :key="header" @click="explorer.sortItems(header)">
				<div class="inner-wrapper">
					<span class="column-name">{{lang.headers[header]}}</span>
					<div class="arrow-wrapper">
						<div v-if="explorer.orderBy !== header || !explorer.orderType">
							<base-svg class="list-svg" name="sort_arrows"/>
						</div>
						<div v-else-if="explorer.orderType === 'ASC'">
							<base-svg class="list-svg" name="sort_arrow_up"/>
						</div>
						<div v-else-if="explorer.orderType === 'DESC'">
							<base-svg class="list-svg" name="sort_arrow_down"/>
						</div>
					</div>
				</div>
			</th>
			<th class="table-item"></th>
		</tr>
	</thead>
</template>

<script>
import BaseSvg from '@a/components/atoms/BaseSvg/BaseSvg'
import mixin from '~/fileManager/admin/mixins/ExplorerHeadersMixin'

export default {
	mixins: [mixin],
	components: {
		BaseSvg
	},
	props: {
		explorer: Object
	},
	computed: {
		lang () {
			return this.$app.translator.active.translations['file-manager']
		},
		areAllItemsChecked () {
			return this.explorer.choosedItems.length === this.explorer.sortedItems.length
		}
	},
	methods: {
		chooseAll () {
			this.explorer.chooseAllItems()
		},
		unchooseAll () {
			this.explorer.unchooseAllItems()
		}
	}
}
</script>

<style scoped lang="scss">
.thead {
	position: sticky;
	top: 0;
	z-index: 6;
	background: #f4f4f4;
	.table-row {
		.table-item {
			text-align: left;
			padding: 7px 20px;

			.inner-wrapper {
				&:hover {
					cursor: pointer;
				}
			}

			@include media-breakpoint-up(md) {
				padding-left: 20px;
			}
			&.sortable {
				cursor: pointer;
			}
			&.unsortable {
				min-width: 160px;
				text-align: center;
				padding-bottom: 10px;
			}
			&.name-header {
				min-width: 250px;
			}
			.column-name {
				vertical-align: top;
			}
			.arrow-wrapper {
				display: inline-block;
			}
			.list-svg {
				width: 15px;
				height: 15px;
			}
		}
	}
	.inner-wrapper {
		display: flex;
		justify-content: center;
		width: 120px;
	}
}

.icon {
	width: 15px;
	height: 15px;
	&:hover {
		cursor: pointer;
	}
}
</style>
