<script>
import merge from 'lodash.merge'

const variants = {
	paragraph: {
		color: '',
		tag: 'p',
		size: '',
		uppercase: false,
		italic: false,
		weight: false
	},
	'page-header': {
		color: 'black',
		tag: 'h1',
		size: 'lg',
		uppercase: false,
		italic: false,
		weight: false,
		center: true
	}
}

export default {
	name: 'BaseFont',
	functional: true,
	props: {
		variant: {
			type: String,
			default: 'paragraph',
			validator: (value) => {
				return [
					'paragraph',
					'page-header',
					'section-header',
					'medium-header'
				].includes(value)
			}
		},
		tag: {
			type: String,
			default: '',
			validator: (value) => {
				return [
					'',
					'p',
					'h1',
					'h2',
					'h3'
				].includes(value)
			}
		},
		color: {
			type: String,
			default: '',
			validator: (value) => {
				return [
					'',
					'white',
					'black',
					'grey'
				].includes(value)
			}
		},
		weight: {
			type: String,
			default: '',
			validator: (value) => {
				return [
					'',
					'medium',
					'bold'
				].includes(value)
			}
		},
		size: {
			type: String,
			default: '',
			validator: (value) => {
				return [
					'',
					'xs',
					'sm',
					'md',
					'lg',
					'xl'
				].includes(value)
			}
		},
		shadow: {
			type: Boolean,
			default: false
		},
		uppercase: {
			type: Boolean,
			default: false
		},
		italic: {
			type: Boolean,
			default: false
		},
		center: {
			Boolean,
			default: false
		}
	},
	render (h, { props, data, children }) {
		const chooser = type => props[type] ? props[type] : variants[props.variant][type]
		const tag = chooser('tag')
		const color = chooser('color')
		const fontSize = chooser('size')
		const fontWeight = chooser('weight')
		const shadow = chooser('shadow')
		const uppercase = chooser('uppercase')
		const italic = chooser('italic')
		const center = chooser('center')
		const shadowColor = {
			light: 'dark',
			dark: 'light',
			'light-grey': 'dark',
			grey: 'light'
		}[color] || 'dark'

		data.class = merge({}, data.class, {
			[`fc-${color}`]: color,
			[`fs-${fontSize}`]: fontSize,
			[`fw-${fontWeight}`]: fontWeight,
			[`fsh-${shadowColor}`]: shadow,
			fuc: uppercase,
			fi: italic,
			tac: center
		})
		return h(tag, data, children)
	}
}

</script>

<style lang="scss" scoped>
.fs {
	&-xl {
		font-size: 3rem;
		line-height: 3.9rem;
		@include media-breakpoint-up(md) {
			font-size: 4.5rem;
			line-height: 6rem;
		}
		@include media-breakpoint-up(xl) {
			font-size: 7rem;
			line-height: 10rem;
		}
	}
	&-lg {
		font-size: 2.2rem;
		line-height: 3.2rem;
	}
	&-md {
		font-size: 1.8rem;
		line-height: 3.2rem;
		@include media-breakpoint-up(md) {
			font-size: 2.4rem;
		}
		@include media-breakpoint-up(xl) {
			font-size: 3rem;
		}
	}
	&-sm {
		font-size: 1.8rem;
		line-height: 3.2rem;
		@include media-breakpoint-up(md) {
			font-size: 2rem;
		}
	}
	&-xs {
		font-size: 1.4rem;
		line-height: 2.5rem;
		@include media-breakpoint-up(md) {
			font-size: 1.6rem;
			line-height: 3.2rem;
		}
	}
}
.fc {
	&-grey {
		color: $grey-500;
	}
	&-white {
		color: $white;
	}
	&-black {
		color: $black;
	}
}
.fsh {
	&-light {
		text-shadow: $light-shadow;
	}
	&-dark {
		text-shadow: $dark-shadow;
	}
}
.fuc {
	text-transform: uppercase;
}
.fi {
	font-style: italic;
}
.fw {
	&-bold {
		font-weight: bold;
	}
	&-medium {
		font-weight: 500;
	}
	&-regular {
		font-weight: normal;
	}
}
.tac {
	text-align: center;
}
</style>
