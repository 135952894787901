var render = function render(){var _vm=this,_c=_vm._self._c;return ((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input.value),expression:"input.value"},{name:"mask",rawName:"v-mask",value:(_vm.input.mask),expression:"input.mask"}],class:{
        invalid: _vm.input.isInvalidAndTouched
    },attrs:{"placeholder":_vm.input.placeholder,"id":_vm.input.name,"disabled":_vm.input.disable,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.input.value)?_vm._i(_vm.input.value,null)>-1:(_vm.input.value)},on:{"blur":_vm.blurHandler,"change":function($event){var $$a=_vm.input.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.input, "value", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.input, "value", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.input, "value", $$c)}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input.value),expression:"input.value"},{name:"mask",rawName:"v-mask",value:(_vm.input.mask),expression:"input.mask"}],class:{
        invalid: _vm.input.isInvalidAndTouched
    },attrs:{"placeholder":_vm.input.placeholder,"id":_vm.input.name,"disabled":_vm.input.disable,"type":"radio"},domProps:{"checked":_vm._q(_vm.input.value,null)},on:{"blur":_vm.blurHandler,"change":function($event){return _vm.$set(_vm.input, "value", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input.value),expression:"input.value"},{name:"mask",rawName:"v-mask",value:(_vm.input.mask),expression:"input.mask"}],class:{
        invalid: _vm.input.isInvalidAndTouched
    },attrs:{"placeholder":_vm.input.placeholder,"id":_vm.input.name,"disabled":_vm.input.disable,"type":_vm.type},domProps:{"value":(_vm.input.value)},on:{"blur":_vm.blurHandler,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.input, "value", $event.target.value)}}})
}
var staticRenderFns = []

export { render, staticRenderFns }