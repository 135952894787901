<template>
	<header-layout :title="l.entities[alias].pages.list.title" v-if="page.ready">
		<base-element :element="page.list" />
		<button-wrapper left>
			<base-button
				v-if="page.new"
				@click="page.createNewObject()"
			>
				<base-svg name="plusIconList"></base-svg>
				{{l.entities[alias].pages.list.create_button}}
			</base-button>
		</button-wrapper>
	</header-layout>
</template>

<script>
import HeaderLayout from '@a/components/molecules/layouts/HeaderLayout.vue'
import BaseElement from '@a/components/atoms/BaseElement'
import ButtonWrapper from '@a/components/atoms/ButtonWrapper'
import BaseButton from '@a/components/atoms/BaseButton'
import BaseSvg from '@a/components/atoms/BaseSvg'
import { pageMixin } from '@a/pages/page.mixin'

export default {
	components: {
		HeaderLayout,
		BaseElement,
		ButtonWrapper,
		BaseButton,
		BaseSvg
	},
	mixins: [
		pageMixin
	]
}
</script>

<style lang="scss" scoped>
.button-wrapper {
	button {
		display: flex;
		align-items: center;
		font-size: 1.3rem;
		text-transform: uppercase;
	}
	svg {
		width: 13px;
		margin-right: 1rem;
	}
}
</style>
