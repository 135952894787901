<template>
	<td v-if="column.list.draggableOn" class="cell">
		<base-svg class="icon" name="drag_indicator" />
	</td>
</template>

<script>
import BaseSvg from '@a/components/atoms/BaseSvg'

export default {
	props: {
		column: Object,
		object: [Object, Array]
	},
	components: {
		BaseSvg
	}
}
</script>

<style lang="scss" scoped>
.icon {
	width: 15px;
	height: 15px;
	cursor: move;
}
.cell {
	width: 0%;
	padding-right: 20px;
}
</style>
