import { CRUDEntity } from '@a/core/entities/CRUDEntity'
class Moderator extends CRUDEntity {
	static alias = 'moderator'

	getName () {
		return this.name
	}
}

export { Moderator }
