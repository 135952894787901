<template>
	<component
		ref="component"
		:is="element.component"
		:element="element"
	/>
</template>

<script>
export default {
	props: {
		element: Object
	}
}
</script>
