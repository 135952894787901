import EditFormPage from '@a/pages/forms/EditFormPage.vue'
import { Page } from '../../Page'
import { Notification, NotificationType } from '@a/core/entities/Notification'

class EntityEditFormPage extends Page {
	static component = EditFormPage

	static get path () {
		return `/${this.entity}/edit/:id`
	}

	static get alias () {
		return `${this.entity}-edit`
	}

	ready = false
	object
	relations = []

	get objectId () {
		return this.app.vue.$route.params.id
	}

	get Entity () {
		return this.app.getEntity(this.constructor.entity)
	}

	async afterRedirect () {
		this.object = await this.Entity.getSingle(this.objectId, { relations: this.relations, response: 'full' })
		this.form = await this.getForm()
		await this.setFormData()
		this.form.onSubmit(this.save.bind(this))
		this.ready = true
	}

	async setFormData () {
		await this.form.create()
		await this.form.setInitialValues(this.object)
	}

	async save () {
		const values = await this.form.getValue()
		const { success, data } = await this.object.edit(values)

		this.notify(success, data)
	}

	notify (success, data) {
		if (success) Notification.create(NotificationType.SUCCESS, `entities.${this.constructor.entity}.pages.edit.notification.success`)
		else Notification.create(NotificationType.ERROR, `entities.${this.constructor.entity}.pages.edit.notification.error`)
	}

	async getForm () {
		const formSchema = await this.getFormSchema()
		const formGenerator = await this.app.createElement(formSchema)
		const form = await formGenerator()
		return form
	}
}

export { EntityEditFormPage }
