import { AuthorizationModule } from '~/authorization/admin'
import { FileManagerModule } from '~/fileManager/admin/index'
import { TranslationsModule } from '~/translations/admin/index'
import { SettingsModule } from '~/settings/admin'
import { ProjectModule } from '~/project/admin'

function useModules (app) {
	app.use(new AuthorizationModule())
	app.use(new TranslationsModule())
	app.use(new SettingsModule())
	app.use(new ProjectModule())
	app.use(new FileManagerModule())
}

export {
	useModules
}
