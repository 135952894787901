<template>
	<div
		class="multi-items"
		:class="{lastElement: last,
				firstElement: first}"
	>
		<div class="header">
			<div class="arrows-wrapper before-line-helper">
				<button @click="item.moveUp()" class="arrow-up">
					<base-svg class="arrow" name="arrow_section_up" />
				</button>
				<button @click="item.moveDown()" class="arrow-down">
					<base-svg class="arrow" name="arrow_section_down" />
				</button>
			</div>
			<div class="item-title" @click="item.toggle()">
				<span>
					{{item.label}}
				</span>
			</div>
			<div class="buttons-wrapper before-line-helper">
				<span @click="item.toggle()" class="icon-wrapper">
					<base-svg name="pencil"/>
					<template v-if="item.isOpen">
						{{l.elements.repeater.close}}
					</template>
					<template v-else>
						{{l.elements.repeater.edit}}
					</template>
				</span>
				<span @click="item.remove()" class="icon-wrapper icon-close">
					<base-svg name="closeIconList"/>
					{{l.elements.repeater.delete}}
				</span>
			</div>
		</div>
		<div class="body" :class="{open: item.isOpen}">
			<base-element v-for="element in item.elements" :key="element.attribute" :element="element"/>
		</div>
	</div>
</template>

<script>
import BaseElement from '@a/components/atoms/BaseElement'
import BaseSvg from '@a/components/atoms/BaseSvg'

export default {
	components: {
		BaseElement,
		BaseSvg
	},
	props: {
		item: Object,
		elementsLength: Number,
		index: Number
	},
	computed: {
		first () {
			return this.index === 0
		},
		last () {
			return this.elementsLength === this.index + 1
		},
		lang () {
			return this.$app.translator.active.translations
		},
		alias () {
			return this.item.constructor.alias
		}
	}
}
</script>
<style lang="scss" scoped>
.multi-items {
	background-color: $white;
	&.firstElement {
		.arrow-up {
			pointer-events: none;
			opacity: 0.2;
		}
	}
	&.lastElement{
		margin-bottom: 2rem;
		.arrow-down {
			pointer-events: none;
			opacity: 0.2;
		}
	}
}
.header {
	display: flex;
    width: 100%;
    overflow-x: auto;
    justify-content: space-between;
	flex-wrap: wrap;
	@include media-breakpoint-up(md) {
		flex-wrap: nowrap;
	}
	@media (orientation: landscape) {
		flex-wrap: nowrap;
	}
	& > * {
		padding: 2rem 3rem;
	}
}
.item-title {
	flex-grow: 1;
	cursor: pointer;
	font-size: 1.3rem;
	@include transition;
	&:hover {
		opacity: 0.6;
	}
}
.body.open {
	overflow: auto;
	padding: 3rem;
	background-color: $grey-800;
	::v-deep .input label {
		color: $white;
	}
	::v-deep .quillWrapper .ql-toolbar {
		background-color: $white;
	}
	::v-deep .wrapper {
		span {
			color: $white;
		}
		.button-wrapper {
			button {
				border: 1px solid $white;
				@include transition;
				&:hover {
					background-color: $white;
					color: $grey-800;
					border-color: $grey-800;
				}
			}
		}
	}
}
.arrows-wrapper {
	display: flex;
	position: relative;
	padding-right: 2rem;
	flex-grow: 1;
	@include media-breakpoint-up(md) {
		flex-grow: 0;
		&::before {
			right: 0;
			background-color: $grey-400;
		}
	}
	@media (orientation: landscape) {
		flex-grow: 0;
		&::before {
			right: 0;
			background-color: $grey-400;
		}
	}
}
.buttons-wrapper {
	position: relative;
	display: flex;
	padding-left: 2rem;
	justify-content: center;
	width: 100%;
	@include media-breakpoint-up(md) {
		justify-content: flex-start;
		width: auto;
	}
	@media (orientation: landscape) {
		justify-content: flex-start;
		width: auto;
	}
	&::before {
		left: 0;
		background-color: $grey-400;
	}
}
.before-line-helper {
	&::before {
		position: absolute;
		content: '';
		width: 2px;
		height: 35px;
		top: 50%;
		transform: translateY(-50%);
	}
}
.icon-wrapper {
	display: inline-flex;
	text-transform: uppercase;
	cursor: pointer;
	align-items: center;
	font-size: 1.3rem;
	&.icon-close {
		color: $danger-500;
	}
	svg {
		margin-right: .5rem;
		height: 11px;
		transition: $transition;
	}
	&:first-child {
		margin-right: 3rem;
	}
	&:hover svg {
		opacity: 0.6;
	}
}
button {
	display: inline-flex;
    padding: 5px;
    background: none;
    border: none;
	cursor: pointer;
	.arrow {
		width: 12px;
		height: 7px;
	}
}
</style>
