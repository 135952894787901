import Vue from 'vue'
import { Application } from '@a/core/application/Application'
import { useModules } from '@a/modules'
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'

(async () => {
	const app = new Application()

	Vue.prototype.$app = app
	window.app = app

	useModules(app)

	await app.init()

	app.vue.$mount('#app')
})()
