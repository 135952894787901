<template>
	<div class="layout">
		<base-menu :menu="layout.menu"/>
		<base-topbar :topbar="layout.topbar"/>
		<main class="content">
			<slot />
		</main>
	</div>
</template>

<script>
import BaseMenu from '@a/components/organisms/BaseMenu'
import BaseTopbar from '@a/components/organisms/BaseTopbar'

export default {
	components: {
		BaseMenu,
		BaseTopbar
	},
	props: {
		layout: Object
	}
}
</script>

<style lang="scss" scoped>
.content {
	height: calc(100vh - 50px);
	margin-top: 50px;
	padding: 15px 5px;
	overflow-y: auto;
	background: $light-gray;
	@include media-breakpoint-up (lg) {
		height: calc(100vh - 65px);
		border-radius: 10px;
		padding: 15px;
		margin-right: 15px;
		margin-left: 255px;
	}
}
</style>
