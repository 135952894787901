import { Item } from '~/fileManager/admin/core/fileManager/Item'

class File extends Item {
	extension
	mimeType

	constructor ({ extension, mimeType, ...params }) {
		super(params)
		this.extension = extension
		this.mimeType = mimeType
	}

	getExtension () {
		return this.extension
	}

	getMimeType () {
		return this.mimeType
	}
}

export { File }
