<template>
    <div
        ref="error"
        class="input-error"
        :style="{'maxHeight': maxHeightValue}"
    >{{error ? error : ''}}</div>
</template>

<script>
import { wait } from 'utils/mask/index'
export default {
	props: {
		error: [String, Boolean]
	},
	data () {
		return {
			maxHeight: 0
		}
	},
	computed: {
		maxHeightValue () {
			return this.maxHeight + 'px'
		}
	},
	mounted () {
		this.displayError()
	},
	methods: {
		async displayError () {
			if (!this.$refs.error) return 0
			if (!this.error) {
				this.maxHeight = 0
				await wait(500)
				return 0
			}
			const width = this.$refs.error.clientWidth
			this.maxHeight = (Math.floor(this.error.length * 10 / width) + 1) * 20
		}
	},
	watch: {
		error: 'displayError'
	}
}
</script>

<style lang="scss" scoped>
    .input-error {
        flex-basis: 100%;
        color: $danger;
        font-size: 13px;
        line-height:20px;
        max-height: 0px;
        overflow: hidden;
        @include transition;
    }
</style>
