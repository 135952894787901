<template>
	<div class="wrapper">
		<span class="input-text">{{element.label[$app.translator.active.alias]}}</span>
		<span class="input-text">{{lang.choosen_file}}: {{ element.value ? element.value.path : '' }}</span>
		<div class="lang-and-title">
			<span class="alt-title-text" v-if="element.value.alt">{{lang.image_alt}}: {{ element.value ? element.value.alt : '' }}</span>
			<span class="alt-title-text" v-if="element.value.title">{{lang.image_title}}: {{ element.value ? element.value.title : '' }}</span>
		</div>
		<cms-image
			v-if="element.value && element.value.path"
			:value="element.value.path"
			:lazy="true"
			:base="{width: 300, height: 125, fit: 'contain', background: 'transparent'}"
		/>
		<div class="button-wrapper">
			<base-button v-if="element.value" @click="element.clear()">
				{{lang.delete}}
			</base-button>
			<base-button @click="element.open()">
				{{lang.choose_file}}
			</base-button>
			<base-button v-if="element.value.path" @click="element.openSeo()">
				{{lang.seo_modify}}
			</base-button>
		</div>
	</div>
</template>

<script>
import BaseButton from '@a/components/atoms/BaseButton'
import CmsImage from '~/cms/admin/components/CmsImage'

export default {
	components: {
		BaseButton,
		CmsImage
	},
	props: {
		element: Object
	},
	computed: {
		lang () {
			return this.$app.translator.active.translations['file-manager']
		}
	}
}
</script>
<style lang="scss" scoped>
.wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	cursor: pointer;
	margin: 1rem 0;
	.button-wrapper {
		display: flex;
		margin-top: 1rem;
		& ::v-deep button {
			margin-right: 2rem;
			border: 1px solid $white;
			@include transition;
			&:hover {
				background-color: $white;
				color: $grey-800;
				border-color: $grey-800;
			}
		}
	}
	.input-text {
		display: block;
		margin-bottom: 1rem;
		font-weight: bold;
		font-size: 1.4rem;
	}
	.alt-title-text {
		display: block;
		margin-bottom: 1rem;
		font-size: 1rem;
	}
}
</style>
