const pageMixin = {
	computed: {
		page () {
			return this.$app.router.page
		},
		alias () {
			return this.page.Entity?.alias ? this.page.Entity.alias : this.page.alias
		}
	}
}

export {
	pageMixin
}
