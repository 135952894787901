import { render, staticRenderFns } from "./BaseTopbar.vue?vue&type=template&id=cba12b1a&scoped=true&"
import script from "./BaseTopbar.vue?vue&type=script&lang=js&"
export * from "./BaseTopbar.vue?vue&type=script&lang=js&"
import style0 from "./BaseTopbar.vue?vue&type=style&index=0&id=cba12b1a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cba12b1a",
  null
  
)

export default component.exports