import { ReactiveClass } from '../ReactiveClass'

class Element extends ReactiveClass {
	component

	async getValue () {}

	async validate () {}

	async create (parent) {
		this.parent = parent
	}

	async setInitialValue (values) {}
}

export {
	Element
}
