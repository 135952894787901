<template>
    <div class="input">
        <base-label v-if="label" @click="$emit('click')" :name="name" :label="label" />
		<div class="input-wrapper">
			<div class="input-main"
                :class="{'input-main-seperator': seperator}"
            >
                <slot />
                <slot name="icon" />
				<message-error :error="error" />
			</div>
            <base-info :text="info" />
		</div>
	</div>
</template>

<script>
import BaseLabel from '@a/components/modules/inputs/atoms/BaseLabel'
import MessageError from '@a/components/modules/inputs/atoms/MessageError'
import BaseInfo from '@a/components/modules/inputs/molecules/BaseInfo'

export default {
	components: {
		MessageError,
		BaseInfo,
		BaseLabel
	},
	props: {
		valid: Boolean,
		error: [String, Boolean],
		label: [Object, String, Boolean],
		name: String,
		seperator: Boolean,
		info: [String, Boolean]
	}
}
</script>

<style lang="scss" scoped>
    .input {
		width: 100%;
		padding: 10px 0;
        &-wrapper {
			display: flex;
			flex-direction: column;
            width: 100%;
        }
        &-main {
            display: flex;
            width: 100%;
            flex-direction: column;
            position: relative;
            &.input-main-seperator {
                &::before {
                    content: "";
                    position: absolute;
                    top: 13px;
                    left: 70px;
                    width: 1px;
                    height: 19px;
                    background-color: #222;
                }
            }
        }
    }
</style>
