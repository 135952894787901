<template>
	<div class="wrapper">
		<base-font variant="page-header" uppercase>{{ lang.title }}</base-font>
		<div class="iframe-container">
			<iframe class="iframe" src="https://www.youtube.com/embed/CIHknh991e8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
		</div>
	</div>
</template>

<script>
import { pageMixin } from '@a/pages/page.mixin'
import BaseFont from '@a/components/atoms/BaseFont/BaseFont'

export default {
	components: {
		BaseFont
	},
	mixins: [
		pageMixin
	],
	computed: {
		lang () {
			return this.$app.translator.active.translations.pages.dashboard
		}
	}
}
</script>
<style lang="scss" scoped>
.wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 3rem;
	height: 100%;
	width: 100%;
	@include media-breakpoint-up(lg) {
		grid-template-rows: repeat(2, 1fr);
		grid-template-columns: repeat(2, calc(100% / 2));
	}

	.iframe-container {
		position: relative;
		overflow: hidden;
		width: 80%;
		padding-top: 56.25%;
		margin: 3rem auto;
		max-width: 1280px;
		max-height: 720px;

		.iframe {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%;
			border: 0;
			max-width: 1280px;
			max-height: 720px;
		}
	}
}
</style>
