import VMenuParentElement from '@a/components/molecules/menus/MenuParentElement'
import { MenuParent } from './MenuParent'

class MenuParentElement extends MenuParent {
	name
	icon
	status = 'close'
	component = VMenuParentElement

	constructor ({ name, icon }) {
		super()
		this.name = name
		this.icon = icon
	}

	get visible () {
		return this.elements.map(element => element.visible).includes(true)
	}

	toggle () {
		if (this.status === 'open') this.parent.closeAllWithout(null)
		else this.open()
	}

	open () {
		this.status = 'open'
		this.parent.closeAllWithout(this)
	}

	close () {
		this.status = 'close'
	}
}
export {
	MenuParentElement
}
