<template>
	<div class="download-status">
		<div ref="progressBar" class="progress-bar-wrapper">
			<progress id="progress-bar" class="bar" :value="status.progress" max="100"></progress>
			<base-font class="label" weight="bold" tag="h3">{{ status.progress }} %</base-font>
		</div>
		<div class="file-actions">
			<base-button class="remove-btn" @click="status.remove()">
				<base-svg name="close" class="icon"></base-svg>
			</base-button>
		</div>
	</div>
</template>

<script>
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import BaseButton from '@a/components/atoms/BaseButton/BaseButton.vue'
import BaseSvg from '@a/components/atoms/BaseSvg/BaseSvg.vue'
import BaseFont from '@a/components/atoms/BaseFont/BaseFont.vue'

export default {
	components: {
		BaseButton,
		BaseSvg,
		BaseFont
	},
	props: {
		status: Object
	},
	data: () => ({
		tippyInstance: null
	}),
	mounted () {
		this.tippyInstance = tippy(this.$refs.progressBar, {
			content: this.status.file.item.name,
			theme: 'light'
		})
	}
}
</script>

<style scoped lang="scss">
.download-status {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	justify-content: space-between;
	margin: 1rem;
	background: $white;
	border: 1px solid #ACACAC;

	@include media-breakpoint-up(lg) {
		flex-direction: row;
	}

	.progress-bar-wrapper {
		display: flex;
		align-items: center;
		width: 100%;
		gap: 1rem;
		margin-left: auto;
		flex: 3 0 0;

		@include media-breakpoint-up(lg) {
			width: 300px;
		}

		::v-deep .label {
			flex: 1 0 0;
		}
		.bar {
			flex: 30 0 0;
		}

		progress {
			width: inherit;
		}
	}
}

.remove-btn {
	border: none;
	border-radius: 0;

	.icon {
		width: 25px;
		height: 25px;
		fill: white;
	}
}

progress {
	border: 0;
	height: 40px;
}
progress::-webkit-progress-bar {
	border: 0;
	height: 40px;
}
progress::-webkit-progress-value {
	border: 0;
	height: 40px;
}
progress::-moz-progress-bar {
	border: 0;
	height: 40px;
}
</style>
