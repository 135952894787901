import { Page } from '@a/core/pages/Page'
import RemindPassword from '../../pages/RemindPassword.vue'
import { InputText } from '@a/core/elements/inputs/InputText'
import { Notification, NotificationType } from '@a/core/entities/Notification'

class RemindPasswordPage extends Page {
	static alias = 'remind-password'
	static path = '/remind-password'
	static component = RemindPassword

	inputEmail
	view = 'form'

	layout = 'blank'
	processing = false

	get email () {
		return this.inputEmail.value
	}

	init () {
		super.init()
		this.createInputs()
	}

	afterRedirect () {
		if (this.app.authorization.isAuthorizated) return this.dashboardRedirect()
	}

	createInputs () {
		this.inputEmail = new InputText({
			name: 'email',
			label: 'pages.remind_password.email'
		})
	}

	async remindPassword () {
		try {
			this.processing = true
			const { error } = await this.app.getService('rext').remindPassword(this.email)

			this.processing = false
			if (error) {
				Notification.create(NotificationType.ERROR, 'pages.remind_password.error')
			} else {
				Notification.create(NotificationType.SUCCESS, 'pages.remind_password.success')
				this.view = 'info'
			}
		} catch (err) {
			console.error(err)
			Notification.create(NotificationType.ERROR, 'errors.server_error')
		}
	}

	signInRedirect () {
		this.app.vue.$router.replace({ name: 'sign-in' })
	}

	dashboardRedirect () {
		this.app.vue.$router.replace({ name: 'dashboard' })
	}
}

export {
	RemindPasswordPage
}
