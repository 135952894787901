import { EntityCreateFormPage } from '@a/core/pages/entity/create/EntityCreateFormPage'
import { checkPermissions } from '~/authorization/admin/utils/checkPermissions'
import { Permission } from '~/authorization/admin/entities/Permission'

class UserGroupCreatePage extends EntityCreateFormPage {
	static entity = 'user-group'
	static visible = checkPermissions('permission_management')

	getFormSchema () {
		return {
			$type: 'form',
			elements: [
				{
					$type: 'column',
					grid: {
						col: 12,
						lg: 6
					},
					elements: [
						{
							$type: 'text_input',
							attribute: 'alias',
							name: 'alias',
							label: 'entities.user-group.pages.form.name',
							validation: []
						},
						{
							$type: 'entity_many_input',
							attribute: 'permissions',
							name: 'permissions',
							label: 'entities.user-group.pages.form.permissions',
							validation: [],
							entity: Permission
						},
						{
							$type: 'button_submit',
							label: 'entities.user-group.pages.list.create_button'
						}
					]
				}
			]
		}
	}
}

export { UserGroupCreatePage }
