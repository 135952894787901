import VDropdownTopbar from '@a/components/molecules/topbar/DropdownTopbar'

class DropdownTopbar {
	isOpen = false
	component = VDropdownTopbar
	topbarComponent
	dropdownComponent
	align = 'left'

	constructor () {
		this.handleBodyClickFunction = this.handleBodyClick.bind(this)
	}

	open () {
		this.isOpen = true
		document.body.addEventListener('click', this.handleBodyClickFunction)
	}

	toggle () {
		if (this.isOpen) this.close()
		else this.open()
	}

	handleBodyClick (e) {
		this.close()
	}

	close () {
		this.isOpen = false
		document.body.removeEventListener('click', this.handleBodyClickFunction)
	}
}
export {
	DropdownTopbar
}
