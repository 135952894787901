
const AppNotification = {

	showNotification (notification) {
		this.notifications.push(notification)
	},
	closeNotification (notification) {
		this.notifications = this.notifications.filter(n => n !== notification)
	}
}
export {
	AppNotification
}
