
class ListItem {
	explorer
	item

	constructor (item, explorer) {
		this.item = item
		this.explorer = explorer
	}

	get isSelected () {
		return this.explorer.choosedItems.includes(this)
	}

	choose () {
		if (!this.isSelected) this.explorer.chooseItem(this)
	}

	unchoose () {
		if (this.isSelected) this.explorer.unchooseItem(this)
	}

	async unmarkForDeletion () {
		await this.explorer.unmarkItemForDeletion(this.item)
	}
}

export { ListItem }
