import Vue from 'vue'
import VueRouter from 'vue-router'
import { Emitter } from '@a/core/Emitter'

Vue.use(VueRouter)

class Router extends Emitter {
	vueRouter
	page
	beforeEachCallbacks = []

	constructor (app) {
		super()
		this.app = app
	}

	getVueRoutes () {
		return this.app.getPages().map(Page => ({
			path: Page.path,
			component: Page.component,
			meta: { page: Page },
			name: Page.alias
		}))
	}

	getVueRouter () {
		if (!this.vueRouter) this.createVueRouter()
		return this.vueRouter
	}

	createVueRouter () {
		this.vueRouter = new VueRouter({
			mode: 'history',
			base: process.env.BASE_URL,
			routes: this.getVueRoutes()
		})
		this.beforeEachCallbacks.map(cb => this.vueRouter.beforeEach(cb))
		this.vueRouter.beforeEach(this.beforeRouteChangeHandler.bind(this))
	}

	beforeEach (cb) {
		this.beforeEachCallbacks.push(cb)
	}

	async beforeRouteChangeHandler (to, from, next) {
		const Page = to.meta.page
		this.page = new Page({
			app: this.app
		})
		this.page.init()
		next()
		await this.app.waitForVue()
		this.page.afterRedirect()
	}
}

export {
	Router
}
