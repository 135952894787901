const config = {
	services: {
		rext: {
			url: window.location.origin + '/api',
			imagesUrl: window.location.origin + '/api/images/'
		}
	}
}

export {
	config
}
