<template>
	<component :is="dynamicComponent" v-on="$listeners" />
</template>

<script>
const req = require.context('@a/assets/svg/', true, /\.(svg)$/i)
const modules = {}
req.keys().map(key => {
	const name = key.match(/\w+/)[0]
	modules[name] = req(key)
})

export default {
	name: 'VSvg',
	props: {
		name: String
	},
	computed: {
		dynamicComponent () {
			return this.name ? modules[this.name] : false
		}
	}
}
</script>
