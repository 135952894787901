import { rextServiceExtend } from './services/RextService'
import Translator from '@a/core/language/Translator'
import Language from '@a/core/language/Language'

class TranslationsModule {
	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
	}

	async beforeInitReactive (app) {
		const settings = await app.getSettings()
		const languageSettings = Object.values(settings.general.languages.adminPanel)
		const langs = await app.getService('rext').getTranslations()
		const translator = new Translator()
		const languages = languageSettings.map(language => new Language({
			name: language.toUpperCase(),
			alias: language.toLowerCase(),
			translations: langs[language.toLowerCase()],
			translator
		}))

		translator.restoreActive(languages[0])
		app.translator = translator
	}
}
export { TranslationsModule }
