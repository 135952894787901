export function rextServiceExtend (Service) {
	return class RextService extends Service {
		async getTranslations () {
			const response = await this.http.get('/translations?system=admin')

			if (response.status !== 200) return { error: true }

			return response.data
		}
	}
}
