import { DefaultLayout } from '@a/core/layouts/default/DefaultLayout'
import { BlankLayout } from '../layouts/blank/BlankLayout'
import { MenuElement } from '@a/core/layouts/default/menu/MenuElement'

const AppLayout = {
	readyToAddLayouts () {
		this.addLayout(DefaultLayout)
		this.addLayout(BlankLayout)
	},

	createLayouts () {
		this.getLayouts().forEach(Layout => {
			this.layouts[Layout.alias] = new Layout()
		})
	},

	afterLayoutsCreated () {
		const layout = this.getLayout('default')
		const dashboard = new MenuElement({
			name: 'dashboard',
			icon: 'dashboard',
			to: { name: 'dashboard' }
		})

		layout.menu.addElement(dashboard)
	}
}

export {
	AppLayout
}
