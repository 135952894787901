<template>
	<button @click="$emit('click')" :class="{outline}">
		<slot/>
	</button>
</template>
<script>
export default {
	props: {
		outline: Boolean
	}
}
</script>
<style lang="scss" scoped>
button {
	background: $primary;
	color: $white;
	font-size: $font-size-base;
	padding: 10px 15px;
	border: 1px solid $primary;
	border-radius: $border-radius;
	cursor: pointer;
	transition: $transition;
	&.outline {
		color: $primary;
		background: white;
		&:hover {
			background: $primary;
		}
	}
	&:hover {
		color: white;
	}
}
</style>
