<template>
	<td class="permissions-wrapper">
		<span
			v-for="val in getColumsValue"
			:key="val"
		>
			{{val}}
		</span>
	</td>
</template>

<script>
export default {
	computed: {
		getActiveLanguage () {
			return this.$app.translator.active.alias
		},
		getColumsValue () {
			const columnsValues = this.column.getValue(this.object)
			const values = columnsValues.map(column => {
				return this.$app.translator.getTranslation(`entities.permission.${column}`)[this.getActiveLanguage] || column
			})
			return values
		}
	},
	props: {
		column: Object,
		object: Object
	}
}
</script>
<style lang="scss" scoped>
.permissions-wrapper {
	display: flex;
	flex-wrap: wrap;
	width: 520px;
	@include media-breakpoint-up(xl) {
		width: auto;
	}
}
span {
	border-radius: 5px;
	font-size: 1.3rem;
	margin-right: 1rem;
	margin-top: 1rem;
	padding: .5rem 1rem;
	background-color: $grey-600;
	color: $white;
}
</style>
