<template>
	<base-wrapper
		:valid="input.isValidAndTouched"
        :error="error"
        :label="input.label"
        :name="input.name"
		:info="input.info"
		class="input-select"
		@click="open"
	>
		<div class="select-wrapper">
			<div
				ref="select"
				tabindex="0"
				@click.stop="open"
				class="select"
				:class="{ open: input.isOpen, 'has-scroll': hasScroll }"
			>
				<template v-if="input.ready">
					<select-option :option="choosedOption"/>
					<base-input
						:input="inputSearch"
						type="text"
						class="input-search"
						ref="inputSearch"
					/>
					<select-option
						v-for="option in options"
						:key="option.id"
						:option="option"
					/>
				</template>
				<template v-else>
					Ładowanie
				</template>
			</div>
		</div>

		<template v-slot:icon>
			<base-svg class="input-select-arrow" name="arrow_drop_down" :class="{ open: input.isOpen }" />
		</template>
	</base-wrapper>
</template>

<script>
import BaseInput from '@a/components/modules/inputs/atoms/BaseInput.vue'
import SelectOption from '@/components/modules/inputs/atoms/SelectOption'
import BaseSvg from '@a/components/atoms/BaseSvg'
import mixin from './input.mixin'

export default {
	mixins: [mixin],
	components: {
		BaseSvg,
		SelectOption,
		BaseInput
	},
	data () {
		return {
			hasScroll: false
		}
	},
	computed: {
		lang () {
			return this.$app.translator.active.translations
		},
		choosedOption () {
			return this.input.choosedOption
		},
		options () {
			return this.input.options.filter(option => {
				const optionName = option.name.toString().toLowerCase()
				const searchValue = this.inputSearch.value.toString().toLowerCase()
				return optionName.includes(searchValue)
			})
		},
		inputSearch () {
			return {
				placeholder: this.lang.inputs.placeholder.search,
				name: `input-search-${this.input.name}`,
				value: '',
				disable: true
			}
		}
	},
	methods: {
		open (ev) {
			if (ev.target === this.$refs.inputSearch.$el) return false
			if (this.input.isClose) this.input.open()
			else this.input.close()
		}
	},
	watch: {
		'input.isOpen' () {
			if (this.input.isOpen) {
				this.inputSearch.value = ''
				let length = this.input.options.length + 1
				this.inputSearch.disable = false
				if (length > 5) {
					this.hasScroll = true
					length = 5
				}
				this.$refs.select.style.maxHeight = 39 + 35 * length + 15 + 'px'

				setTimeout(() => this.$refs.inputSearch.$el.focus(), 150)
			} else {
				this.inputSearch.disable = true
				this.$refs.select.style.maxHeight = '45px'
				this.hasScroll = false
			}
			this.$refs.select.scrollTo(0, 0)
		}
	}
}
</script>

<style lang="scss" scoped>
.select-wrapper {
	height: $input-height;
}
.input-select {
	display: flex;
	flex-direction: column;
	font-size: $font-size-label;
	.select {
		position: absolute;
		z-index: 5;
		background-color: $white;
		line-height: 25px;
		max-height: $input-height;
		padding: 10px;
		overflow: hidden;
		font-size: $font-size-base;
		border:1px solid $grey-400;
		border-radius: $border-radius;
		padding: 10px;
		min-height:$input-height;
		width: 100%;
		padding-right: 30px;
		cursor: pointer;
		outline: none;
		@include transition;
		transition-duration: 0.2s;
		&.invalid {
			border:1px solid $danger;
		}
		&.disabled,
		&:disabled {
			cursor: default;
			background-color: $light-gray;
			color: $grey-800;
		}
		&::placeholder{
			color: rgba($grey-800,.3);
			font-style: italic;
			font-weight: bold;
		}
		&.open {
			overflow: auto;
			z-index: 10;
			min-height: 135px;
			&:hover {
				.select-option {
					opacity: .6;
				}
			}
			&.has-scroll {
				&::-webkit-scrollbar {
					display: block;
				}
				border-top-right-radius: 3px;
				border-bottom-right-radius: 3px;
			}
			.select-option {
				pointer-events: auto;
				opacity: 1;
				height: initial;
				overflow: initial;
				&:hover {
					opacity: 1;
				}
			}
		}
		/* Chrome, Safari, Edge, Opera */
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		&::-webkit-scrollbar {
			width: 5px;
			margin: 10px 0;
			display: none;
		}
		&::-webkit-scrollbar-track {
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}
		&::-webkit-scrollbar-thumb {
			background: $grey-500;
			border-radius: 3px;
		}
		&::-webkit-scrollbar-thumb:hover {
			background: $grey-800;
			width: 10px;
		}
	}
	.input-search {
		margin-top: 10px;
		outline: none;
	}
}

.input-select-arrow {
	position: absolute;
	z-index: 2;
	height: 22px;
	top: 10px;
	right: 5px;
	@include transition;
	&.open {
		transform: rotate(180deg);
	}
}
</style>
