import { Modal } from '@a/core/application/Modal'
import FileManagerModalComponent from '~/fileManager/admin/components/FileManagerModal/FileManagerModal'
import { Explorer } from '~/fileManager/admin/core/fileManager/Explorer'
import path from 'path'

class FileManagerModal extends Modal {
	static async create () {
		const modal = new FileManagerModal()
		await modal.init()
		return modal
	}

	explorer
	component = FileManagerModalComponent
	value
	sendResult

	async init () {
		this.explorer = await Explorer.create(this.application, 1, '')
	}

	async open (value) {
		this.initialValue = value
		this.value = value
		await this.goToPath()

		this.showModal()
	}

	async goToPath () {
		if (this.value && this.value.path) {
			const dirPath = path.dirname(this.value.path)
			await this.explorer.goToPath(dirPath)
		}
	}

	showModal () {
		super.open()
	}

	close () {
		this.sendResult(this.initialValue)
		super.close()
	}

	chooseFile () {
		const choosedFile = this.explorer.choosedItems[0]
		return this.sendResult({
			path: choosedFile.item.path
		})
	}

	async getResult () {
		return new Promise((resolve, reject) => {
			this.sendResult = (value) => {
				resolve(value)
			}
		})
	}
}

export { FileManagerModal }
