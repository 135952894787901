<template>
	<div class="layout">
		<page-header>
			{{title}}
			<template #right>
				<slot name="header" />
			</template>
		</page-header>
		<div class="content">
			<slot />
		</div>
	</div>
</template>

<script>
import PageHeader from '@a/components/atoms/PageHeader'
export default {
	components: {
		PageHeader
	},
	props: {
		title: String
	}
}
</script>

<style lang="scss" scoped>
.layout {
	display: flex;
    flex-direction: column;
	height: 100%;
	background: $light-gray;
	height: 100%;
	display: flex;
	flex-direction: column;
	@include media-breakpoint-up(lg) {
		width: 100%;
	}
	.content {
		height: 100%;
	}
}
</style>
