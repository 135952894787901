import Vue from 'vue'
import App from '@a/App.vue'
import initGlobalUtils from './Global'

const AppVue = {
	waitForVueCallbacks: [],
	created () {
		this.vue = new Vue({
			router: this.router.getVueRouter(),
			render: h => h(App)
		})
		this.waitForVueCallbacks.map(callback => callback.call())
		initGlobalUtils()
	},
	waitForVue () {
		if (this.vue) return true
		return new Promise(resolve => this.waitForVueCallbacks.push(resolve))
	}
}

export {
	AppVue
}
