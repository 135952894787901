import { Column } from './Column'
import PermissionsColumnTemplate from '@a/components/atoms/PermissionsColumn'

class PermissionsColumn extends Column {
	component = PermissionsColumnTemplate
	constructor ({ name, getter }) {
		super({ name })
		this.getter = getter
	}

	getValue (object) {
		return this.getter(object)
	}
}

export { PermissionsColumn }
