
class FileDownloadingStatus {
	progress = 0
	file

	constructor (file) {
		this.file = file
	}

	get path () {
		return this.file.item.path
	}

	updateProgress (progressEvent) {
		this.progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100)

		if (this.progress === 100) {
			this.file.explorer.notifyOnDownload(this.file.item)
		}
	}

	remove () {
		this.file.explorer.removeFileDownloadingStatus(this)
	}
}

export { FileDownloadingStatus }
