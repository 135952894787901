import Vue from 'vue'
import { AppPage } from './Application.page'
import { AppLayout } from './Application.layout'
import { AppNotification } from './Application.notification'
import { AppPrompt } from './Application.prompt'
import { AppModal } from './Application.modal'
import { Lifecycle } from './Lifecycle'
import { toPascalCase as tPC } from 'utils/strings'
import { asyncForEach } from 'utils/async'
import { Emitter } from '@a/core/Emitter'
import { AppService } from './Application.service'
import { AppElement } from './Application.element'
import { AppVue } from './Application.vue'

const EXTENDED_CLASSES = [
	['service', 'services'],
	['route', 'routes'],
	['layout', 'layouts'],
	['page', 'pages'],
	['entity', 'entities']
]

class Application extends Emitter {
	modules = []
	notifications = []
	prompt = false
	modal = false
	settings = false
	getSettingsCallbacks = []

	get language () {
		return this.translator.active.alias
	}

	initReactive () {
		Vue.observable(this)
	}

	use (module) {
		this.modules.push(module)
	}

	async init () {
		await this.runLifecycle('beforeInit')
		await asyncForEach(EXTENDED_CLASSES, async (part) => {
			await this.initPart(part)
		})
		await this.runLifecycle('beforeInitReactive')
		await this.initElements()
		this.initReactive()
		await this.runLifecycle('created')
	}

	async initPart ([singular, plural]) {
		this[plural] = {}

		this[`add${tPC(singular)}`] = (alias, element) => {
			if (typeof alias !== 'string') {
				element = alias
				alias = element.alias
			}
			if (this[plural][alias]) console.warn(`${tPC(singular)} with alias '${alias}' already exist!`)
			this[plural][element.alias] = element
		}

		await this.runLifecycle(`readyToAdd${tPC(plural)}`)

		this[`get${tPC(singular)}`] = (alias) => {
			return this[plural][alias]
		}
		this[`get${tPC(plural)}`] = () => {
			return Object.values(this[plural])
		}
		this[`extend${tPC(singular)}`] = (alias, extendFunction) => {
			const element = this[`get${tPC(singular)}`](alias)
			this[plural][alias] = extendFunction(element)
		}

		await this.runLifecycle(`readyToExtend${tPC(plural)}`)
		await this.runLifecycle(`create${tPC(plural)}`)
		await this.runLifecycle(`after${tPC(plural)}Created`)
	}

	async runLifecycle (cycle) {
		if (this[cycle]) await this[cycle]()
		await asyncForEach(this.modules, async (module) => {
			if (module[cycle]) await module[cycle](this)
		})
	}

	emit () {
		return this._emit(...arguments)
	}

	async afterServicesCreated () {
		await this.loadSettings()
	}

	async loadSettings () {
		const service = this.getService('rext')

		const { data, status } = await service.http.get('/setting')

		if (status !== 200) return { error: true }

		this.settings = {}
		Object.keys(data).forEach((setting) => {
			this.settings[setting] = data[setting]
		})

		this.getSettingsCallbacks.map(cb => cb(this.settings))
	}

	async getSettings () {
		if (this.settings) return this.settings
		return new Promise(resolve => this.getSettingsCallbacks.push(resolve))
	}
}

Object.assign(Application.prototype, AppService, AppLayout, AppPage, AppVue, AppNotification, AppPrompt, AppModal, AppElement, Lifecycle)

export {
	Application
}
