<template>
	<div class="tab-nav-item">
		<button
			@click="tab.choose()"
			class="tab-item"
			:class="{ active: tab.active }"
		>
			{{tab.tabTitle}}
		</button>
	</div>
</template>
<script>
export default {
	props: {
		tab: Object
	}
}
</script>
<style lang="scss" scoped>
.tab {
	&-item {
		width: 100%;
		outline: none;
		border-radius: $border-radius $border-radius 0 0;
		border: none;
		padding: 1rem 2rem;
		font-size: 1.3rem;
		text-transform: uppercase;
		background-color: $white;
		cursor: pointer;
		@include transition;
		@include media-breakpoint-up (md) {
			width: auto;
		}
		&:hover, &.active {
			background-color: $primary;
			color: $white;
			border-bottom-color: white;
		}
	}
}
</style>
