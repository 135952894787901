
import Vue from 'vue'
export default function initGlobalUtils () {
	Vue.mixin({
		computed: {
			l () {
				return this.$app.translator.active.translations
			}
		}
	})
}
