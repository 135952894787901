<template>
	<div class="table-outer-wrapper">
		<div class="table-wrapper">
			<table v-if="list.data.length" class="table">
				<thead>
					<header-row-list-page :alias="alias" :columns="list.columns" />
				</thead>
				<draggable
					v-if="list.draggableOn"
					:list="list.data"
					tag="tbody"
				>
					<component v-for="object in list.data" :key="object.id" :is="list.rowComponent" :object="object" :columns="list.columns"/>
				</draggable>
				<tbody v-else>
					<component v-for="object in list.data" :key="object.id" :is="list.rowComponent" :object="object" :columns="list.columns"/>
				</tbody>
			</table>
			<div v-else class="empty-list-info">
				<base-font class="page-counter" size="sm" color="black">
					{{l.entities[alias].pages.list.empty_list_info}}
				</base-font>
			</div>
		</div>
		<div v-if="list.pagination" class="bottom">
			<button-wrapper left>
				<base-button
					v-if="!list.draggableOn"
					@click="list.turnOnDraggableMode()"
				>
					{{lang.elements.list.change_order}}
				</base-button>
				<base-button
					v-if="list.draggableOn"
					@click="list.saveOrder()"
				>
					{{lang.elements.list.save_order}}
				</base-button>
			</button-wrapper>
			<button-wrapper right>
				<base-button v-if="!list.isFirstPage" @click="list.previousPage()">
					&laquo;
				</base-button>
				<base-input v-if="list.inputPageNumber" :input="list.inputPageNumber" type="text" />
				<base-font class="page-counter" color="black">
					{{lang.inputs.label.from}} {{list.maxPage}}
				</base-font>
				<base-button v-if="!list.isLastPage" @click="list.nextPage()">
					&raquo;
				</base-button>
			</button-wrapper>
		</div>
	</div>
</template>

<script>
import ListComponentVue from './ListComponent.vue'
import Draggable from 'vuedraggable'

export default {
	extends: ListComponentVue,
	components: {
		Draggable
	}
}
</script>
