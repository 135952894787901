<template>
	<div>
		<base-wrapper
			:valid="input.isValidAndTouched"
			:label="input.label"
			:name="name"
			:info="input.info"
			:error="input.error"
			class="input-password"
		>
			<base-input
				:input="input"
				:type="input.isHidden ? 'password' : 'text'"
			/>
			<template v-slot:icon>
				<base-svg
					name="visibility"
					@touchstart="input.show()"
					@touchend="input.hide()"
					@mousedown="input.show()"
					@mouseleave="input.hide()"
					@mouseup="input.hide()"
					:class="{
						valid: input.isValidAndTouched,
						clicked: !input.isHidden
					}"
				/>
			</template>
		</base-wrapper>
		<div>
			<base-rule
				v-for="(rule, key) in input.rules"
				:key="key"
				:option="rule"
			/>
		</div>
	</div>
</template>

<script>
import mixin from './input.mixin'
import BaseInput from '@a/components/modules/inputs/atoms/BaseInput'
import BaseRule from '@a/components/modules/inputs/atoms/BaseRule'
import BaseSvg from '@a/components/atoms/BaseSvg'

export default {
	mixins: [mixin],
	components: {
		BaseInput,
		BaseSvg,
		BaseRule
	}
}
</script>

<style lang="scss" scoped>
.input-password {
	&::v-deep {
		svg {
			cursor: pointer;
			position: absolute;
			right: 7px;
			top: 50%;
			width: 20px;
			transform: translateY(-50%);
		}
	}
}
</style>
