<template>
	<tr>
		<component v-for="column in columns" :key="column._uid" :is="column.headerComponent" :alias="alias" :column="column"/>
	</tr>
</template>

<script>
export default {
	props: {
		columns: Array,
		alias: String
	}
}
</script>

<style lang="scss" scoped>
tr {
	th {
		padding: 5px 5px 5px 10px;
		width: 70px;
		@include media-breakpoint-up(xl) {
			padding-left: 20px;
		}
	}
}
</style>
