<template>
	<div class="loader-wrapper">
		<base-svg name="sync" />
	</div>
</template>
<script>
import BaseSvg from '@a/components/atoms/BaseSvg'

export default {
	components: {
		BaseSvg
	}
}
</script>
<style lang="scss" scoped>
.loader-wrapper {
	width: 50px;
	margin: 0 auto;
	& svg {
		animation: rotate .8s infinite linear;
	}
}
@keyframes rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(-360deg);
	}
}
</style>
