class MenuParent {
	elements = []

	addElement (menuElement) {
		menuElement.parent = this
		this.elements.push(menuElement)
		return this
	}

	closeAllWithout (menuElement) {
		this.elements.forEach(element => {
			if (element !== menuElement) {
				if (element.close) element.close()
			}
		})
	}
}

export { MenuParent }
