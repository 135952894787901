import { Column } from './Column'
import ActionColumnTemplate from '@a/components/molecules/columns/ActionColumn'
import { Prompt } from '@a/core/application/Prompt'
import { Notification, NotificationType } from '@a/core/entities/Notification'

class ActionColumn extends Column {
	component = ActionColumnTemplate
	actions = []
	constructor ({ actions }) {
		super({ name: 'actions' })
		this.actions = actions
	}

	get showEdit () {
		return !!~this.actions.indexOf('edit')
	}

	get showRemove () {
		return !!~this.actions.indexOf('remove')
	}

	get showView () {
		return !!~this.actions.indexOf('view')
	}

	get activeLang () {
		return window.app.translator.active.translations
	}

	getPromptTranslation (alias) {
		return this.activeLang.entities[alias].pages.prompt
	}

	async remove (object) {
		const prompt = Prompt.create(`${this.getPromptTranslation(object._alias)} ` + object.getName())
		const confirmed = await prompt.getResult()

		if (!confirmed) return false

		const success = await object.remove()

		if (success) Notification.create(NotificationType.SUCCESS, `entities.${object._alias}.pages.delete.notification.success`)
		else Notification.create(NotificationType.ERROR, `entities.${object._alias}.pages.delete.notification.error`)
	}

	edit (object) {
		const name = `${object.constructor.alias}-edit`
		window.app.vue.$router.push({ name, params: { id: object.id } })
	}

	view (object) {
		const name = `${object.constructor.alias}-view`
		window.app.vue.$router.push({ name, params: { id: object.id } })
	}
}

export { ActionColumn }
