import { RecaptchaService } from '@a/services/RecaptchaService'
import { RextService } from '@a/services/RextService'

const AppService = {
	readyToAddServices () {
		this.addService(RextService)
		this.addService(RecaptchaService)
	},

	createServices () {
		this.getServices().forEach(Service => {
			this.services[Service.alias] = new Service(this)
		})
	}
}

export {
	AppService
}
