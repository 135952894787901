import { asyncForEach } from 'utils/async'
import { EntityListElement } from './EntityListElement'
import OrderedListComponent from '@a/pages/lists/OrderedListComponent.vue'
import { OrderColumn } from './columns/OrderColumn'

class OrderedEntityListElement extends EntityListElement {
	component = OrderedListComponent

	constructor (data) {
		super(data, false)
		this.initOrderby = { order: 'ASC' }
		this.initReactive()
	}

	async initColumns () {
		await this.addColumn({
			type: OrderColumn,
			name: 'order',
			sortParam: 'order',
			getter: product => product.order
		})
	}

	turnOnDraggableMode () {
		this.draggableOn = true
	}

	async saveOrder () {
		const newOrders = {}

		this.data
			.map((row, key) => ({ id: row.id, order: row.order, newOrder: key + this.skip }))
			.filter(({ newOrder, order }) => newOrder !== order)
			.forEach(({ id, newOrder }) => { newOrders[id] = newOrder })

		await this.Entity.changeOrder(newOrders)
		this.draggableOn = false
	}
}

const orderedEntityListElementGenerator = app => async (object) => {
	return async () => {
		const list = new OrderedEntityListElement({
			Entity: object.Entity,
			relations: object.relations,
			where: object.where,
			orderby: object.orderby,
			take: object.take,
			pagination: object.pagination
		})
		await list.initColumns()
		await asyncForEach(object.columns, async column => await list.addColumn(column))
		return list
	}
}

export {
	OrderedEntityListElement,
	orderedEntityListElementGenerator
}
