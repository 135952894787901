import { EntityCreateFormPage } from '@a/core/pages/entity/create/EntityCreateFormPage'
import { checkPermissions } from '~/authorization/admin/utils/checkPermissions'
import { required } from 'utils/inputs/validation'
import { Notification, NotificationType } from '@a/core/entities/Notification'

class ProjectCreatePage extends EntityCreateFormPage {
	static entity = 'project'
	static visible = checkPermissions('project_management')

	notify (success, data) {
		if (success) Notification.create(NotificationType.SUCCESS, 'entities.project.pages.create.notification.success')
		else if (data.alias === 'NAME_BUSY') Notification.create(NotificationType.ERROR, 'entities.project.pages.create.notification.project_name_busy')
		else Notification.create(NotificationType.ERROR, 'entities.moderator.pages.create.notification.error')
	}

	getFormSchema () {
		return {
			$type: 'form',
			elements: [
				{
					$type: 'column',
					grid: {
						col: 6
					},
					elements: [
						{
							$type: 'text_input',
							attribute: 'name',
							name: 'name',
							label: 'entities.project.pages.form.name',
							validation: [required()]
						}
					]
				},
				{
					$type: 'column',
					grid: {
						col: 12
					},
					elements: [
						{
							$type: 'button_submit',
							right: true,
							label: 'entities.project.pages.create.button'
						}
					]
				}
			]
		}
	}
}

export { ProjectCreatePage }
