import { Router } from './Router'

const Lifecycle = {
	createRoutes () {
		this.router = new Router(this)
	}
}

export {
	Lifecycle
}
