<template>
	<div>
		<template v-if="page.view === 'form'">
			<div class="header-wrapper">
				<base-font variant="page-header">{{l.pages.remind_password.title}}</base-font>
			</div>
			<base-element :element="page.inputEmail" />
			<div class="wrapper">
				<button-submit @click="page.remindPassword()" :loading="page.processing">{{l.pages.remind_password.remind_password}}</button-submit>
				<base-button outline @click="page.signInRedirect()">{{l.pages.remind_password.sign_in}}</base-button>
			</div>
		</template>
		<template v-else-if="page.view === 'info'">
			<div class="header-wrapper center success-info">
				{{l.pages.remind_password.success_info}}
			</div>
			<div class="center">
				<base-button outline @click="page.signInRedirect()">{{l.pages.remind_password.go_back_to_sign_in}}</base-button>
			</div>
		</template>
	</div>
</template>

<script>
import ButtonSubmit from '@a/components/molecules/ButtonSubmit'
import BaseButton from '@a/components/atoms/BaseButton'
import BaseElement from '@a/components/atoms/BaseElement'
import BaseFont from '@a/components/atoms/BaseFont'

export default {
	components: {
		ButtonSubmit,
		BaseButton,
		BaseElement,
		BaseFont
	},
	computed: {
		page () {
			return this.$app.router.page
		}
	}
}
</script>
<style lang="scss" scoped>
.center {
	text-align: center;
}
.wrapper {
	display: flex;
	justify-content: space-between;
}
.success-info {
	margin-bottom: 2rem;
}
</style>
