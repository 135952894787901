import { EntityEditFormPage } from '@a/core/pages/entity/edit/EntityEditFormPage'
import { checkPermissions } from '~/authorization/admin/utils/checkPermissions'
import { Permission } from '~/authorization/admin/entities/Permission'

class UserGroupEditPage extends EntityEditFormPage {
	static entity = 'user-group'
	static visible = checkPermissions('permission_management')

	getFormSchema () {
		return {
			$type: 'form',
			elements: [
				{
					$type: 'column',
					grid: {
						col: 12,
						lg: 6
					},
					elements: [
						{
							$type: 'text_input',
							attribute: 'alias',
							name: 'alias',
							label: 'entities.user-group.pages.form.name',
							validation: []
						},
						{
							$type: 'entity_many_input',
							attribute: 'permissions',
							name: 'permissions',
							label: 'entities.user-group.pages.form.permissions',
							validation: [],
							entity: Permission
						},
						{
							$type: 'column',
							grid: {
								col: 12
							},
							elements: [
								{
									$type: 'buttons',
									right: true,
									label: 'entities.user-group.pages.list.buttons'
								}
							]
						}
					]
				}
			]
		}
	}
}

export { UserGroupEditPage }
