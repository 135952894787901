import { Element } from './Element'
import { asyncForEach, asyncMap } from 'utils/async'
import TabWrapper from '../../components/modules/forms/TabWrapper'
import merge from 'lodash.merge'
import { getValue, setValue } from 'utils/objects'

class TabWrapperElement extends Element {
	tabGenerator = []
	tabs = []
	component = TabWrapper
	activeTab
	name
	label

	get elements () {
		return this.tabs
	}

	constructor ({ label, name, attribute }) {
		super(...arguments)
		this.label = this.getLabel(label)
		this.name = name
		this.attribute = attribute
	}

	getLabel (label) {
		if (!label) return false
		if (typeof label === 'object') return label
		const translation = window.app.translator?.getTranslation(label)
		if (translation) return translation
		return label
	}

	addTab (element) {
		this.tabGenerator.push(element)

		return this
	}

	setTabActive (tab) {
		this.activeTab = tab
	}

	async create (parent) {
		this.parent = parent
		await asyncForEach(this.tabGenerator, async generator => {
			const tab = await generator()
			await tab.create(this)
			tab.setTabWrapper(this)
			this.tabs.push(tab)
		})
		this.setInitialTab()
		this.initReactive()
		return this
	}

	setInitialTab () {
		const query = window.app.vue.$router.currentRoute.query
		const queryTab = query[this.name]
		const tabWithQuery = this.tabs.find(tab => tab.tabName === queryTab)
		const selectedTab = tabWithQuery || this.tabs[0]

		this.setTabActive(selectedTab)
	}

	async getValue () {
		const values = await asyncMap(this.elements, element => element.getValue())
		const merged = merge(...values)
		if (!this.attribute) return merged
		const value = {}
		setValue(this.attribute, value, merged)
		return value
	}

	async validate () {
		const results = await asyncMap(this.elements, async element => await element.touchAndValidate())
		if (results.includes(false)) return false
		return true
	}

	touchAndValidate () {
		return this.validate()
	}

	async setInitialValue (values) {
		if (this.attribute) values = getValue(this.attribute, values)
		await asyncForEach(this.elements, element => element.setInitialValue(values))
	}
}

const tabWrapperElementGenerator = app => async (object) => {
	return async () => {
		const tabWrapper = new TabWrapperElement(object)
		await asyncForEach(object.elements, async el => tabWrapper.addTab(await app.createElement(el)))
		return tabWrapper
	}
}

export { TabWrapperElement, tabWrapperElementGenerator }
