import { Page } from '@a/core/pages/Page'
import SignIn from '../../pages/SignIn.vue'
import { InputText } from '@a/core/elements/inputs/InputText'
import { InputPassword } from '@a/core/elements/inputs/InputPassword'
import { Notification, NotificationType } from '@a/core/entities/Notification'

class SignInPage extends Page {
	static alias = 'sign-in'
	static path = '/sign-in'
	static component = SignIn

	inputEmail
	inputPassword

	layout = 'blank'
	processing = false

	get email () {
		return this.inputEmail.value
	}

	get password () {
		return this.inputPassword.value
	}

	init () {
		super.init()
		this.createInputs()
	}

	afterRedirect () {
		if (this.app.authorization.isAuthorizated) return this.signInRedirect()
	}

	createInputs () {
		this.inputEmail = new InputText({
			name: 'email',
			label: 'pages.sign_in.email'
		})
		this.inputPassword = new InputPassword({
			name: 'password',
			label: 'pages.sign_in.password',
			rules: []
		})
	}

	async signIn () {
		try {
			this.processing = true

			const { error } = await this.app.authorization.signIn({
				email: this.email,
				password: this.password
			})

			this.processing = false

			if (error) {
				Notification.create(NotificationType.ERROR, 'pages.sign_in.error')
			} else {
				this.signInRedirect()
			}
		} catch (err) {
			console.error(err)
			Notification.create(NotificationType.ERROR, 'errors.server_error')
		}
	}

	remindPasswordRedirect () {
		this.app.vue.$router.replace({ name: 'remind-password' })
	}

	signInRedirect () {
		const moderator = this.app.authorization.moderator
		if (moderator.user.permissions.includes('file_manager')) {
			this.app.vue.$router.replace({ name: 'file-manager', params: { projectName: 'root' } })
		} else if (moderator.user.permissions.includes('project_owner')) {
			const projectToRedirect = moderator.projects[0].name
			this.app.vue.$router.replace({ name: 'file-manager', params: { projectName: projectToRedirect } })
		}
	}
}

export {
	SignInPage
}
