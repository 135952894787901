<template>
	<div class="notification" :class="classes">
		<div class="content">
			<p>{{message}}</p>
		</div>
		<base-svg @click="notification.close()" name="close" />
		<div class="progress-bar"></div>
	</div>
</template>

<script>
import BaseSvg from '@a/components/atoms/BaseSvg'
import { Notification, NotificationType } from '@a/core/entities/Notification'
import { isObjectLike } from 'utils/objects'

export default {
	components: {
		BaseSvg
	},
	props: {
		notification: Notification
	},
	computed: {
		classes () {
			return {
				error: this.notification.type === NotificationType.ERROR,
				info: this.notification.type === NotificationType.INFO,
				success: this.notification.type === NotificationType.SUCCESS
			}
		},
		message () {
			const lang = this.$app.translator.active.alias

			if (isObjectLike(this.notification.message)) return this.notification.message[lang]

			return this.notification.message
		}
	}
}
</script>

<style lang="scss" scoped>
.notification {
	position: relative;
	background: white;
	border: 1px solid $primary;
	padding: 10px 10px 13px 10px;
	overflow: hidden;
	width: 100%;
	border-radius: $border-radius;
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.content {
		width: 100%;
	}
	svg {
		cursor: pointer;
		height: 18px;
		&:hover {
			transition: $transition;
			opacity: 0.6;
		}
	}
	.progress-bar {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 3px;
		background: $primary;
		animation: progress 5s forwards linear;
	}

	@keyframes progress {
		from {
			width: 100%;
		}
		to {
			width: 0;
		}
	}

	&.success {
		border-color: $success;
		svg {
			color: $success;
		}
		.progress-bar {
			background: $success;
		}
	}

	&.error {
		border-color: $danger;
		svg {
			color: $danger;
		}
		.progress-bar {
			background: $danger;
		}
	}
}
</style>
