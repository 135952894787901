import { EntityListPage } from '@a/core/pages/entity/list/EntityListPage'
import { checkPermissions } from '~/authorization/admin/utils/checkPermissions'
import { ActionColumn } from '@a/core/elements/columns/ActionColumn'
import { BaseColumn } from '@a/core/elements/columns/BaseColumn'
import { PermissionsColumn } from '@a/core/elements/columns/PermissionsColumn'

class ModeratorListPage extends EntityListPage {
	static entity = 'moderator'
	static visible = checkPermissions('user_management')

	getColumns () {
		return [
			{
				type: BaseColumn,
				name: 'id',
				sortParam: 'id',
				getter: moderator => moderator.id
			},
			{
				type: BaseColumn,
				name: 'name',
				sortParam: 'name',
				getter: moderator => moderator.name
			},
			{
				type: BaseColumn,
				name: 'email',
				sortParam: 'email',
				getter: moderator => moderator.user.email
			},
			{
				type: BaseColumn,
				name: 'group',
				getter: moderator => moderator.user?.userGroup?.alias || '-'
			},
			{
				type: PermissionsColumn,
				name: 'projects',
				getter: moderator => moderator.projects.map(project => project.name)
			},
			{
				type: ActionColumn,
				actions: ['remove', 'edit']
			}
		]
	}
}

export { ModeratorListPage }
