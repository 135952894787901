import { FileManagerPage } from '~/fileManager/admin/core/pages/FileManagerPage'
import { MenuElement } from '@a/core/layouts/default/menu/MenuElement'
import { rextServiceExtend } from './services/RextService'
import { checkPermissions } from '~/authorization/admin/utils/checkPermissions'
import { fileInputGenerator } from '~/fileManager/admin/core/fileManager/FileInput'
import { imageInputGenerator } from '~/fileManager/admin/core/fileManager/ImageInput'
class FileManagerModule {
	beforeInit (app) {
		app.on('moderator:dataFetched', this.createDynamicMenuElements.bind(this))
	}

	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
	}

	readyToAddPages (app) {
		app.addPage(FileManagerPage)
	}

	afterLayoutsCreated (app) {
		const layout = app.getLayout('default')

		const fileManagerList = new MenuElement({
			name: 'file_manager',
			icon: 'media',
			to: { name: 'file-manager', params: { projectName: 'root' } },
			visible: checkPermissions('file_manager')
		})

		layout.menu.addElement(fileManagerList)

		if (app.authorization.moderator) this.createDynamicMenuElements(app)
	}

	beforeInitReactive (app) {
		app.addElement('file_input', fileInputGenerator(app))
		app.addElement('image_input', imageInputGenerator(app))
	}

	createDynamicMenuElements (app) {
		const layout = app.getLayout('default')
		const moderatorProjects = app.authorization.moderator ? app.authorization.moderator.projects : []

		moderatorProjects.forEach(project => {
			const fileManagerList = new MenuElement({
				name: project.name,
				icon: 'media',
				to: { name: 'file-manager', params: { projectName: project.name } },
				visible: checkPermissions('project_owner')
			})

			layout.menu.addElement(fileManagerList)
		})
	}
}

export { FileManagerModule }
