<template>
	<div class="tab-content">
		<base-element
			v-for="item in activeTab.elements"
			:key="item._uid"
			:element="item"
		/>
	</div>
</template>

<script>
import BaseElement from '@a/components/atoms/BaseElement/BaseElement.vue'
export default {
	components: {
		BaseElement
	},
	props: {
		activeTab: Object
	}
}
</script>
