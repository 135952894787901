<template>
	<tr @click="goToDirectory" class="list-item" :class="{ 'marked-for-deletion': isMarkedForDeletion }">
		<td class="checkbox-col">
			<input type="checkbox" :disabled="!isDisabled" @click.stop="changeCheckboxState" :checked="listItem.isSelected">
		</td>
		<file-image-preview class="preview" v-if="isItemAnImage" :element="listItem.item"/>
		<td class="preview" v-else-if="isItemAFile">
			<base-svg class="icon" name="explorer_text_file"/>
		</td>
		<td class="preview" v-else>
			<base-svg class="icon explorer-directory-icon" name="explorer_directory"/>
		</td>
		<list-item-header-value v-for="(header, i) in headers" :key="i"
								:header="header" :value="getCellValue(listItem.item, header)"
								@unmark="unmarkForDeletion"
		/>
		<td class="download">
			<base-button class="download-btn" v-if="canFileBeDownloaded"  @click="download">
				<base-svg class="download-icon" name="explorer_download" />
				<span>{{ lang.download }}</span>
			</base-button>
		</td>
	</tr>
</template>

<script>
import prettyBytes from 'pretty-bytes'
import FileImagePreview from '~/fileManager/admin/components/FileImagePreview/FileImagePreview'
import BaseSvg from '@a/components/atoms/BaseSvg/BaseSvg'
import BaseButton from '@a/components/atoms/BaseButton/BaseButton'
import { ListDirectoryItem } from '~/fileManager/admin/core/fileManager/ListDirectoryItem'
import mixin from '~/fileManager/admin/mixins/ExplorerHeadersMixin'
import ListItemHeaderValue from '~/fileManager/admin/components/ListItemHeaderValue/ListItemHeaderValue'

export default {
	mixins: [mixin],
	components: {
		FileImagePreview,
		BaseSvg,
		BaseButton,
		ListItemHeaderValue
	},
	props: {
		listItem: Object
	},
	computed: {
		lang () {
			return this.$app.translator.active.translations['file-manager']
		},
		isItemAnImage () {
			return ['png', 'jpg', 'gif', 'jpeg', 'webp'].includes(this.listItem.item.extension)
		},
		isItemAFile () {
			return this.listItem.item.extension ? !this.isItemAnImage : false
		},
		canFileBeDownloaded () {
			return this.isItemAnImage || this.isItemAFile
		},
		isDisabled () {
			if (this.listItem.explorer.canChooseMoreItems) return true
			if (this.listItem.explorer.choosedItems.find(listItem => this.listItem === listItem) &&
				!this.listItem.explorer.canChooseMoreItems) return true
			return false
		},
		isMarkedForDeletion () {
			return this.listItem.item.markedForDeletion
		}
	},
	methods: {
		getCellValue (element, header) {
			return {
				size: this.formatSize(element.size),
				birthDate: this.formatBirthDate(element[header]),
				markedForDeletion: this.formatMarkedForDeletion(element[header])
			}[header] || element[header]
		},
		async goToDirectory () {
			if (this.listItem instanceof ListDirectoryItem) await this.listItem.open()
		},
		async download () {
			await this.listItem.download(this.$app)
		},
		async unmarkForDeletion () {
			await this.listItem.unmarkForDeletion()
		},
		formatMarkedForDeletion (markedForDeletion) {
			if (markedForDeletion) return 'Do usunięcia'
			return '-'
		},
		formatBirthDate (birthDate) {
			const date = new Date(birthDate)
			return date.toLocaleDateString()
		},
		formatSize (elementSize) {
			return elementSize ? prettyBytes(elementSize) : ' '
		},
		changeCheckboxState () {
			const clickEvent = {
				false: this.listItem.choose.bind(this.listItem),
				true: this.listItem.unchoose.bind(this.listItem)
			}[this.listItem.isSelected]
			clickEvent()
		}
	}
}
</script>

<style scoped lang="scss">
.list-item {
	border-bottom: 1px solid #ACACAC;
	height: 73px;
	background-color: $white;
	cursor: pointer;
	@include transition;
	@include media-breakpoint-up (xl) {
		&:hover {
			background: $grey-400;
		}
	}
	&.marked-for-deletion {
		background-color: #e244448f;
	}
	td {
		padding: 10px 20px;
		&.birthDate {
			width: 100px;
		}
		&.name {
			padding-left: 5rem;
			min-width: 250px;
		}
		&.size {
			width: 70px;
		}
		&.extension {
			width: 70px;
		}
		&.image-preview-wrapper {
			width: 120px;
		}
		&.preview {
			text-align: center;
			width: 120px;
		}
		&.download {
			width: 180px;
		}
	}
	.extension, .size, .birthDate {
		text-align: center;
	}
	.icon {
		width: 120px;
		height: 50px;
		fill: rgb(172, 172, 172);
	}
	.download-btn {
		display: flex;
		align-items: center;
		gap: .5rem;
	}
	.download-icon {
		width: 15px;
		height: 15px;
	}
	.explorer-directory-icon {
		width: 42px;
		height: 32px;
	}
	.checkbox-col {
		width: 30px;
		text-align: center;
	}
}
</style>
