import { asyncForEach } from 'utils/async'
import BaseForm from '../../components/organisms/BaseForm'
import { WrapperElement } from './WrapperElement'

class Form extends WrapperElement {
	component = BaseForm
	initialValues
	initialValuesKeys
	processing

	onSubmit (callback) {
		this.submitCallback = callback
	}

	async submit () {
		this.processing = true
		await this.submitCallback()
		this.processing = false
	}

	async setInitialValues (values) {
		this.initialValues = values
		await asyncForEach(this.elements, element => element.setInitialValue(values))
	}
}

const formGenerator = app => async (object) => {
	return async () => {
		const form = new Form()
		await asyncForEach(object.elements, async el => form.addElement(await app.createElement(el)))
		return form
	}
}

export { Form, formGenerator }
