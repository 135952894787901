<template>
	<div class="dropdown">
		<div
			@click="dropdown.changePassword"
			class="dropdown-item"
		>
			<base-svg class="icon" name="vpn_key"/>
			<base-font color="white" uppercase>{{l.layout.authorization.dropdown.change_password}}</base-font>
		</div>
		<div
			@click="dropdown.signOut"
			class="dropdown-item"
		>
			<base-svg class="icon" name="axislock"/>
			<base-font color="white" uppercase>{{l.layout.authorization.dropdown.logout}}</base-font>
		</div>
	</div>
</template>
<script>
import BaseSvg from '@a/components/atoms/BaseSvg'
import BaseFont from '@a/components/atoms/BaseFont'

export default {
	components: {
		BaseSvg,
		BaseFont
	},
	props: {
		dropdown: Object
	}
}
</script>
<style lang="scss" scoped>
.dropdown {
	position: absolute;
    top: 30px;
    left: -40px;
	background-color: $primary;
	box-shadow: -15px 16px 18px rgba(0, 0, 0, 0.3);
	border: 1px solid $primary;
	@include media-breakpoint-up (md) {
		position: absolute;
		top: 100%;
		left: 0;
	}
	&-item {
		display: flex;
		align-items: center;
		cursor: pointer;
		padding: 1rem 1.5rem;
		transition: .3s ease;
		color: white;
		background: $primary;
		min-width: 160px;
	}
	& .icon {
		fill: $white;
		margin-right: 1.5rem;
		width: 20px;
		height: 20px;
	}
}
</style>
