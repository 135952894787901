<template>
	<div class="notifications-wrapper" >
		<base-notification v-for="notification in notifications" :key="notification.id" :notification="notification"/>
	</div>
</template>

<script>
import BaseNotification from '@a/components/molecules/BaseNotification'
export default {
	computed: {
		notifications () {
			return this.$app.notifications
		}
	},
	components: {
		BaseNotification
	}
}
</script>

<style lang="scss" scoped>
.notifications-wrapper {
	position: fixed;
	right: 30px;
	bottom: 30px;
	display: flex;
	width: 100%;
	max-width: 250px;
	flex-direction: column;
	z-index: 2000;
	@include media-breakpoint-up(md) {
		max-width: 300px;
	}
}
</style>
