import { Input } from './Input'
import { ReactiveClass } from '@a/core/ReactiveClass'
import InputSelectTemplate from '@a/components/modules/inputs/organisms/InputSelect.vue'

class Option extends ReactiveClass {
	id = null
	name = ''

	constructor ({ id, name }) {
		super()
		this.id = id
		this.name = this.getName(name)
	}

	get isChoosed () {
		return this.select.choosedOption === this
	}

	choose () {
		this.select.choose(this)
	}

	getName (name) {
		if (typeof name === 'object') return name
		const translation = window.app.translator.getTranslation(name, name)
		return translation
	}
}

class InputSelect extends Input {
	component = InputSelectTemplate
	options = []
	choosedOption = null
	_open = false
	ready = false

	constructor ({ options, value, ...data }) {
		super(data)
		if (options) this.setOptions(options)
		this.default = value
		if (value) this.value = value

		this.closeHandler = this.close.bind(this)
	}

	get value () {
		if (!this.choosedOption) return null
		return this.choosedOption.id
	}

	set value (id) {
		if (!this.ready) {
			this._temporaryValue = id
			return 0
		}
		const option = this.options.find(option => option.id === id)
		if (!option) return 0
		this.choosedOption = option
	}

	get isOpen () {
		return this._open
	}

	get isClose () {
		return !this._open
	}

	choose (option) {
		this.choosedOption = option
		this._emit('value:changed', this.value)
		setTimeout(() => this.close(), 0)
	}

	close () {
		this._open = false
		document.removeEventListener('click', this.closeHandler)
	}

	open () {
		this._open = true
		document.addEventListener('click', this.closeHandler, true)
	}

	clear () {
		this.value = this.default
	}

	setOptions (data) {
		const options = this.dataToOptionObject(data)
		options.map(option => {
			option.select = this
			this.options.push(option)
		})
		this.ready = true
		const choosedOption = options.find(option => option.id === this._temporaryValue) || options[0]
		this.choosedOption = choosedOption
	}

	dataToOptionObject (data) {
		return data.map(object => {
			if (object instanceof Option) return object
			return new Option(object)
		})
	}
}

const inputSelectGenerator = app => async (object) => {
	return async () => {
		const input = new InputSelect({
			...object
		})
		return input
	}
}

export { InputSelect, Option, inputSelectGenerator }
