import { EntityEditFormPage } from '@a/core/pages/entity/edit/EntityEditFormPage'
import { checkPermissions } from '~/authorization/admin/utils/checkPermissions'
import { moderatorFormSchema } from './moderatorFormSchema'

class ModeratorEditPage extends EntityEditFormPage {
	static entity = 'moderator'
	static visible = checkPermissions('user_management')

	async getFormSchema () {
		return {
			$type: 'form',
			elements: [
				...(await moderatorFormSchema(this.app)),
				{
					$type: 'column',
					grid: {
						col: 12
					},
					elements: [
						{
							$type: 'buttons',
							right: true,
							label: 'entities.moderator.pages.list.buttons'
						}
					]
				}
			]
		}
	}
}

export { ModeratorEditPage }
