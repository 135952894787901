<template>
	<div class="header">
		<h1 class="header-text"><slot /></h1>
		<div class="right">
			<slot name="right" />
		</div>
	</div>
</template>
<script>
export default {
	name: 'PageHeader'
}
</script>
<style lang="scss" scoped>
.header {
	background: $white;
	margin: -15px -5px 0 -5px;
	padding: 15px 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	&-text {
		font-size: 16px;
		padding-right: 1rem;
		font-weight: bold;
		margin: 0;
		@include media-breakpoint-up(md) {
			font-size: 22px;
			padding-right: 0;
		}
	}
	@include media-breakpoint-up(md) {
		margin: -15px -15px 0 -15px;
		padding: 20px;
	}
}
</style>
