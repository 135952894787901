import { Column } from './Column'
import BaseColumnTemplate from '@a/components/atoms/BaseColumn'

class BaseColumn extends Column {
	component = BaseColumnTemplate
	constructor ({ getter, textDirectionVertical, ...data }) {
		super(data)
		this.getter = getter
		this.textDirectionVertical = textDirectionVertical
	}

	getValue (object) {
		return this.getter(object)
	}
}

export { BaseColumn }
