import { SignInPage } from './core/pages/SignInPage'
import { RemindPasswordPage } from './core/pages/RemindPasswordPage'
import { UserChangePasswordPage } from './core/pages/UserChangePasswordPage'
import { ChangePasswordPage } from './core/pages/ChangePasswordPage'
import { MenuElement } from '@a/core/layouts/default/menu/MenuElement'
import { MenuParentElement } from '@a/core/layouts/default/menu/MenuParentElement'
import { UserDropdownTopbar } from './core/layouts/deafult/userDropdown/UserDropdownTopbar'
import { rextServiceExtend } from './services/RextService'
import { Authorization } from './core/authorization/Authorization'
import { dashboardPageExtend } from './core/pages/DashboardPage'
import { ModeratorListPage } from './core/pages/entities/moderator/ModeratorListPage'
import { Moderator } from './entities/Moderator'
import { UserGroup } from './entities/UserGroup'
import { ModeratorCreatePage } from './core/pages/entities/moderator/ModeratorCreatePage'
import { ModeratorEditPage } from './core/pages/entities/moderator/ModeratorEditPage'
import { UserGroupListPage } from './core/pages/entities/userGroup/UserGroupListPage'
import { UserGroupCreatePage } from './core/pages/entities/userGroup/UserGroupCreatePage'
import { UserGroupEditPage } from './core/pages/entities/userGroup/UserGroupEditPage'
import { checkPermissions } from './utils/checkPermissions'
import { Notification, NotificationType } from '@a/core/entities/Notification'
class AuthorizationModule {
	readyToAddServices (app) {
		app.authorization = new Authorization(app)
	}

	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
	}

	async afterServicesCreated (app) {
		await app.authorization.init()
	}

	readyToAddPages (app) {
		app.addPage(SignInPage)
		app.addPage(RemindPasswordPage)
		app.addPage(UserChangePasswordPage)
		app.addPage(ChangePasswordPage)
		app.addPage(ModeratorListPage)
		app.addPage(ModeratorCreatePage)
		app.addPage(ModeratorEditPage)
		app.addPage(UserGroupListPage)
		app.addPage(UserGroupCreatePage)
		app.addPage(UserGroupEditPage)
	}

	readyToExtendPages (app) {
		app.extendPage('dashboard', dashboardPageExtend)
	}

	readyToAddEntities (app) {
		app.addEntity(Moderator)
		app.addEntity(UserGroup)
	}

	afterRoutesCreated (app) {
		app.router.beforeEach((to, from, next) => {
			if (!to.meta.page) throw new Error('CAN NOT FIND PAGE')
			const isVisible = to.meta.page.visible()
			if (isVisible) next()
			else if (!app.authorization.isAuthorizated) next({ name: 'sign-in' })
			else if (!isVisible) {
				next({ name: 'dashboard' })
				Notification.create(NotificationType.ERROR, 'errors.permission_error')
			} else next()
		})
	}

	afterLayoutsCreated (app) {
		const layout = app.getLayout('default')
		const parent = new MenuParentElement({
			name: 'users',
			icon: 'person'
		})

		parent.addElement(new MenuElement({
			name: 'list',
			icon: 'list',
			to: { name: 'moderator-list' },
			visible: checkPermissions('user_management')
		}))

		parent.addElement(new MenuElement({
			name: 'permissions',
			icon: 'info',
			to: { name: 'user-group-list' },
			visible: checkPermissions('permission_management')
		}))

		layout.menu.addElement(parent)

		const dropdown = new UserDropdownTopbar()
		layout.topbar.addElement(dropdown)
	}
}
export { AuthorizationModule }
