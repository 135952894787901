const AppPrompt = {
	openPrompt (prompt) {
		this.prompt = prompt
	},
	closePrompt () {
		this.prompt = null
	},
	afterRoutesCreated () {
		this.router.beforeEach((to, from, next) => {
			this.closePrompt()
			next()
		})
	}
}
export {
	AppPrompt
}
