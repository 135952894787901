import { Setting } from '~/settings/admin/core/entities/Setting'
import { SettingEditPage } from '~/settings/admin/core/pages/SettingEditPage'
import { checkPermissions } from '~/authorization/admin/utils/checkPermissions'
import { MenuElement } from '@a/core/layouts/default/menu/MenuElement'
import { generalFormSchema } from '~/settings/admin/core/pages/generalFormSchema'

class SettingsModule {
	readyToAddEntities (app) {
		app.addEntity(Setting)
	}

	readyToAddPages (app) {
		app.addPage(SettingEditPage)
	}

	readyToExtendPages (app) {
		const Page = app.getPage('setting-edit')
		Page.types.general = generalFormSchema
	}

	afterLayoutsCreated (app) {
		const layout = app.getLayout('default')
		const settings = new MenuElement({
			name: 'general_settings',
			icon: 'settings',
			to: { name: 'setting-edit', params: { module: 'general' } },
			visible: checkPermissions('settings_management')
		})

		layout.menu.addElement(settings)
	}
}

export { SettingsModule }
