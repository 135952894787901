import { Column } from './Column'
import OrderColumnTemplate from '@a/components/atoms/OrderColumn'
import OrderHeaderColumnTemplate from '@a/components/atoms/OrderHeaderColumn'

class OrderColumn extends Column {
	component = OrderColumnTemplate
	headerComponent = OrderHeaderColumnTemplate
}

export { OrderColumn }
