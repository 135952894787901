<template>
	<div class="wrapper">
		<cms-image
			v-if="modal.data.path"
			:value="`${modal.data.path}`"
			:lazy="true"
			:base="{width: 1280, height: 720, fit: 'contain'}"
		/>
	</div>
</template>

<script>
import CmsImage from '~/cms/admin/components/CmsImage'

export default {
	components: {
		CmsImage
	},
	computed: {
		modal () {
			return this.$app.modal
		}
	}
}
</script>

<style scoped lang="scss">
.wrapper {
	display: flex;
	justify-content: center;

	::v-deep img {
		object-fit: contain;
		width: 100%;
	}
}
</style>
<style lang="scss">
.modal {
	width: fit-content !important;
}
</style>
