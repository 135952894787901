import { DashboardPage } from '@a/core/pages/DashboardPage'

const AppPage = {
	readyToAddPages () {
		this.addPage(DashboardPage)
	}
}

export {
	AppPage
}
