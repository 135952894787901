function tryParseJSON (str, ifErrorReturn = false) {
	try {
		JSON.parse(str)
	} catch (e) {
		return ifErrorReturn
	}
	return JSON.parse(str)
}

const getValue = (path, object, defaultValue = '') => {
	if (!Array.isArray(path)) return getValue(path.split('.'), object, defaultValue)
	if (path.length === 0) return object
	const attribute = path.shift()
	if (typeof object[attribute] === 'undefined') return defaultValue
	return getValue(path, object[attribute], defaultValue)
}

const setValue = (path, object, value) => {
	if (!Array.isArray(path)) {
		const pathToArray = path.split('.')
		return setValue(pathToArray, object, value)
	}
	const attribute = path.shift()
	if (path.length === 0) {
		object[attribute] = value
		return null
	} else if (!object[attribute]) object[attribute] = {}
	return setValue(path, object[attribute], value)
}

const isEmptyOrFalsy = value => {
	if (Array.isArray(value)) return !value.length
	else if (typeof value === 'object' && value !== null) {
		const objectAttributes = Object.keys(value)
		return !objectAttributes.length
	} else {
		return !value
	}
}
const objectCopyByJSON = object => JSON.parse(JSON.stringify(object))
const isObjectLike = value => value !== null && typeof value === 'object' && !Array.isArray(value)

export {
	tryParseJSON,
	getValue,
	setValue,
	isEmptyOrFalsy,
	objectCopyByJSON,
	isObjectLike
}
