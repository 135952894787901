<template>
	<div class="explorer-navbar-wrapper"
		:class="{ 'navbar-not-extended': navbarNotExtended }"
	>
		<div class="navbar">
			<div class="left">
				<base-button class="back-btn" @click="goUp">
					<base-svg class="icon" name="explorer_back" />
				</base-button>
				<div class="path-wrapper">
					<template v-for="directory in explorer.currentDirectory.breadcrumbs">
						<div class="path" v-if="!isBreadcrubmARootPath(directory)" :key="directory.name">
							<span>/</span>
							<span class="path-part" @click="goToDirectory(directory)">
								{{ directory.name }}
							</span>
						</div>
					</template>
				</div>
			</div>
			<div class="right">
				<base-button :class="{ 'new-directory-button-clicked': isNewDirectoryFormVisible }"
							@click="changeNewDirectoryFormVisibility"
				>
					<base-svg class="icon" name="explorer_folder" />
					<span>{{ lang.add_folder }}</span>
				</base-button>
				<base-button :class="{ 'upload-button-clicked': isUploadAreaVisible }"
							@click="changeUploadAreaVisibility"
				>
					<base-svg class="icon" name="explorer_add_file" />
					<span>{{ lang.upload_file }}</span>
				</base-button>
				<base-button @click="downloadChosen">
					<base-svg class="icon download-icon" name="explorer_add_file" />
					<span>{{ lang.download_chosen_items }}</span>
				</base-button>
				<base-button class="" @click="remove" v-if="canRemoveItems">
					<base-svg class="icon" name="explorer_delete" />
					<span>{{ lang.delete }}</span>
				</base-button>
				<base-button class="" ref="markDeletionButton" @click="markForDeletion" v-else>
					<base-svg class="icon" name="explorer_delete" />
					<span>{{ lang.markForDeletion }}</span>
				</base-button>
			</div>
		</div>
		<file-uploader v-if="isUploadAreaVisible" :uploader="explorer.uploader" />
		<create-directory-form v-if="isNewDirectoryFormVisible" :explorer="explorer" />
	</div>
</template>

<script>
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import BaseButton from '@a/components/atoms/BaseButton/BaseButton'
import BaseSvg from '@a/components/atoms/BaseSvg/BaseSvg'
import FileUploader from '~/fileManager/admin/components/FileUploader/FileUploader'
import CreateDirectoryForm from '~/fileManager/admin/components/CreateDirectoryForm/CreateDirectoryForm'
import { checkPermissions } from '~/authorization/admin/utils/checkPermissions'

export default {
	components: {
		BaseSvg,
		BaseButton,
		FileUploader,
		CreateDirectoryForm
	},
	props: {
		explorer: Object
	},
	computed: {
		lang () {
			return this.$app.translator.active.translations['file-manager']
		},
		navbarNotExtended () {
			return !this.isUploadAreaVisible && !this.isNewDirectoryFormVisible
		},
		canRemoveItems () {
			return checkPermissions('file_manager')()
		}
	},
	data: () => ({
		isUploadAreaVisible: false,
		isNewDirectoryFormVisible: false,
		tippyInstance: null
	}),
	methods: {
		isBreadcrubmARootPath (breadcrumb) {
			if (breadcrumb.path.length === 0) return true
			return false
		},
		async goUp () {
			await this.explorer.goUp()
		},
		async remove () {
			await this.explorer.removeChoosedItems()
		},
		async markForDeletion () {
			await this.explorer.markForDeletionChoosedItems()
		},
		async goToDirectory (directory) {
			await this.explorer.goToDirectory(directory)
		},
		changeUploadAreaVisibility () {
			if (this.isUploadAreaVisible) this.isUploadAreaVisible = false
			else {
				this.isUploadAreaVisible = true
				this.isNewDirectoryFormVisible = false
			}
		},
		changeNewDirectoryFormVisibility () {
			if (this.isNewDirectoryFormVisible) this.isNewDirectoryFormVisible = false
			else {
				this.isNewDirectoryFormVisible = true
				this.isUploadAreaVisible = false
			}
		},
		clearUploader () {
			this.explorer.uploader.removeAll()
		},
		async downloadChosen () {
			await this.explorer.downloadChosen()
		},
		initTippy () {
			this.tippyInstance = tippy(this.$refs.markDeletionButton.$el, {
				theme: 'light',
				content: this.lang.mark_for_deletion_tooltip
			})
		}
	},
	mounted () {
		if (!this.canRemoveItems) this.initTippy()
	},
	destroyed () {
		if (!this.canRemoveItems) this.tippyInstance.destroy()
	}
}
</script>

<style scoped lang="scss">
.explorer-navbar-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	.navbar {
		display: flex;
		width: inherit;
		overflow: auto;
		gap: 2rem;

		.left, .right {
			display: flex;
			align-items: center;
			gap: 2rem;
		}

		.right {
			margin-left: auto;
		}
	}
	.path-wrapper {
		width: max-content;
		display: flex;
		gap: .5rem;

		.path {
			display: flex;
			gap: .5rem;

			.path-part {
				&:hover {
					cursor: pointer;
				}
			}
			span {
				color: #ACACAC;
				font-size: 1.8rem;

				&:last-child {
					color: black;
				}
			}
		}
		.path:not(:last-of-type) {
			.path-part {
				color: #ACACAC;
			}
		}
	}
	button {
		display: flex;
		align-items: center;
		background: #f4f4f4;
		color: $primary;
		border: 1px solid #ACACAC;
		border-bottom: none;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;

		span {
			width: max-content;
		}
	}
	.back-btn {
		background: $white;
		color: $primary;
	}

	.download-btn {
		background: $primary;
		color: $white;
	}
	::v-deep .upload-button-clicked {
		z-index: 1;
	}
	::v-deep .new-directory-button-clicked {
		z-index: 1;
	}
	.icon {
		width: 15px;
		height: 15px;

		&:not(:last-child) {
			margin-right: .5rem;
		}
	}
	.download-icon {
		transform: rotate(180deg);
	}
}
.navbar-not-extended {
	border-bottom: 1px solid #ACACAC;
}
</style>
