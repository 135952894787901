import ListPage from '@a/pages/lists/ListPage.vue'
import { Page } from '../../Page'
class EntityListPage extends Page {
	static entity = ''
	static component = ListPage
	static authorization = true

	static get path () {
		return `/${this.entity}`
	}

	static get alias () {
		return `${this.entity}-list`
	}

	constructor () {
		super(...arguments)
		this.app.on(`entity:${this.constructor.entity}:*`, this.fetchList.bind(this))
	}

	new = true
	ready = false
	relations = []
	where
	orderby
	list

	getListSchema () {
		return {
			$type: 'entity_list',
			Entity: this.app.getEntity(this.constructor.entity),
			relations: this.relations,
			where: this.where,
			orderby: this.orderby,
			columns: this.getColumns(),
			page: this.route.meta.listPage
		}
	}

	getColumns () {
		return []
	}

	get Entity () {
		return this.app.getEntity(this.constructor.entity)
	}

	async getList () {
		const listSchema = await this.getListSchema()
		const listGenerator = await window.app.createElement(listSchema)
		const list = await listGenerator()
		return list
	}

	async fetchList () {
		await this.list.fetch()
		await this.list.countData()
	}

	async afterRedirect () {
		super.afterRedirect()
		this.list = await this.getList()
		await this.list.create()
		this.list.on('page:changed', () => this.pageChangedHandler())
		this.ready = true
	}

	pageChangedHandler () {
		this.route.meta.listPage = this.list.page
	}

	createNewObject () {
		this.app.vue.$router.push({ name: `${this.Entity.alias}-create` })
	}
}

export { EntityListPage }
