class Topbar {
	elements = []

	addElement (TopbarElement) {
		this.elements.push(TopbarElement)
	}
}

export {
	Topbar
}
