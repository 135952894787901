<template>
    <div
        v-if="text"
        class="input-info"
        v-tooltip="{content: text}"
    >
        <base-svg name="info"/>
    </div>
</template>

<script>
import BaseSvg from '@a/components/atoms/BaseSvg'

export default {
	components: {
		BaseSvg
	},
	props: {
		text: [String, Boolean]
	}
}
</script>

<style lang="scss" scoped>
    .input-info {
        width: 35px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        position: relative;
    }
    .icon-info {
        fill: $grey-500;
    }
</style>
