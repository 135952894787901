import { Input } from './Input'
import InputEditorTemplate from '@a/components/modules/inputs/organisms/InputEditor'
import { replacer } from 'utils/strings'
import { setValue } from 'utils/objects'

class InputEditor extends Input {
	component = InputEditorTemplate
	constructor ({ object }) {
		super(object)
		this.toolbar = object.customToolbar
	}

	getValue () {
		if (!this.attribute) return this.value
		const value = {}
		setValue(this.attribute, value, replacer(this.value, /(style="\w+.*?")/g, ''))
		return value
	}
}

const inputEditorGenerator = app => async (object) => {
	return async () => {
		const inputEditor = new InputEditor({ object })
		return inputEditor
	}
}

export { InputEditor, inputEditorGenerator }
