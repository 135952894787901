<template>
	<picture :key="value">
		<template v-for="(source, i) in sources" >
			<source v-bind="source" :key="i">
		</template>
		<img :class="{lazyload: lazy}" v-bind="baseSrc"/>
	</picture>
</template>

<script>
import { config } from '@a/config/config'
import { serialize } from 'utils/strings'

const mediaQueries = {
	sm: '(min-width: 576px)',
	md: '(min-width: 768px)',
	lg: '(min-width: 992px)',
	xl: '(min-width: 1200px)',
	xxl: '(min-width: 1400px)'
}
const breakpoints = Object.keys(mediaQueries)
const breakpointProps = {
	type: [Object, Boolean]
}
const breakpointsProps = breakpoints.reduce((props, breakpoint) => {
	props[breakpoint] = breakpointProps
	return props
}, {})

export default {
	props: {
		value: String,
		layout: Boolean,
		lazy: {
			type: Boolean,
			default: false
		},
		base: breakpointProps,
		...breakpointsProps
	},
	computed: {
		imageUrl () {
			return config.services.rext.imagesUrl + this.value
		},
		sources () {
			const srcType = this.lazy ? 'data-srcset' : 'srcset'
			const getSource = ({ breakpoint, format, type }) => ({
				media: mediaQueries[breakpoint],
				[srcType]: this.imageUrl + serialize({
					...this[breakpoint],
					format
				}),
				type
			})
			return breakpoints
				.filter(breakpoint => this[breakpoint])
				.reduce((sources, breakpoint) => {
					const source = getSource({
						breakpoint
					})
					const sourceWebp = getSource({
						breakpoint,
						format: 'webp',
						type: 'image/webp'
					})
					sources.push(source, sourceWebp)
					return sources
				}, [])
		},
		baseSrc () {
			const smallImageSrc = this.imageUrl + serialize({
				width: 10
			})
			const imageSrc = this.imageUrl + serialize({
				...this.base
			})
			if (this.lazy) {
				return {
					src: smallImageSrc,
					'data-srcset': imageSrc
				}
			}
			return {
				src: imageSrc
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.lazyload {
	filter: blur(5px);
}
.lazyloaded, .lazyload {
	height: 100%;
}
</style>
