<template>
	<th class="table-row" :class="{sortable: column.sortMode}" @click="column.changeOrder()">
		<div class="inner-wrapper">
			<span class="column-name" :class="{ verticalHeader: textDirectionVertical }">{{header}}</span>
			<div v-if="column.sortMode" class="arrow-wrapper">
				<div v-if="column.order === 'ASC'">
					<base-svg name="sort_arrow_up"/>
				</div>
				<div v-else-if="column.order === 'DESC'">
					<base-svg name="sort_arrow_down"/>
				</div>
				<div v-else>
					<base-svg name="sort_arrows"/>
				</div>
			</div>
		</div>
	</th>
</template>

<script>
import BaseSvg from '@a/components/atoms/BaseSvg'

export default {
	components: {
		BaseSvg
	},
	computed: {
		activeLanguage () {
			return this.$app.translator.active.alias
		},
		header () {
			if (!this.column.name && this.column.headerText) return this.column.headerText
			else {
				const langPath = `entities.${this.alias}.pages.list.table.${this.column.name}`
				const value = this.$app.translator.getTranslation(`${langPath}`)[this.activeLanguage]
				return value
			}
		},
		textDirectionVertical () {
			return this.column.textDirectionVertical === true
		}
	},
	props: {
		column: Object,
		alias: String
	}
}
</script>

<style lang="scss" scoped>
.verticalHeader {
	writing-mode: vertical-rl;
}
.table-row {
	text-align: left;
	&.sortable {
		cursor: pointer;
	}
	.column-name {
		font-size: 1.2rem;
		vertical-align: top;
		@include media-breakpoint-up(md) {
			font-size: 1.3rem;
		}
	}
	.inner-wrapper {
		display: flex;
	}
	.arrow-wrapper {
		display: inline-block;
	}
	svg {
		width: 15px;
		height: 15px;
	}
}
</style>
