import { Page } from '@a/core/pages/Page'
import ChangePassword from '../../pages/ChangePassword.vue'
import { InputPassword, Rule } from '@a/core/elements/inputs/InputPassword'
import { Notification, NotificationType } from '@a/core/entities/Notification'
import { getPasswordRules } from 'utils/inputs/passwordRules'

class ChangePasswordPage extends Page {
	static alias = 'change-password'
	static path = '/change-password/:token'
	static component = ChangePassword

	inputPassword
	view = 'loader'

	layout = 'blank'

	get password () {
		return this.inputPassword.value
	}

	init () {
		super.init()
		this.createInputs()
	}

	async afterRedirect () {
		if (this.app.authorization.isAuthorizated) return this.dashboardRedirect()
		try {
			const { error } = await this.app.getService('rext').checkToken(this.params.token)
			if (error) this.view = 'error'
			else this.view = 'form'
		} catch (err) {
			console.error(err)
			Notification.create(NotificationType.ERROR, 'errors.server_error')
		}
	}

	createInputs () {
		this.inputPassword = new InputPassword({
			name: 'password',
			label: 'pages.change_password.new_password',
			rules: getPasswordRules(Rule)
		})
	}

	async changePassword () {
		try {
			if (!await this.inputPassword.validate()) {
				return Notification.create(NotificationType.ERROR, 'pages.change_password.password_invalidate')
			}
			const { error } = await this.app.getService('rext').changePassword({
				token: this.params.token,
				password: this.password
			})
			if (error) {
				Notification.create(NotificationType.ERROR, 'pages.change_password.error_during_processing')
			} else {
				this.signInRedirect()
				Notification.create(NotificationType.SUCCESS, 'pages.change_password.success')
			}
		} catch (err) {
			console.error(err)
			Notification.create(NotificationType.ERROR, 'pages.change_password.error_during_processing')
		}
	}

	signInRedirect () {
		this.app.vue.$router.replace({ name: 'sign-in' })
	}

	dashboardRedirect () {
		this.app.vue.$router.replace({ name: 'dashboard' })
	}
}

export {
	ChangePasswordPage
}
