import { Modal } from '@a/core/application/Modal'
import ImageModalComponent from './ImageModal.vue'

class ImageModal extends Modal {
	component = ImageModalComponent

	static createAndOpen (data) {
		const modal = ImageModal.create()
		modal.data = data
		modal.open()
		return modal
	}
}

export { ImageModal }
