import { Page } from '@a/core/pages/Page'
import UserChangePassword from '../../pages/UserChangePassword.vue'
import { Notification, NotificationType } from '@a/core/entities/Notification'
import { getPasswordRules } from 'utils/inputs/passwordRules'
import { checkAuthorization } from '~/authorization/admin/utils/checkAuthorization'
import { required } from 'utils/inputs/validation'
import { Rule } from '@a/core/elements/inputs/InputPassword'

class UserChangePasswordPage extends Page {
	static alias = 'user-change-password'
	static path = '/user-change-password'
	static component = UserChangePassword
	static visible = checkAuthorization

	inputPassword
	inputOldPassword

	view = 'form'

	layout = 'default'
	processing = false
	ready = false

	init () {
		super.init()
		this.createForm()
	}

	async createForm () {
		const form = {
			$type: 'form',
			elements: [
				{
					$type: 'column',
					grid: {
						sm: '12',
						lg: '6',
						xl: '4'
					},
					elements: [
						{
							$type: 'password_input',
							attribute: 'oldPassword',
							name: 'oldPassword',
							label: 'pages.user_change_password.old_password',
							rules: [],
							validation: [required()]
						},
						{
							$type: 'password_input',
							attribute: 'password',
							name: 'password',
							label: 'pages.user_change_password.new_password',
							rules: getPasswordRules(Rule)
						},
						{
							$type: 'button_submit',
							label: 'pages.user_change_password.change_password'
						}
					]
				}
			]
		}

		const formCreateFunction = await window.app.createElement(form)
		this.form = await formCreateFunction()
		await this.form.create()
		this.form.onSubmit(this.changePassword.bind(this))
		this.ready = true
	}

	async changePassword () {
		try {
			this.processing = true

			const body = await this.form.getValue()

			const { error, alias } = await this.app.getService('rext').userChangePassword(body)

			this.processing = false

			if (!error) {
				Notification.create(NotificationType.SUCCESS, 'pages.user_change_password.success')
				return this.dashboardRedirect()
			}
			if (alias === 'OLD_PASSWORD_IS_NOT_CORRECT') {
				return Notification.create(NotificationType.ERROR, 'pages.user_change_password.wrong_old_password')
			}
			Notification.create(NotificationType.ERROR, 'pages.user_change_password.error_during_processing')
		} catch (err) {
			console.error(err)
			Notification.create(NotificationType.ERROR, 'pages.user_change_password.error_during_processing')
		}
	}

	dashboardRedirect () {
		this.app.vue.$router.replace({ name: 'dashboard' })
	}
}

export {
	UserChangePasswordPage
}
