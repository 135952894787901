<template>
    <input
        :placeholder="input.placeholder"
        :id="input.name"
        :type="type"
        :disabled="input.disable"
        v-model="input.value"
        :class="{
            invalid: input.isInvalidAndTouched
        }"
        @blur="blurHandler"
    >
</template>

<script>

export default {
	props: {
		input: Object,
		type: String
	},
	methods: {
		blurHandler () {
			if (this.input.markAsTouched) this.input.markAsTouched()
		}
	}
}
</script>

<style lang="scss" scoped>
    input {
		font-size: $font-size-base;
		border: 1px solid $grey-400;
		border-radius: $border-radius;
		padding: 10px;
		width: 100%;
		height: 45px;
		padding-right: 30px;
		line-height: 1;
		background-color: $white;
		/* Chrome, Safari, Edge, Opera */
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		&[type=number] {
			-moz-appearance:textfield;
		}

		&.invalid {
			border:1px solid $danger;
		}
		&::placeholder{
			color: $grey-800;
			font-size: 1.4rem;
		}
		&.disabled,
		&:disabled {
			background-color: $light-gray;
			color: $grey-800;
		}
	}
</style>
