<template>
	<div class="select-choosed-option" >
		<base-svg name="close" @click="option.remove()"/>
		{{option.name}}
	</div>
</template>

<script>
import BaseSvg from '@a/components/atoms/BaseSvg'

export default {
	components: {
		BaseSvg
	},
	props: {
		option: Object
	}
}
</script>

<style lang="scss" scoped>
	.select-choosed-option {
		svg {
			height: 18px;
		}
		opacity: 1;
		margin-top: 10px;
		height: 25px;
		overflow: hidden;
		@include transition;
		display: flex;
		align-items: center;
	}
</style>
