import { Element } from '@a/core/elements/Element'
import { asyncForEach, asyncMap } from 'utils/async'
import RepeaterElementComponent from '@a/components/modules/forms/RepeaterElement/RepeaterElement.vue'
import { isEmptyOrFalsy } from 'utils/objects'
import { WrapperElement } from './WrapperElement'
import _ from 'lodash'

class Item extends WrapperElement {
	value = {}
	isOpen = false

	constructor (schema) {
		super()
		this.schema = schema
	}

	get label () {
		const activeLanguage = window.app.translator.active.alias
		let label = window.app.translator.getTranslation(this.parent.label)[activeLanguage] || this.parent.label
		Object.entries(this.value).map(([key, value]) => {
			label = label.replace(`<<${key}>>`, value)
		})
		return label
	}

	async create (parent) {
		this.parent = parent
		await asyncForEach(this.schema, async el => this.addElement(await window.app.createElement(el)))
		this.initReactive()
		return this
	}

	async setInitialValue (value) {
		this.value = value
	}

	async getValue () {
		if (this.isOpen) await this.saveValue()
		return this.value
	}

	toggle () {
		if (this.isOpen) this.close()
		else this.open()
	}

	open () {
		this.isOpen = true
		this.createElements()
	}

	async close () {
		this.isOpen = false
		await this.saveValue()
		this.clear()
	}

	async saveValue () {
		this.value = await super.getValue()
	}

	clear () {
		this.elements = []
	}

	async createElements () {
		await asyncForEach(this.elementGenerators, async generator => {
			const element = await generator()
			await element.create(this)
			element.setInitialValue(this.value)
			this.elements.push(element)
		})
	}

	moveUp () {
		this.parent.moveItemUp(this)
	}

	moveDown () {
		this.parent.moveItemDown(this)
	}

	remove () {
		this.parent.removeItem(this)
	}
}

class RepeaterElement extends Element {
	component = RepeaterElementComponent
	items = []
	attribute
	schema

	constructor ({ grid, attribute, schema, label, title }) {
		super(grid)
		this.attribute = attribute
		this.schema = schema
		this.label = label
		this.title = this.getTitle(title)
	}

	getTitle (title) {
		if (typeof title === 'object') return title
		const translation = window.app.translator.getTranslation(title)
		if (translation) return translation
		return title
	}

	async getValue () {
		const value = await asyncMap(this.items, item => item.getValue())
		if (!this.attribute) return value
		return _.set({}, this.attribute, value)
	}

	async validate () {
		// TODO: Uzupełnić walidowanie
	}

	async create (parent) {
		this.parent = parent
	}

	async setInitialValue (values) {
		this.clear()
		if (isEmptyOrFalsy(values)) return
		const value = this.attribute ? _.get(values, this.attribute) : values
		if (!value) return false
		await asyncForEach(value, (value) => this.addItem(value))
	}

	clear () {
		this.items = []
	}

	async addItem (value) {
		const item = new Item(this.schema)
		await item.create(this)
		if (value) item.setInitialValue(value)
		this.items.push(item)
	}

	removeItem (item) {
		this.items = this.items.filter(object => item !== object)
	}

	moveItem (from, to) {
		return this.items.splice(to, 0, this.items.splice(from, 1)[0])
	}

	moveItemUp (item) {
		const itemIndex = this.items.indexOf(item)
		this.moveItem(itemIndex, itemIndex - 1)
	}

	moveItemDown (item) {
		const itemIndex = this.items.indexOf(item)
		this.moveItem(itemIndex, itemIndex + 1)
	}
}

const repeaterElementGenerator = app => async (object) => {
	return async () => {
		const repeaterElement = new RepeaterElement(object)
		return repeaterElement
	}
}
export { RepeaterElement, repeaterElementGenerator }
