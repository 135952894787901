<template>
	<header-layout :title="l.entities[alias].pages.edit.title" v-if="page.ready">
		<template #header>
			<slot name="header"></slot>
		</template>
		<base-element :element="page.form" />
	</header-layout>
</template>

<script>
import HeaderLayout from '@a/components/molecules/layouts/HeaderLayout.vue'
import { pageMixin } from '@a/pages/page.mixin'
import BaseElement from '@a/components/atoms/BaseElement/BaseElement.vue'

export default {
	components: {
		HeaderLayout,
		BaseElement
	},
	mixins: [
		pageMixin
	]
}
</script>
