import { EntityListPage } from '@a/core/pages/entity/list/EntityListPage'
import { checkPermissions } from '~/authorization/admin/utils/checkPermissions'
import { BaseColumn } from '@a/core/elements/columns/BaseColumn'
import { PermissionsColumn } from '@a/core/elements/columns/PermissionsColumn'
import { ActionColumn } from '@a/core/elements/columns/ActionColumn'

class UserGroupListPage extends EntityListPage {
	static entity = 'user-group'
	static visible = checkPermissions('permission_management')

	getColumns () {
		return [
			{
				type: BaseColumn,
				name: 'id',
				sortParam: 'id',
				getter: userGroup => userGroup.id
			},
			{
				type: BaseColumn,
				name: 'alias',
				sortParam: 'alias',
				getter: userGroup => userGroup.alias
			},
			{
				type: PermissionsColumn,
				name: 'permissions',
				getter: userGroup => userGroup.permissions
			},
			{
				type: ActionColumn,
				actions: ['edit', 'remove']
			}
		]
	}
}

export { UserGroupListPage }
