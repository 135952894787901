import { FileInput } from '~/fileManager/admin/core/fileManager/FileInput'
import ImageInputComponent from '../../components/ImageInput'
import { ImageManagerModal } from '~/fileManager/admin/core/fileManager/ImageManagerModal'
import { SeoManagerModal } from '~/fileManager/admin/core/fileManager/SeoManagerModal'

class ImageInput extends FileInput {
	static async create (object) {
		const input = new ImageInput(object)
		await input.init()
		return input
	}

	component = ImageInputComponent

	async init () {
		this.modal = await ImageManagerModal.create()
		this.seoModal = new SeoManagerModal()
	}

	async open () {
		this.modal.open(this.value)
		this.value = await this.modal.getResult()
	}

	async openSeo () {
		this.seoModal.open(this.value)
		this.value = await this.seoModal.getResult()
	}
}

const imageInputGenerator = app => async (object) => {
	return async () => {
		const input = ImageInput.create(object)

		return input
	}
}

export { ImageInput, imageInputGenerator }
