<template>
	<td :class="header">
		<span v-if="!isStatusCell">{{ value }}</span>
		<list-item-status-column v-else :value="value" @unmark="$emit('unmark')"/>
	</td>
</template>

<script>
import ListItemStatusColumn from '~/fileManager/admin/components/ListItemStatusColumn/ListItemStatusColumn'

export default {
	components: {
		ListItemStatusColumn
	},
	props: {
		header: String,
		value: String
	},
	computed: {
		isUserWithPermissionToUncheckStatus () {
			return this.$app.authorization.moderator.user.permissions.includes('file_manager')
		},
		isStatusCell () {
			return this.isUserWithPermissionToUncheckStatus && this.header === 'markedForDeletion' && this.value !== '-'
		}
	},
	methods: {
		getCellValue (element, header) {
			return {
				size: this.formatSize(element.size),
				birthDate: this.formatBirthDate(element[header]),
				markedForDeletion: this.formatMarkedForDeletion(element[header])
			}[header] || element[header]
		}
	}
}
</script>

<style scoped lang="scss">
td {
	padding: 10px 20px;
	&.creatorIp {
		text-align: center;
		width: 160px;
	}
	&.markedForDeletion {
		text-align: center;
		width: 100px;
	}
}
</style>
