import { FileManagerModal } from '~/fileManager/admin/core/fileManager/FileManagerModal'
import { Explorer } from '~/fileManager/admin/core/fileManager/Explorer'

class ImageManagerModal extends FileManagerModal {
	static async create () {
		const modal = new ImageManagerModal()
		await modal.init()
		return modal
	}

	value = null
	initialValue = null

	async init () {
		this.explorer = await Explorer.create(this.application, 1, '')
	}

	async open (object) {
		super.open(object)
	}

	close () {
		this.sendResult(this.initialValue)
		super.close()
	}

	chooseFile () {
		const choosedFile = this.explorer.choosedItems[0]
		this.sendResult({
			...this.value,
			path: choosedFile.item.path
		})
		super.close()
	}

	async getResult () {
		return new Promise((resolve, reject) => {
			this.sendResult = (value) => {
				resolve(value)
			}
		})
	}
}

export { ImageManagerModal }
