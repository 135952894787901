class Page {
	static open () {
		window.app.vue.$router.push({ name: this.alias })
	}

	static alias
	static path
	static component
	static visible = () => true

	layout = 'default'
	app

	get route () {
		return this.app.vue.$route
	}

	get params () {
		return this.app.vue.$route.params
	}

	get query () {
		return this.app.vue.$route.query
	}

	constructor ({ app }) {
		this.app = app
	}

	init () {
		this.layout = this.app.getLayout(this.layout)
	}

	afterRedirect () {}

	status () {}

	back () {
		this.app.vue.$router.go(-1)
	}
}

export { Page }
