<template>
	<td class="image-preview-wrapper">
		<div class="img-wrapper" @click="openModal">
			<cms-image
				v-if="element.path"
				:value="`${element.path}`"
				layout
				:lazy="true"
				:base="{width: 120, height: 50, fit: 'contain', background: 'transparent'}"
			/>
		</div>
	</td>
</template>
<script>
import CmsImage from '~/cms/admin/components/CmsImage'
import { ImageModal } from '~/fileManager/admin/components/ImageModal/ImageModal'

export default {
	components: {
		CmsImage
	},
	props: {
		element: Object
	},
	methods: {
		openModal () {
			this.$app.modal = ImageModal.createAndOpen(this.element)
		}
	}
}
</script>
<style lang="scss" scoped>
.image-preview-wrapper {
	padding: 5px 10px;
}
.img-wrapper {
	width: 120px;
	height: 50px;
	overflow: hidden;
}
</style>
