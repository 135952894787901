import BaseWrapper from '@a/components/modules/inputs/molecules/BaseWrapper'

const mixin = {
	computed: {
		error () {
			if (!this.input.isInvalidAndTouched) return false
			return this.input.error
		},
		activeLanguage () {
			return this.$app.translator.active.alias
		},
		name () {
			return this.input.label[this.activeLanguage]
		},
		input () {
			return this.element
		}
	},
	components: {
		BaseWrapper
	},
	props: {
		element: Object
	}
}

export default mixin
