<template>
	<base-col class="float" v-bind="element.gridColumns">
		<base-element :element="element.selectInput"/>
		<base-element v-for="element in element.elements" :key="element.attribute" :element="element"/>
	</base-col>
</template>

<script>
import BaseCol from '@a/components/atoms/BaseCol'
import BaseElement from '@a/components/atoms/BaseElement'

export default {
	components: {
		BaseElement,
		BaseCol
	},
	props: {
		element: Object
	}
}
</script>

<style lang="scss">
.float {
	float: left;
	&::after {
		content: "";
		clear: both;
		display: table;
	}
}
</style>
