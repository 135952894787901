<template>
	<div v-if="element.visible">
		<div
			class="menu-dropdown"
			:class="{active: element.status !== 'close'}"
			@click="element.toggle()"
		>
			<base-svg
				class="menu-icon"
				:name="element.icon"
			/>
			<base-font variant="paragraph" color="white">
				{{name}}
			</base-font>
			<base-svg class="menu-dropdown-arrow" name="keyboard_arrow_down"/>
		</div>
		<div
			v-if="element.status === 'open'"
			class="menu-dropdown-items"
		>
			<component
				v-for="item in element.elements"
				:key="item.name"
				:is="item.component"
				:element="item"
				:child="true"
			/>
		</div>
	</div>
</template>
<script>
import BaseFont from '@a/components/atoms/BaseFont'
import BaseSvg from '@a/components/atoms/BaseSvg'

export default {
	components: {
		BaseSvg,
		BaseFont
	},
	computed: {
		activeLanguage () {
			return this.$app.translator.active.alias
		},
		name () {
			const langPath = `menu.${this.element.name}`
			return this.$app.translator.getTranslation(`${langPath}`)[this.activeLanguage]
		}
	},
	props: {
		element: Object
	}
}
</script>
<style lang="scss" scoped>
.menu-dropdown {
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 1rem 6rem 1rem 1.5rem;
	height: 60px;
	& ::v-deep p {
		font-size: 1.3rem;
		line-height: 1.8rem;
	}
	@include transition;
	&:hover {
		background: rgba(0, 0, 0, 0.15);
	}
	&-items {
		background-color: rgba($secondary, .6);
		& svg {
			width: 15px;
		}
		& .menu-element {
			height: 50px;
			padding-left: 4rem;
		}
	}
}
.menu-dropdown.active  .menu-dropdown-arrow {
	transform: translateY(-50%) rotate(180deg);
}
.menu-dropdown-arrow {
	position: absolute;
	height: 25px;
	top: 50%;
	right: 20px;
	fill: $white;
	transform: translateY(-50%);
	transition: .3s ease;
}
.menu-icon {
	fill: $white;
	margin-right: 1rem;
	path, circle {
		fill: $white;
	}
}
</style>
