import { Emitter } from '@a/core/Emitter'
import VMenuElement from '@a/components/molecules/menus/MenuElement'
class MenuElement extends Emitter {
	name
	icon
	to
	component = VMenuElement

	get visible () {
		if (this.checkVisibleFn) return this.checkVisibleFn()
		return true
	}

	constructor ({ name, icon, to, redirect, visible }) {
		super()
		this.name = name
		this.icon = icon
		this.to = to
		this.redirect = redirect
		this.checkVisibleFn = visible
	}

	clickHandler () {
		if (this.to) window.app.vue.$router.push(this.to)
		else if (this.redirect) window.location.href = this.redirect
		this._emit('click')
	}
}

export {
	MenuElement
}
