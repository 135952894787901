<script>
import merge from 'lodash.merge'

const colType = {
	type: [String, Number],
	validator: (value) => value <= 12 || value < 1
}
export default {
	name: 'BaseCol',
	functional: true,
	globalComponent: true,
	props: {
		col: colType,
		sm: colType,
		md: colType,
		lg: colType,
		xl: colType,
		xxl: colType,
		offsetSm: colType,
		offsetMd: colType,
		offsetLg: colType,
		offsetXl: colType,
		offsetXxl: colType
	},
	render (h, { props, data, children }) {
		const sizes = Object.keys(props).filter(key => key)
		const colsClasses = {}
		sizes.forEach((size, i) => {
			let objName
			if (size === 'col') objName = `${size}-${props[size]}`
			else if (size.includes('offset')) {
				const offsetSize = size.split('offset')[1].toLowerCase()
				objName = `offset-${offsetSize}-${props[size]}`
			} else objName = `col-${size}-${props[size]}`
			colsClasses[objName] = !!props[size]
		})
		data.class = merge({}, data.class, colsClasses)
		return h('div', data, children)
	}
}

</script>

<style lang="scss" scoped>
@mixin make-col-auto() {
	flex: 0 0 auto;
	width: auto;
}

@mixin make-col($size, $columns: $grid-columns) {
	flex: 0 0 auto;
	width: percentage(math.div($size, $columns));
}

@mixin make-col-offset($size, $columns: $grid-columns) {
	$num: math.div($size, $columns);
	margin-left: if($num==0, 0, percentage($num));
}

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
	%grid-column {
		position: relative;
		width: 100%;
		padding-right: math.div($gutter, 2);
		padding-left: math.div($gutter, 2);
	}

	@each $breakpoint in map-keys($breakpoints) {
		$infix: breakpoint-infix($breakpoint, $breakpoints);

		@if $columns>0 {
			@for $i from 1 through $columns {
				.col#{$infix}-#{$i} {
					@extend %grid-column;
				}
			}
		}

		@include media-breakpoint-up($breakpoint, $breakpoints) {
			.col#{$infix} {
				flex: 1 0 0%;
			}

			.col#{$infix}-auto {
				@include make-col-auto();
			}

			@if $columns>0 {
				@for $i from 1 through $columns {
					.col#{$infix}-#{$i} {
						@include make-col($i, $columns);
					}
				}

				@for $i from 0 through ($columns - 1) {
					@if not ($infix==""and $i==0) {
						.offset#{$infix}-#{$i} {
							@include make-col-offset($i, $columns);
						}
					}
				}
			}
		}
	}
}
.col-12.float {
	padding: 0;
	@include media-breakpoint-up(sm) {
		padding: 0 1.5rem
	}
}
@include make-grid-columns();

</style>
