export function checkPermissions (...permissions) {
	return function () {
		if (!window.app.authorization.moderator) return false
		const moderatorPerms = window.app.authorization.moderator.user.permissions
		return permissions.every(permission => {
			if (!~moderatorPerms.indexOf(permission)) return false
			return true
		})
	}
}

export function checkFileManagerPermissions (...permissions) {
	return function () {
		if (!window.app.authorization.moderator) return false
		const moderatorPerms = window.app.authorization.moderator.user.permissions
		return permissions.some(permission => {
			if (!~moderatorPerms.indexOf(permission)) return false
			return true
		})
	}
}
