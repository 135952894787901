class RecaptchaService {
	static alias = 'recaptcha'

	constructor (app) {
		this.app = app
	}

	registerRecaptcha () {
		if (window.grecaptcha) return true
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async resolve => {
			const script = document.createElement('script')
			document.body.appendChild(script)
			script.onload = resolve
			const settings = await this.app.getSettings()
			this.apiKey = settings.env.recaptchaApiKey
			script.src = `https://www.google.com/recaptcha/api.js?render=${this.apiKey}`
		})
	}

	ready () {
		return new Promise(resolve => {
			window.grecaptcha.ready(resolve)
		})
	}

	async getToken () {
		await this.registerRecaptcha()
		await this.ready()
		const token = await window.grecaptcha.execute(this.apiKey, { action: 'submit' })
		return token
	}
}

export { RecaptchaService }
