<template>
	<div class="clearfix">
		<base-element v-for="element in form.elements" :key="element.attribute" :element="element"/>
	</div>
</template>

<script>
import BaseElement from '@a/components/atoms/BaseElement'

export default {
	computed: {
		form () { return this.element }
	},
	components: {
		BaseElement
	},
	props: {
		element: Object
	}
}
</script>
<style lang="scss" scoped>
.clearfix {
	width: 100%
}
::v-deep .button-submit {
	padding-top: 1rem;
	justify-content: flex-end;
	@include media-breakpoint-up(md) {
		justify-content: flex-start;
	}
}
</style>
