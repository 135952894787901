<template>
	<base-wrapper
		:valid="input.isValidAndTouched"
        :error="error"
        :label="name"
        :name="input.name"
		:info="input.info"
		class="input-text"
	>
		<textarea
			:class="{
				'no-height': input.rows
			}"
			v-model="input.value"
			:rows="input.rows"
		/>
		<span v-if="input.maxLength" class="counter">{{input.value.length}} / {{input.maxLength}}</span>
	</base-wrapper>
</template>

<script>
import mixin from './input.mixin'

export default {
	mixins: [mixin]
}
</script>
<style lang="scss" scoped>
	textarea {
		font-size: $font-size-base;
		border:1px solid $grey-400;
		border-radius: $border-radius;
		padding: 10px;
		min-height: 45px;
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		padding-right: 30px;
		overflow-y: scroll;
        height: 100px;

		&.no-height {
			min-height: auto;
			height: auto;
		}

		/* Firefox */
		&[type=number] {
			-moz-appearance:textfield;
		}

		&.invalid {
			border:1px solid $danger;
		}
		&.disabled {
			background-color: $light-gray;
			color: $grey-800;
		}

		/* Chrome, Safari, Edge, Opera */
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		&::-webkit-scrollbar {
			width: 5px;
			margin: 10px 0;
		}
		&::-webkit-scrollbar-track {
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}
		&::-webkit-scrollbar-thumb {
			background: $grey-500;
			border-radius: 3px;
		}
		&::-webkit-scrollbar-thumb:hover {
			background: $grey-800;
			width: 10px;
		}
	}
	.counter {
		margin-top: 5px;
		text-align: right;
	}
</style>
