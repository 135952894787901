import CreateFormPage from '@a/pages/forms/CreateFormPage.vue'
import { Page } from '../../Page'
import { Notification, NotificationType } from '@a/core/entities/Notification'

class EntityCreateFormPage extends Page {
	static component = CreateFormPage

	static get path () {
		return `/${this.entity}/create`
	}

	static get alias () {
		return `${this.entity}-create`
	}

	ready = false
	entity
	object

	get Entity () {
		return this.app.getEntity(this.constructor.entity)
	}

	async init () {
		super.init()
		this.object = new this.Entity()
		this.form = await this.getForm()
		await this.form.create()
		this.form.onSubmit(this.save.bind(this))

		const params = this.app.vue.$router.currentRoute.params
		await this.form.setInitialValues(params)

		this.ready = true
	}

	async save () {
		const values = await this.form.getValue()
		const { success, data } = await this.object.create(values)

		this.notify(success, data)

		if (success) this.afterSave()
	}

	notify (success, data) {
		if (success) Notification.create(NotificationType.SUCCESS, `entities.${this.constructor.entity}.pages.create.notification.success`)
		else Notification.create(NotificationType.ERROR, `entities.${this.constructor.entity}.pages.create.notification.error`)
	}

	async getForm () {
		const formSchema = await this.getFormSchema()
		const formGenerator = await window.app.createElement(formSchema)
		const form = await formGenerator()
		return form
	}

	afterSave () {
		window.app.vue.$router.go(-1)
	}

	setData (data) {
		this.object.setData(data)
		this.object.setFieldData()
	}
}

export { EntityCreateFormPage }
