import { ReadEntity } from '@a/core/entities/ReadEntity'

class Permission extends ReadEntity {
	static alias = 'permission'

	getName () {
		const langs = window.app.translator.active.translations.entities
		const entitiLangs = langs[this.constructor.alias]

		return entitiLangs[this.id]
	}
}

export { Permission }
