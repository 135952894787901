import { Input } from './Input'
import InputTextTemplate from '@a/components/modules/inputs/organisms/InputText.vue'
import moment from 'moment'

class InputDate extends Input {
	component = InputTextTemplate
	_mask = false

	constructor ({ type }) {
		super(...arguments)
		this.type = type || 'datetime-local'
	}

	get value () {
		return this._value
	}

	set value (value) {
		const dateValue = moment(value).format(this.format)
		if (dateValue !== 'Invalid date') this._value = dateValue
		else this._value = ''
		this._emit('value:changed', dateValue)
	}

	get format () {
		return {
			date: 'YYYY-MM-DD',
			'datetime-local': 'YYYY-MM-DDTHH:mm:ss'
		}[this.type]
	}
}

const inputDateGenerator = app => async (object) => {
	return async () => {
		const inputDate = new InputDate({
			...object
		})
		return inputDate
	}
}

export { InputDate, inputDateGenerator }
