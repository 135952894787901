import { asyncForEach, asyncMap } from 'utils/async'
import MultiSchemaElementComponent from '@a/components/modules/forms/MultiSchemaElement/MultiSchemaElement.vue'
import { WrapperElement } from './WrapperElement'
import { inputSelectGenerator } from './inputs/InputSelect'
import { getValue } from 'utils/objects'
class MultiSchemaElement extends WrapperElement {
	component = MultiSchemaElementComponent
	elementGenerators = []
	elements = []
	attribute

	constructor ({ attribute, types, label, typeAttribute = 'type' }) {
		super()
		this.attribute = attribute
		this.label = label
		this.types = types
		this.typeAttribute = typeAttribute
	}

	addElement (element) {
		this.elementGenerators.push(element)

		return this
	}

	async create (parent) {
		this.parent = parent
		const selectInputGenerator = await inputSelectGenerator(window.app)({
			name: 'type',
			label: this.label,
			options: this.types.map(type => ({
				id: type.id,
				name: type.name
			}))
		})
		this.selectInput = await selectInputGenerator()
		this.selectInput.on('value:changed', this.createElements.bind(this))
		await this.createElements()
		this.initReactive()
		return this
	}

	async createElements () {
		this.elements = []
		const generators = await this.getElementGenerators()
		await asyncForEach(generators, async generator => {
			const element = await generator()
			await element.create(this)
			this.elements.push(element)
		})
	}

	async getActualTypeId () {
		return await this.selectInput.getValue()
	}

	async getActualType () {
		const id = await this.getActualTypeId()
		return this.types.find(type => type.id === id)
	}

	async getElementGenerators () {
		const type = await this.getActualType()
		return asyncMap(type.elements, async element => await window.app.createElement(element))
	}

	async getValue () {
		const value = await super.getValue()
		value[this.typeAttribute] = await this.getActualTypeId()
		return value
	}

	async setInitialValue (values) {
		const type = getValue(this.typeAttribute, values)
		this.selectInput.value = type

		await this.createElements()
		await super.setInitialValue(values)
	}
}

const multiSchemaElementGenerator = app => async (object) => {
	return async () => {
		const multiSchemaElement = new MultiSchemaElement(object)
		return multiSchemaElement
	}
}
export { MultiSchemaElement, multiSchemaElementGenerator }
