<template>
	<nav class="topbar">
		<div class="logo-wrapper">
			<base-svg class="logo" name="logo" />
		</div>
		<div class="topbar-inner-wrapper">
			<div class="topbar-items-wrapper">
				<component
					v-for="element in elementsLeft"
					:is="element.component"
					:key="element.name"
					:element="element"
				/>
			</div>
			<div class="topbar-items-wrapper">
				<component
					v-for="element in elementsRight"
					:is="element.component"
					:key="element.name"
					:element="element"
				/>
			</div>
		</div>
	</nav>
</template>

<script>
import BaseSvg from '@a/components/atoms/BaseSvg/BaseSvg'

export default {
	components: {
		BaseSvg
	},
	computed: {
		elementsLeft () {
			return this.topbar.elements.filter(element => element.align === 'left')
		},
		elementsRight () {
			return this.topbar.elements.filter(element => element.align === 'right')
		}
	},
	props: {
		topbar: Object
	}
}
</script>
<style lang="scss" scoped>
.logo-wrapper {
	width: 130px;
	.logo {
		fill: #FFF;
		display: block;
		height: 40px;
		margin-left: 40px;
	}
	@include media-breakpoint-up (md) {
		width: 240px;
		.logo {
			height: 50px;
			margin: 0 auto 0 0;
		}
	}
}
.topbar {
	color: white;
	position: fixed;
	top: 0;
	left: 0;
	height: 50px;
	width: 100%;
	background: $primary;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 12;
	&-inner-wrapper {
		display: flex;
		justify-content: space-between;
		padding: 0 1.5rem;
		@include media-breakpoint-up (md) {
			width: calc(100% - 240px);
		}
	}
	&-items-wrapper {
		display: flex;
	}
}
</style>
