import _ from 'lodash'

const NotificationType = {
	ERROR: 0,
	SUCCESS: 1,
	INFO: 2
}
class Notification {
	static create (type, lang, langCallback) {
		const message = window.app.translator.getTranslation(lang)
		const notification = Notification.notificationCreate({
			type,
			message: langCallback ? langCallback(message) : message
		})
		notification.show()
	}

	static notificationCreate () {
		const instance = new this(...arguments)
		return instance
	}

	id
	message
	type

	constructor ({ message, type }) {
		this.id = _.uniqueId()
		this.message = message
		this.type = type
		this.application = window.app
	}

	show () {
		this.application.showNotification(this)
		setTimeout(this.close.bind(this), 5000)
	}

	close () {
		this.application.closeNotification(this)
	}
}

export { Notification, NotificationType }
