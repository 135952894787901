import { CRUDEntity } from '@a/core/entities/CRUDEntity'

class Setting extends CRUDEntity {
	static alias = 'setting';

	static async findByAlias (alias) {
		const { data } = await window.app.getService('rext')
			.http
			.get(`/${this.alias}/${alias}`)

		return new this(data)
	}

	getName () {
		return this.alias
	}
}

export { Setting }
