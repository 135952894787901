import { getValue, setValue } from 'utils/objects'
import { Element } from '../Element'

class Input extends Element {
	_value
	_touched = false
	error = null
	_placeholder = ''

	constructor ({ attribute, value, name, label, validation = [], info = false, placeholder = '', hidden, disable }) {
		super()
		this.attribute = attribute
		this.name = name
		this.label = this.getLabel(label)
		this.validation = validation
		if (value) this.value = value
		this._placeholder = placeholder
		this._info = info
		this.on('value:changed', this.valueChangedHandler.bind(this))
		if (hidden) this.hiddenChecker = hidden
		if (disable) this.disableChecker = disable
	}

	get placeholder () {
		const { translator } = window.app
		if (typeof this._placeholder === 'function') return this._placeholder()
		return this._placeholder ? translator.getTranslation(this._placeholder)[translator.active.alias] : ''
	}

	get info () {
		if (typeof this._info === 'function') return this._info(this)
		return this._info ? window.app.translator.getTranslation(this._info) : ''
	}

	get isValid () {
		return this.error === null
	}

	get isInvalid () {
		return !!this.error
	}

	get isTouched () {
		return this._touched
	}

	get isInvalidAndTouched () {
		return this.isTouched && this.isInvalid
	}

	get isValidAndTouched () {
		return this.isTouched && this.isValid
	}

	get value () {
		return this._value
	}

	set value (value) {
		this._value = value
		this._emit('value:changed', value)
	}

	get formValue () {
		return this.value || ''
	}

	get hidden () {
		if (!this.hiddenChecker) return false
		return this.hiddenChecker()
	}

	get disable () {
		if (!this.disableChecker) return false
		return this.disableChecker()
	}

	valueChangedHandler () {
		this.validate()
	}

	markAsTouched () {
		this._touched = true
	}

	markAsUnTouched () {
		this._touched = false
	}

	async touchAndValidate () {
		this.markAsTouched()
		await this.validate()
		return this.isValid
	}

	getLabel (label) {
		if (!label) return false
		if (typeof label === 'object') return label
		const translation = window.app.translator?.getTranslation(label)
		if (translation) return translation
		return label
	}

	async validate () {
		if (this.disable) {
			this.error = null
			return true
		}
		for (const rule of this.validation) {
			const isValid = await rule.validate(this.value)

			if (!isValid) {
				this.error = rule.message()
				return false
			}
		}
		this.error = null
		return true
	}

	clear () {
		this.value = ''
		this.markAsUnTouched()
	}

	getValue () {
		if (!this.attribute) return this.value
		const value = {}
		setValue(this.attribute, value, this.value)
		return value
	}

	setInitialValue (values) {
		let value = values
		if (this.attribute) value = getValue(this.attribute, values)

		if (value !== undefined) this.value = value
		else this.clear()
	}
}

export { Input }
