import { DropdownTopbar } from '@a/core/layouts/default/dropdownTopbar/DropdownTopbar'
import BaseTopbar from '~/authorization/admin/components/UserDropdown/BaseTopbar'
import BaseDropdown from '~/authorization/admin/components/UserDropdown/BaseDropdown'

class UserDropdownTopbar extends DropdownTopbar {
	topbarComponent = BaseTopbar
	dropdownComponent = BaseDropdown
	align = 'right'

	signOut () {
		window.app.authorization.signOut()
	}

	changePassword () {
		window.app.vue.$router.replace({ name: 'user-change-password' })
	}
}

export {
	UserDropdownTopbar
}
