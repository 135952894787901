const required = msg => ({
	validate: value => !!value,
	message: () => msg || 'To pole jest wymagane'
})
const min = (nbr, msg) => ({
	validate: value => value.length >= nbr,
	message: () => msg
})
const max = (nbr, msg) => ({
	validate: value => value.length <= nbr,
	message: () => msg
})
const minHtml = (nbr, msg) => ({
	validate: value => {
		const tempDiv = document.createElement('div')
		tempDiv.innerHTML = value
		return tempDiv.innerText.length >= nbr
	},
	message: () => msg
})
const maxHtml = (nbr, msg) => ({
	validate: value => {
		const tempDiv = document.createElement('div')
		tempDiv.innerHTML = value
		return tempDiv.innerText.length <= nbr
	},
	message: () => msg
})
const minInteger = (nbr, msg) => ({
	validate: value => Number(value) >= nbr,
	message: () => msg + nbr
})
const maxInteger = (nbr, msg) => ({
	validate: value => Number(value) <= nbr,
	message: () => msg + nbr
})
const minNumber = (nbr, msg) => ({
	validate: value => parseFloat(value.replace(',', '.')) >= parseFloat(nbr),
	message: () => msg
})
const maxNumber = (nbr, msg) => ({
	validate: value => parseFloat(value.replace(',', '.')) <= parseFloat(nbr),
	message: () => msg
})
const pesel = msg => ({
	validate: pesel => {
		const arr = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3]
		let sum = 0

		for (let i = 0; i < 10; i++) sum += arr[i] * pesel[i]

		sum = sum % 10 === 0 ? 0 : 10 - sum % 10
		return sum === +pesel[10]
	},
	message: () => msg
})
const nip = msg => ({
	validate: nip => {
		// eslint-disable-next-line no-useless-escape
		nip = nip.replace(/[\ \-]/gi, '')
		const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7]
		let sum = 0
		const controlNumber = parseInt(nip.substring(9, 10))
		const weightCount = weight.length
		for (let i = 0; i < weightCount; i++) {
			sum += (parseInt(nip.substr(i, 1)) * weight[i])
		}
		return sum % 11 === controlNumber
	},
	message: () => msg || 'To nie jest prawidłowy numer NIP.'
})
const email = msg => ({
	validate: value => {
		const email = String(value).toLowerCase()
		// eslint-disable-next-line no-useless-escape
		var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
		return regexp.test(email)
	},
	message: () => msg || 'To nie jest prawidłowy e-mail.'
})
const minAge = msg => ({
	validate: value => {
		const birthday = new Date(value).getTime()
		const now = new Date().getTime()
		const i18yo = 18 * 365.25 * 24 * 60 * 60 * 1000
		return birthday < now - i18yo
	},
	message: () => msg
})
const dateMinNow = msg => ({
	validate: value => {
		const date = new Date(value).getTime()
		const now = new Date().getTime()
		return date > now
	},
	message: () => msg
})
const nrb = msg => ({
	validate: nrb => {
		var weight = [1, 10, 3, 30, 9, 90, 27, 76, 81, 34, 49, 5, 50, 15, 53, 45, 62, 38, 89, 17, 73, 51, 25, 56, 75, 71, 31, 19, 93, 57]

		nrb = nrb + '2521' // PL
		nrb = nrb.substr(2) + nrb.substr(0, 2)
		var Z = 0
		for (var i = 0; i < 30; i++) {
			Z += nrb[29 - i] * weight[i]
		}
		if (Z % 97 === 1) return true
		else return false
	},
	message: () => msg
})

const phoneNumber = msg => ({
	validate: value => !!value && value.length >= 11,
	message: () => msg
})

const isValidNumber = msg => ({
	validate: value => {
		return !isNaN(value)
	},
	message: () => msg || 'To nie jest prawidłowa liczba.'
})

export {
	required,
	min,
	max,
	minHtml,
	maxHtml,
	pesel,
	nip,
	email,
	minAge,
	dateMinNow,
	nrb,
	minNumber,
	maxNumber,
	phoneNumber,
	minInteger,
	maxInteger,
	isValidNumber
}
