<template>
	<base-wrapper
		:valid="input.isValidAndTouched"
        :error="error"
        :label="name"
        :name="input.name"
		:info="input.info"
		class="input-text"
	>
		<base-input
			:input="input"
			:type="input.type"
		/>
		<div class="price">
			zł
		</div>
	</base-wrapper>
</template>

<script>
import mixin from './input.mixin'
import BaseInput from '@a/components/modules/inputs/atoms/BaseInputWithoutMask'

export default {
	mixins: [mixin],
	components: {
		BaseInput
	}
}
</script>

<style lang="scss" scoped>
.price {
	position: absolute;
	right: 0;
	top: 13px;
	width: 30px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 1.6rem;
}
</style>
