export function rextServiceExtend (Service) {
	return class RextService extends Service {
		getAxiosConfig () {
			const config = super.getAxiosConfig()

			if (!config.headers) config.headers = {}

			if (this.app.authorization && this.app.authorization.isAuthorizated) {
				config.headers.authorization = `Bearer ${this.app.authorization.token.value}`
			}

			return config
		}

		async createToken (data) {
			const http = await this.getHttpWithRecaptcha()
			const response = await http.post('/token', data)

			if (response.status !== 201) return { error: response.data.alias }

			return response.data
		}

		async deleteToken (id) {
			const response = await this.http.delete(`/token/${id}`)

			if (response.status !== 204) return { error: true }

			return { error: false }
		}

		async getMe () {
			const response = await this.http.get('/moderator/me')

			if (response.status !== 200) return { error: true }

			return response.data
		}

		async remindPassword (email) {
			const response = await this.http.post('/moderator/remind-password-token', { email })
			if (response.status !== 201) return { error: true }
			return { error: false }
		}

		async checkToken (token) {
			const response = await this.http.get(`/remind-password-token/${token}`)
			if (response.status !== 200) return { error: true }
			return { error: false }
		}

		async changePassword (data) {
			const response = await this.http.post(`/remind-password-token/${data.token}/_change-password`, { password: data.password })
			if (response.status !== 200) return { error: true }
			return { error: false }
		}

		async userChangePassword (data) {
			const response = await this.http.post('/me/_change-password', data)
			if (response.status !== 200) return { error: true, alias: response.data.alias }
			return { error: false }
		}
	}
}
