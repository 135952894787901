<template>
	<base-wrapper
		:label="element.label"
	>
		<tab-nav :tabs="element.tabs"/>
		<tab-content :activeTab="element.activeTab"/>
	</base-wrapper>
</template>
<script>
import TabNav from '@a/components/modules/forms/TabNav'
import TabContent from '@a/components/modules/forms/TabContent'
import BaseWrapper from '@a/components/modules/inputs/molecules/BaseWrapper'

export default {
	components: {
		TabNav,
		TabContent,
		BaseWrapper
	},
	props: {
		element: Object
	}
}
</script>
