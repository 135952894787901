<template>
	<vue-editor
		:id="input.name"
		:rows="6"
		v-model="input.value"
		:class="{
		invalid: input.isInvalidAndTouched,
		disabled: input.disable
	}"
		:editor-toolbar="input.toolbar ? input.toolbar : customToolbar"
		@blur="input.markAsTouched()"
	></vue-editor>
</template>

<script>
import { VueEditor } from 'vue2-editor'

export default {
	components: {
		VueEditor
	},
	props: {
		input: Object
	},
	data: () => ({
		customToolbar: [
			['bold', 'italic', 'underline', 'strike'],
			['blockquote'],
			[{ list: 'ordered' }, { list: 'bullet' }],
			[{ indent: '-1' }, { indent: '+1' }],
			[{ header: [1, 2, 3, 4, 5, 6, false] }],
			['clean']
		]
	})
}
</script>

<style lang="scss" scoped>
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

.quillWrapper {
	font-size: $font-size-base;
	border:1px solid $grey-800;
	border-radius: $border-radius;
	overflow: hidden;
}
::v-deep .ql-editor {
	background: $white;
	min-height: 215px;
}
</style>
