import { Item } from '~/fileManager/admin/core/fileManager/Item'
import { File } from '~/fileManager/admin/core/fileManager/File'
import { NotificationType, Notification } from '@a/core/entities/Notification'

class Directory extends Item {
	files
	directories
	parent

	constructor ({ parent, ...params }) {
		super(params)
		this.parent = parent
	}

	get breadcrumbs () {
		if (!this.parent) return [this]
		return [...this.parent.breadcrumbs, this]
	}

	async init (deepestParentPath) {
		this.deepestParentPath = deepestParentPath

		const { files, directories, parent } = await this._fetchInfo()

		this._initParent(parent)
		this._initFiles(files)
		this._initDirectories(directories)
	}

	_initParent (parent) {
		if (!Object.keys(parent).length) return undefined
		const setParent = (parent) => {
			if (!parent) return undefined
			if (this.deepestParentPath === this.path) return undefined
			parent = new Directory({
				path: parent.path,
				parent: setParent(parent.parent),
				app: this.app
			})
			return parent
		}

		this.parent = setParent(parent)
	}

	_initFiles (files) {
		this.files = files.map(
			file =>
				new File({
					...file,
					app: this.app
				})
		)
	}

	_initDirectories (directories) {
		this.directories = directories.map(
			directory =>
				new Directory({
					...directory,
					app: this.app
				})
		)
	}

	async _fetchInfo () {
		const service = this.app.getService('rext')
		const response = await service.getDirectoryInformation(this.path)
		if (response.error) Notification.create(NotificationType.ERROR, 'errors.permission_error')

		return response
	}

	getFiles () {
		return this.files
	}

	getDirectories () {
		return this.directories
	}

	getParent () {
		return this.parent
	}
}

export { Directory }
