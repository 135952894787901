import { Page } from '@a/core/pages/Page'
import DashboardComponent from '@a/pages/Dashboard'

class DashboardPage extends Page {
	static alias = 'dashboard'
	static path = '/'
	static component = DashboardComponent
	static widgets = []
	widgets = []

	constructor ({ app }) {
		super({ app })
		this.createWidgets()
	}

	get alias () {
		return this.constructor.alias
	}

	static addWidget (Widget) {
		this.widgets.push(Widget)
	}

	createWidgets () {
		this.widgets = this.constructor.widgets.map(Widget => new Widget())
	}
}

export { DashboardPage }
