import { UploadableFile } from '~/fileManager/admin/core/fileManager/UploadableFile'
import { Notification, NotificationType } from '@a/core/entities/Notification'
import { asyncForEach, asyncMap } from 'utils/async'
import { isItemDirectory } from 'utils/isItemDirectory'
import * as Process from 'process'

class Uploader {
	static async create (app, explorer) {
		const uploader = new Uploader(app, explorer)
		await uploader.init()
		return uploader
	}

	files = []
	maxFileSize
	app
	explorer

	constructor (app, explorer) {
		this.app = app
		this.explorer = explorer
	}

	get path () {
		return this.explorer.currentDirectory.path
	}

	get uploadQueue () {
		return this.files.filter(file => file.progress === 0 && file.progress !== 100).slice(0, 5)
	}

	async init () {
		const generalSettings = await this.app.getSettings()
		const filesSettings = generalSettings.general.files
		this.maxFileSize = this.getMaxFileSizeInBytes(filesSettings)
	}

	getMaxFileSizeInBytes ({ fileManagerFileSize, unit }) {
		return {
			KB: fileManagerFileSize * 1024,
			MB: fileManagerFileSize * 1024 * 1024,
			GB: fileManagerFileSize * 1024 * 1024 * 1024
		}[unit]
	}

	async add (filesData) {
		try {
			const files = (await asyncMap(filesData, async file => {
				if (isItemDirectory(file)) this._showDirectoryErrorNotification()
				else {
					return new UploadableFile({
						name: file.name.match(/(\S.+\S)/)[0],
						blob: await file.arrayBuffer(),
						size: file.size,
						path: this.path,
						uploader: this
					})
				}
			})).filter(a => !!a)

			this.files.push(...files)
			await this.uploadQueued()

			this._filterFilesWithTooBigSize()
		} catch (e) {
			this._showSizeErrorNotification()
		}
	}

	remove (fileToRemove) {
		this.files = this.files.filter(file => file !== fileToRemove)
	}

	removeAll () {
		this.files = this.files.filter(file => file.progress !== 100)
	}

	async uploadQueued () {
		await asyncForEach(this.uploadQueue, async file => {
			if (!this.isFileSizeCorrect(file)) this._showSizeErrorNotification(file)
			else if (!file.isUploaded()) await this.uploadFile(file)
		})
		if (this.uploadQueue.length > 0) Process.nextTick(async () => await this.uploadQueued())
	}

	async uploadFile (file) {
		await file.createChunks()

		const service = this.app.getService('rext')
		const { error } = await service.uploadFile(file)
		if (error) Notification.create(NotificationType.ERROR, 'file-manager.errors.upload_success')
		else {
			Notification.create(NotificationType.SUCCESS, 'file-manager.upload_success')
			this.app.emit('explorer:refresh')
		}
	}

	isFileSizeCorrect (file) {
		if (file.size < this.maxFileSize) return true
		return false
	}

	_showSizeErrorNotification (file) {
		const message = this.app.translator.getTranslation('file-manager.file_incorrect_size')
		Object.entries(message).forEach(([key, value]) => {
			message[key] = value.replace('{}', file ? file.name : '')
		})

		const notification = Notification.notificationCreate({
			type: NotificationType.ERROR,
			message
		})
		notification.show()
	}

	_showDirectoryErrorNotification () {
		Notification.create(NotificationType.ERROR, 'file-manager.directory_not_allowed')
	}

	_filterFilesWithTooBigSize () {
		this.files = this.files.filter(file => this.isFileSizeCorrect(file))
	}
}

export { Uploader }
