<template>
	<div class="button-wrapper" :class="classes">
		<slot />
	</div>
</template>

<script>
export default {
	props: {
		left: Boolean
	},
	computed: {
		classes () {
			return {
				left: this.left,
				right: !this.left
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.button-wrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	&.right {
		justify-content: flex-end;
		>button {
			margin-left: 10px;
		}
	}
	&.left {
		justify-content: flex-start;
		>button {
			margin-right: 10px;
		}
	}
}
</style>
