<template>
    <p
        :class="{valid: option.isValid, invalid: option.isInvalidAndTouched}"
        v-html="text"
    ></p>
</template>

<script>
export default {
	props: {
		option: Object
	},
	computed: {
		text () {
			const lang = this.$app.translator.active.alias
			return `- ${this.option.text[lang]}`
		}
	}
}
</script>

<style lang="scss" scoped>
    p {
        font-size: 13px;
        line-height: 13px;
        margin: 10px 0;
        letter-spacing: 0.5px;
        &.invalid {
            color: $danger;
        }
        &.valid {
            color: $success;
        }
    }
</style>
