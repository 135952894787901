<template>
    <label
        :for="name"
        @click.stop="$emit('click')"
    >{{value}}:</label>
</template>

<script>
export default {
	props: {
		name: String,
		label: [Object, String]
	},
	computed: {
		value () {
			if (typeof this.label === 'string') return this.label
			return this.label[this.$app.translator.active.alias]
		}
	}
}
</script>

<style lang="scss" scoped>
	label {
		font-size: 1.4rem;
        display: flex;
		width: 100%;
		margin-bottom: 5px;
		font-weight: bold;
    }

</style>
