import { Modal } from '@a/core/application/Modal'
import SeoManagerModalComponent from '~/fileManager/admin/components/SeoManagerModal/SeoManagerModal'
import { InputText } from '@a/core/elements/inputs/InputText'
import { getValue } from 'utils/objects'

class SeoManagerModal extends Modal {
	component = SeoManagerModalComponent
	value = null
	initialValue = null
	altInput
	titleInput
	sendResult

	constructor () {
		super()
		this.generateInputs()
	}

	async open (value) {
		this.initialValue = value
		this.value = value
		this.setInitialValue(value)

		this.showModal()

		const values = await this.getValues()
		this.sendResult({
			...values
		})
		super.close()
	}

	showModal () {
		super.open()
	}

	close () {
		this.sendResult(this.initialValue)
		super.close()
	}

	setAltAndTitle () {
		super.close()
		return this.sendResult({
			alt: this.altInput.value,
			title: this.titleInput.value,
			path: this.value.path
		})
	}

	generateInputs () {
		this.altInput = new InputText({
			attribute: 'alt',
			name: 'altInput',
			label: 'inputs.label.alt'
		})
		this.titleInput = new InputText({
			attribute: 'title',
			name: 'titleInput',
			label: 'inputs.label.title'
		})
	}

	setInitialValue (values) {
		[this.altInput, this.titleInput].forEach(input => {
			input.value = getValue(input.attribute, values)
		})
	}

	async getResult () {
		return new Promise((resolve, reject) => {
			this.sendResult = value => {
				resolve(value)
			}
		})
	}

	async getValues () {
		return new Promise(resolve => {
			this.sendResult = value => resolve(value)
		})
	}
}

export { SeoManagerModal }
