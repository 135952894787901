import BaseButton from '@a/components/atoms/BaseButton'
class Modal {
	static create () {
		const instance = new this(...arguments)
		return instance
	}

	static createAndOpen () {
		const modal = Modal.create()
		modal.open()
		return modal
	}

	component = BaseButton

	constructor () {
		this.application = window.app
	}

	open () {
		this.application.openModal(this)
	}

	close () {
		this.application.closeModal()
	}
}

export { Modal }
