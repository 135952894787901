import cookies from 'js-cookie'

class Authorization {
	token
	user

	constructor (app) {
		this.app = app
	}

	get isAuthorizated () {
		if (!this.token) return false
		return true
	}

	async init () {
		await this.restoreToken()
	}

	async signIn (data) {
		const service = this.app.getService('rext')
		const response = await service.createToken(data)

		if (response.error) return response

		this.setToken(response)

		await this.fetchUser()
		this.app.emit('moderator:dataFetched', this.app)

		return { error: false }
	}

	async fetchUser () {
		const service = this.app.getService('rext')
		const response = await service.getMe()

		if (response.error) this.removeTokenCookie()
		else this.moderator = response
	}

	removeTokenCookie () {
		cookies.remove('rext-token-admin')
		this.token = false
	}

	setToken (token) {
		this.token = token
		cookies.set('rext-token-admin', JSON.stringify(this.token), { expires: 30 })
	}

	async restoreToken () {
		let token = cookies.get('rext-token-admin')
		if (!token) return false
		token = JSON.parse(token)
		this.token = token
		await this.fetchUser()
	}

	async signOut () {
		const service = this.app.getService('rext')
		await service.deleteToken(this.token.id)
		this.removeTokenCookie()
		window.location.reload()
	}
}

export { Authorization }
