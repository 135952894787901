<template>
	<div class="file-uploader-wrapper">
		<div class="file-uploader">
			<uploader-list :files="uploader.files" @clearUploader="clearUploader" />
			<div class="drop-area" :class="{ 'uploader-list-hidden': !isUploaderListVisible, 'dragover': isDrag }"
				ref="dropArea" @drop.prevent="dropHandler" @dragover.prevent @dragenter.prevent="isDrag = true"
				@dragleave.prevent="isDrag = false"
			>
				<base-font size="xs" weight="bold">{{ lang.upload_area_header }}</base-font>
				<base-font size="xs" weight="bold" uppercase>{{ lang.upload_area_or }}</base-font>
				<base-button class="add-file-btn" @click="openFileSelector">
					<base-svg class="icon" name="explorer_add_file" />
					<span>{{ lang.upload_file }}</span>
				</base-button>
				<input class="file-input" ref="fileInput" type="file" multiple @change="addFilesToList">
			</div>
		</div>
	</div>
</template>

<script>
import BaseFont from '@a/components/atoms/BaseFont/BaseFont'
import BaseButton from '@a/components/atoms/BaseButton/BaseButton'
import BaseSvg from '@a/components/atoms/BaseSvg/BaseSvg'
import UploaderList from '~/fileManager/admin/components/UploaderList/UploaderList'

export default {
	components: {
		BaseButton,
		BaseFont,
		BaseSvg,
		UploaderList
	},
	props: {
		uploader: Object
	},
	data: () => ({
		isDrag: false
	}),
	computed: {
		lang () {
			return this.$app.translator.active.translations['file-manager']
		},
		isUploaderListVisible () {
			return this.uploader.files > 0
		}
	},
	methods: {
		openFileSelector () {
			this.$refs.fileInput.click()
		},
		async addFilesToList (event) {
			try {
				const filesData = event.target.files
				await this.uploader.add(filesData)
			} finally {
				this.resetInput()
			}
		},
		async dropHandler (event) {
			try {
				const filesData = event.dataTransfer.files
				this.isDrag = false
				await this.uploader.add(filesData)
			} finally {
				this.resetInput()
			}
		},
		resetInput () {
			this.$refs.fileInput.value = null
		},
		clearUploader () {
			this.uploader.removeAll()
		}
	}
}
</script>

<style scoped lang="scss">
.file-uploader-wrapper {
	width: 100%;
	border: 1px solid #ACACAC;
	position: relative;
	top: -1px;

	.drop-area {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-bottom: 2rem;
		width: inherit;

		::v-deep p {
			user-select: none;
		}

		::v-deep p:first-of-type {
			color: $primary;
		}

		::v-deep p:last-of-type {
			color: #ACACAC;
		}

		.file-input {
			visibility: hidden;
		}

		::v-deep .add-file-btn {
			display: flex;
			align-items: center;
			transition: none;
		}

		.icon {
			width: 15px;
			height: 15px;
			margin-right: .5rem;
			filter: brightness(500%);
		}
	}
}
.uploader-list-hidden {
	padding: 2rem 0;
}
.dragover {
	background: #E8F0FE;
	box-shadow: inset 2px 2px #4285F4, inset -2px -2px #4285F4, 0 1px 2px rgb(0 0 0 / 10%);

	::v-deep * {
		visibility: hidden;
	}
}
</style>
