<template>
	<div class="prompt" v-if="prompt">
		<div class="content">
			{{prompt.question}}
		</div>
		<button-wrapper>
			<base-button outline @click="prompt.close(false)">Nie</base-button>
			<base-button @click="prompt.close(true)">Tak</base-button>
		</button-wrapper>
	</div>
</template>

<script>
import ButtonWrapper from '@a/components/atoms/ButtonWrapper/ButtonWrapper.vue'
import BaseButton from '@a/components/atoms/BaseButton'
export default {
	computed: {
		prompt () {
			return this.$app.prompt
		}
	},
	components: {
		ButtonWrapper,
		BaseButton
	}
}
</script>

<style lang="scss" scoped>
.prompt {
	position: fixed;
	top: 45px;
    z-index: 70;
	left: 50%;
	transform: translateX(-50%);
	max-width: 90vw;
	width: 280px;
	display: flex;
	flex-direction: column;
	background: white;
	border: 1px solid $primary;
	padding: 10px 10px 13px 10px;
	overflow: hidden;
	border-radius: $border-radius;
	justify-content: space-between;
	align-items: center;
	@include media-breakpoint-up(md) {
		max-width: 350px;
		width: auto;
	}
	.content {
		width: 100%;
		margin-bottom: 10px;
		font-size: 1.4rem;
	}
}

</style>
