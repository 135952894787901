<template>
	<th v-if="column.list.draggableOn">
	</th>
</template>

<script>
export default {
	props: {
		column: Object
	}
}
</script>
