<template>
	<div class="wrapper">
		<button-submit class="button-edit" :class="classes" @click="element.submit()" :loading="element.submitProcessing">
			{{lang.edit_button}}
		</button-submit>
		<button-submit class="button-edit-close" :class="classes" @click="element.submitAndClose()" :loading="element.submitCloseProcessing">
			{{lang.edit_close_button}}
		</button-submit>
	</div>
</template>

<script>
import ButtonSubmit from '@a/components/molecules/ButtonSubmit'
import { getValue } from 'utils/objects'

export default {
	components: {
		ButtonSubmit
	},
	props: {
		element: Object
	},
	computed: {
		lang () {
			return getValue(this.element.label, this.l, '')
		},
		classes () {
			return {
				'button-submit-right': this.element.right
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.button-submit-right {
	justify-content: flex-end;
}
.wrapper {
	display: flex;
	justify-content: flex-end;
	& ::v-deep .button-edit-close {
		margin-left: 2rem;
	}
}
</style>
