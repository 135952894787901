<template>
	<div class="tab-nav">
		<tab-nav-item
			v-for="tab in tabs"
			:key="tab._uid"
			:tab="tab"
		/>
	</div>
</template>

<script>
import TabNavItem from '@a/components/modules/forms/TabNav/TabNavItem'
export default {
	components: {
		TabNavItem
	},
	props: {
		tabs: Array
	}
}
</script>

<style lang="scss" scoped>
.tab {
	&-nav {
		display: flex;
		flex-direction: column;
		border-bottom: 1px solid $primary;
		@include media-breakpoint-up (md) {
			flex-direction: row;
		}
	}
}
</style>
