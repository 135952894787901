import { Element } from './Element'
import { asyncForEach } from 'utils/async'
import ListComponent from '@a/pages/lists/ListComponent'
import RowListPage from '@a/pages/lists/RowListPage.vue'
import { InputText } from '@a/core/elements/inputs/InputText'

class ListElement extends Element {
	columnGenerators = []
	columns = []
	component = ListComponent
	rowComponent = RowListPage

	pagination
	_page = 1
	perPage = 10
	maxPage = null
	changePageTimeout
	_data = []
	dataLength = 0
	draggableOn = false

	constructor ({ data = '', pagination = true }, init = true) {
		super()
		this.data = data
		this.pagination = pagination

		this.inputPageNumber = new InputText({
			value: 1,
			name: 'pageNumber',
			label: 'inputs.label.page_change'
		})
		this.inputPageNumber.initReactive()
		this.inputPageNumber.on('value:changed', val => this.goToPage(val))
		if (init) this.initReactive()
	}

	addColumn (column) {
		this.columnGenerators.push(column)

		return this
	}

	onOrderChanged () {}

	async create (parent) {
		this.parent = parent
		await asyncForEach(this.columnGenerators, async ({ type: Column, ...object }) => {
			const column = new Column(object, this)
			column.on('order:changed', this.onOrderChanged.bind(this))
			this.columns.push(column)
		})
		return this
	}

	set data (value) {
		this._data = value
		this.dataLength = value.length
		this.maxPage = Math.ceil(this.dataLength / this.perPage)
	}

	get data () {
		if (!this.pagination) return this.sort(this._data)
		const take = this.skip + this.take
		const data = this._data.slice(this.skip, take)
		return this.sort(data)
	}

	get take () {
		return this.perPage
	}

	get skip () {
		return (this.page - 1) * this.perPage
	}

	goToPage () {
		clearTimeout(this.changePageTimeout)
		this.changePageTimeout = setTimeout(() => {
			const input = this.inputPageNumber
			if (this.maxPage < input.value) input.value = this.maxPage
			else if (input.value < 1 || isNaN(input.value)) input.value = 1
			if (this.page === input.value) return
			this.page = this.inputPageNumber.value
		}, 200)
	}

	previousPage () {
		this.page -= 1
	}

	nextPage () {
		this.page += 1
	}

	set page (number) {
		this._page = number
		this.inputPageNumber.value = this._page
		this._emit('page:changed')
		return this._page
	}

	get page () {
		return this._page
	}

	get isLastPage () {
		return this.dataLength <= this.page * this.perPage
	}

	get isFirstPage () {
		return this.page === 1
	}

	sort (value) {
		return value
	}
}

const listElementGenerator = app => async (object) => {
	return async () => {
		const list = new ListElement({
			data: object.data,
			pagination: object.pagination
		})
		await asyncForEach(object.columns, async column => await list.addColumn(column))
		return list
	}
}

export {
	ListElement,
	listElementGenerator
}
