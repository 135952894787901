import { Input } from './Input'
import InputPriceTemplate from '@a/components/modules/inputs/organisms/InputPrice.vue'
import _ from 'lodash'

class InputPrice extends Input {
	component = InputPriceTemplate

	constructor ({ ...data }) {
		super(data)
		this.type = 'number'
	}

	setInitialValue (values) {
		const value = this.attribute ? (values ? _.get(values, this.attribute) : undefined) : values
		if (value !== undefined) this.value = value / 100
		else this.clear()
	}

	getValue () {
		let value = this.value
		if (typeof this.value === 'string') value = parseFloat(this.value.replace(',', '.'))
		value = value.toFixed(2) * 100

		if (!this.attribute) return value
		return _.set({}, this.attribute, value)
	}
}

const inputPriceGenerator = app => async (object) => {
	return async () => {
		const inputPrice = new InputPrice({
			...object
		})
		return inputPrice
	}
}

export { InputPrice, inputPriceGenerator }
