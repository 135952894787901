import { EntityListPage } from '@a/core/pages/entity/list/EntityListPage'
import { checkPermissions } from '~/authorization/admin/utils/checkPermissions'
import { BaseColumn } from '@a/core/elements/columns/BaseColumn'
import { ActionColumn } from '@a/core/elements/columns/ActionColumn'

class ProjectListPage extends EntityListPage {
	static entity = 'project'
	static visible = checkPermissions('project_management')
	new = true

	getColumns () {
		return [
			{
				type: BaseColumn,
				name: 'id',
				sortParam: 'id',
				getter: project => project.id
			},
			{
				type: BaseColumn,
				name: 'name',
				sortParam: 'name',
				getter: project => project.name
			},
			{
				type: ActionColumn,
				actions: ['edit', 'remove']
			}
		]
	}
}

export { ProjectListPage }
