import { Input } from './Input'
import InputNumberTemplate from '@a/components/modules/inputs/organisms/InputNumber.vue'

class InputNumber extends Input {
	component = InputNumberTemplate

	constructor ({ ...data }) {
		super(data)
		this.type = 'number'
	}

	getValue () {
		if (!this.attribute) return parseInt(this.value)
		return {
			[this.attribute]: parseInt(this.value)
		}
	}
}

const inputNumberGenerator = app => async (object) => {
	return async () => {
		const inputNumber = new InputNumber({
			...object
		})
		return inputNumber
	}
}

export { InputNumber, inputNumberGenerator }
