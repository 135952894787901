<template>
	<div
		class="menu-element"
		v-if="element.visible"
		@click="element.clickHandler.call(element)"
	>
		<base-svg
			class="menu-icon"
			:class="{small: child}"
			:name="element.icon"
		/>
		<base-font variant="paragraph" color="white">
			{{ name }}
		</base-font>
	</div>
</template>

<script>
import BaseFont from '@a/components/atoms/BaseFont'
import BaseSvg from '@a/components/atoms/BaseSvg'

export default {
	components: {
		BaseFont,
		BaseSvg
	},
	computed: {
		activeLanguage () {
			return this.$app.translator.active.alias
		},
		name () {
			const langPath = `menu.${this.element.name}`
			const value = this.$app.translator.getTranslation(`${langPath}`)[this.activeLanguage] || this.element.name
			return value
		}
	},
	props: {
		element: Object,
		child: Boolean
	}
}
</script>

<style lang="scss" scoped>
.menu-element {
	display: flex;
	align-items: center;
	padding: 1rem 1.5rem;
	cursor: pointer;
	height: 60px;
	@include transition;
	& ::v-deep p {
		font-size: 1.3rem;
		line-height: 1.8rem;
	}
	&:hover {
		background: rgba(0, 0, 0, 0.15);
	}
}
.menu-icon {
	width: 20px;
	fill: $white;
	margin-right: 1rem;
	&.small {
		width: 15px;
	}
	path {
		fill: $white;
	}
}
</style>
