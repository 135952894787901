import { ProjectListPage } from '~/project/admin/core/pages/ProjectListPage'
import { Project } from '~/project/admin/core/entities/Project'
import { rextServiceExtend } from '~/project/admin/core/services/RextService'
import { MenuElement } from '@a/core/layouts/default/menu/MenuElement'
import { checkPermissions } from '~/authorization/admin/utils/checkPermissions'
import { ProjectEditPage } from '~/project/admin/core/pages/ProjectEditPage'
import { ProjectCreatePage } from '~/project/admin/core/pages/ProjectCreatePage'

class ProjectModule {
	readyToAddPages (app) {
		app.addPage(ProjectListPage)
		app.addPage(ProjectEditPage)
		app.addPage(ProjectCreatePage)
	}

	readyToAddEntities (app) {
		app.addEntity(Project)
	}

	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
	}

	afterLayoutsCreated (app) {
		const layout = app.getLayout('default')
		const project = new MenuElement({
			name: 'projects',
			icon: 'projects_list',
			to: { name: 'project-list' },
			visible: checkPermissions('project_management')
		})

		layout.menu.addElement(project)
	}
}

export { ProjectModule }
