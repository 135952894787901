import Vue from 'vue'
import uniqueId from 'lodash/uniqueId'

/**
 * Klasa której zadaniem jest zamiana wszystkich dostępnych property w trakcie inicjalizacji na property reaktywne
 * dzięki czemu można przy ich użyciu tworzyć reaktywne widoki w aplikacji Vue
 * @property {String} _uid - unikalne id obiektu
 * @property {Object} _observators - obiekt przetrzymujący pary "event: [callback, callback, ... ]"
 */
class ReactiveClass {
	static create () {
		const instance = new this(...arguments)

		instance.initReactive()

		if (instance.init) instance.init()
		return instance
	}

	constructor () {
		this._uid = uniqueId('_reactive_')
		this._observators = {}
	}

	/**
	 * Funkcja inicjująca reaktywne property (musi to nastąpić zanim obiekt zostanie użyty w komponencie Vue)
	 */
	initReactive () {
		Vue.util.defineReactive(window, this._uid, this)
	}

	/**
	 * Pozwala nasłuchiwać innym obiektom na event emitowany przez ten obiekt
	 * @param {String} event
	 * @param {Function} callback
	 */
	on (event, callback) {
		if (!Array.isArray(this._observators[event])) this._observators[event] = []
		this._observators[event].push(callback)
	}

	/**
	 * Pozwala wyemitować event do obserwatorów
	 * @param {String} event
	 * @param {Object} data - Obiekt który zostanie przekazany do wywoływanego callbacku
	 */
	_emit (event, data) {
		const events = this._generateEvents(event.split(':'))
		events.map(e => this._tryEmit(e, data, event))
	}

	_generateEvents (parts, prefix) {
		parts = JSON.parse(JSON.stringify(parts))
		const head = parts.shift()

		if (!prefix) {
			return [
				this._generateEvents(parts, head),
				this._generateEvents(parts, '*'),
				'*'
			].flat()
		}

		if (!head) return prefix

		if (parts.length === 0) {
			return [
				prefix + ':' + head,
				prefix + ':*'
			]
		}

		return [
			this._generateEvents(parts, prefix + ':' + head),
			this._generateEvents(parts, prefix + ':*'),
			prefix + ':*'
		].flat()
	}

	_tryEmit (event, data, realEvent) {
		if (!Array.isArray(this._observators[event])) return false
		this._observators[event].map(function (callback) {
			callback(data, realEvent)
		})
	}
}

export {
	ReactiveClass
}
