<template>
	<div class="nav-wrapper">
		<button @click="toggleNav" class="mobile-button">
			<div class="mobile-button-box">
				<div
					class="mobile-button-inner-wrapper"
					:class="{active: isActive}"
				></div>
			</div>
		</button>
		<nav class="menu" :class="{active: isActive}">
			<component
				v-for="element in menu.elements"
				:is="element.component"
				:key="element.name"
				:element="element"
			/>
		</nav>
		<div @click="closeNav" class="overlay"></div>
	</div>
</template>

<script>
export default {
	data () {
		return {
			isActive: false
		}
	},
	props: {
		menu: Object
	},
	methods: {
		toggleNav () {
			this.isActive = !this.isActive
		},
		closeNav () {
			this.isActive = false
		}
	},
	watch: {
		$route () {
			this.closeNav()
		}
	}
}
</script>

<style lang="scss" scoped>
.menu {
	position: fixed;
	top: 50px;
	left: 0;
	height: calc(100vh - 50px);
	overflow-y: auto;
	width: 255px;
	background: $primary;
	display: flex;
	flex-direction: column;
	padding: 2rem 0 1.5rem;
	z-index: 20;
	transform: translateX(-100%);
	@include transition;
	@include media-breakpoint-up (lg) {
		transform: translateX(0);
	}
	@include media-breakpoint-up(xl) {
		z-index: 10;
	}
	&.active {
		transform: translateX(0);
		& + .overlay {
			opacity: 1;
			pointer-events: initial;
		}
	}
	& > * {
		text-transform: uppercase;
	}
}
::v-deep {
	svg {
		width: 20px;
	}
}
.overlay {
	position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgb($grey-800, 0.7);
	opacity: 0;
	z-index: 7;
	pointer-events: none;
	@include transition;
}
.mobile-button {
	position: fixed;
	top: -3px;
	z-index: 1000;
	display: inline-block;
	margin: 0;
	padding: 1.5rem;
	cursor: pointer;
	transition: opacity, filter .3s linear;
	border: 0;
	outline: none;
	background-color: transparent;
	@include media-breakpoint-up(lg) {
		display: none;
	}
	&-box {
		position: relative;
		display: inline-block;
		width: 23px;
		height: 17px;
	}
	&-inner-wrapper {
		top: 2px;
		&.active {
			transform: translate3d(0, 10px, 0) rotate(45deg);
			&:after {
				transform: translate3d(0,-16px,0) rotate(-90deg);
			}
			&:before {
				transform: rotate(-45deg) translate3d(-5.71429px,-6px,0);
				opacity: 0;
			}
		}
		&,
		&::before,
		&::after {
			position: absolute;
			width: 23px;
			height: 4px;
			transition: transform .3s ease;
			border-radius: 4px;
			background-color: $grey-400;
		}
		&::before,
		&::after {
			display: block;
			content: '';
		}
		&::after {
			top: 1.6rem;
		}
		&::before {
			top: 8px;
			transition: transform, opacity .3s ease;
		}
	}
}
</style>
