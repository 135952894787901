import { formGenerator } from '@a/core/elements/Form'
import { columnElementGenerator } from '@a/core/elements/ColumnElement'
import { tabWrapperElementGenerator } from '@a/core/elements/TabWrapperElement'
import { tabElementGenerator } from '@a/core/elements/TabElement'
import { inputTextGenerator } from '@a/core/elements/inputs/InputText'
import { inputDateGenerator } from '@a/core/elements/inputs/InputDate'
import { inputPasswordGenerator } from '@a/core/elements/inputs/InputPassword'
import { entityInputGenerator, inputSearchSelectGenerator } from '@a/core/elements/inputs/InputSearchSelect'
import { entityManyInputGenerator, inputManySearchSelectGenerator } from '@a/core/elements/inputs/InputManySearchSelect'
import { listElementGenerator } from '@a/core/elements/ListElement'
import { entityListElementGenerator } from '@a/core/elements/EntityListElement'
import { buttonSubmitGenerator } from '@a/core/elements/ButtonSubmit'
import { buttonsGenerator } from '@a/core/elements/Buttons'
import { inputEditorGenerator } from '@a/core/elements/inputs/InputEditor'
import { inputSelectGenerator } from '@a/core/elements/inputs/InputSelect'
import { repeaterElementGenerator } from '../elements/RepeaterElement'
import { inputNumberGenerator } from '@a/core/elements/inputs/InputNumber'
import { inputPriceGenerator } from '@a/core/elements/inputs/InputPrice'
import { multiSchemaElementGenerator } from '../elements/MultiSchemaElement'
import { inputTextareaGenerator } from '../elements/inputs/InputTextarea'
import { inputTreeViewGenerator } from '@a/core/elements/inputs/InputTreeView'
import { orderedEntityListElementGenerator } from '../elements/OrderedEntityListElement'

const AppElement = {
	elements: {},
	addElement (type, fn) {
		this.elements[type] = fn
	},
	async createElement ({ $type, ...object }) {
		if (!this.elements[$type]) console.error(`Can not find ${$type} element! Add it using app.addElement method.`)
		return await this.elements[$type](object)
	},
	async initElements () {
		this.addElement('entity_input', entityInputGenerator(this))
		this.addElement('search_select_input', inputSearchSelectGenerator(this))
		this.addElement('form', formGenerator(this))
		this.addElement('column', columnElementGenerator(this))
		this.addElement('password_input', inputPasswordGenerator(this))
		this.addElement('number_input', inputNumberGenerator(this))
		this.addElement('price_input', inputPriceGenerator(this))
		this.addElement('text_input', inputTextGenerator(this))
		this.addElement('textarea', inputTextareaGenerator(this))
		this.addElement('date_input', inputDateGenerator(this))
		this.addElement('tab_wrapper', tabWrapperElementGenerator(this))
		this.addElement('tab', tabElementGenerator(this))
		this.addElement('list', listElementGenerator(this))
		this.addElement('entity_list', entityListElementGenerator(this))
		this.addElement('ordered_entity_list', orderedEntityListElementGenerator(this))
		this.addElement('button_submit', buttonSubmitGenerator(this))
		this.addElement('buttons', buttonsGenerator(this))
		this.addElement('entity_many_input', entityManyInputGenerator(this))
		this.addElement('editor_input', inputEditorGenerator(this))
		this.addElement('select_input', inputSelectGenerator(this))
		this.addElement('many_select_input', inputManySearchSelectGenerator(this))
		this.addElement('repeater', repeaterElementGenerator(this))
		this.addElement('multi_schema_element', multiSchemaElementGenerator(this))
		this.addElement('product_treeview_input', inputTreeViewGenerator(this))
	}
}

export {
	AppElement
}
