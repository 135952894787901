import { EntityCreateFormPage } from '@a/core/pages/entity/create/EntityCreateFormPage'
import { checkPermissions } from '~/authorization/admin/utils/checkPermissions'
import { getPasswordRules } from 'utils/inputs/passwordRules'
import { Notification, NotificationType } from '@a/core/entities/Notification'
import { Rule } from '@a/core/elements/inputs/InputPassword'
import { moderatorFormSchema } from './moderatorFormSchema'

class ModeratorCreatePage extends EntityCreateFormPage {
	static entity = 'moderator'
	static visible = checkPermissions('user_management')

	notify (success, data) {
		if (success) Notification.create(NotificationType.SUCCESS, 'entities.moderator.pages.create.notification.success')
		else if (data.alias === 'USER_EMAIL_IS_NOT_UNIQUE') Notification.create(NotificationType.ERROR, 'entities.moderator.pages.create.notification.email_is_used')
		else Notification.create(NotificationType.ERROR, 'entities.moderator.pages.create.notification.error')
	}

	async getFormSchema () {
		return {
			$type: 'form',
			elements: [
				...(await moderatorFormSchema(this.app)),
				{
					$type: 'column',
					grid: {
						col: 12,
						lg: 6
					},
					elements: [
						{
							$type: 'password_input',
							attribute: 'user.password',
							name: 'password',
							label: 'entities.moderator.pages.form.password',
							rules: getPasswordRules(Rule)
						}
					]
				},
				{
					$type: 'column',
					grid: {
						col: 12
					},
					elements: [
						{
							$type: 'button_submit',
							right: true,
							label: 'entities.moderator.pages.list.create_button'
						}
					]
				}
			]
		}
	}
}

export { ModeratorCreatePage }
