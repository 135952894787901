<template>
	<header-layout :title="l.pages.user_change_password.title" v-if="page.ready">
		<base-element :class="{form: page.form}" :element="page.form"/>
	</header-layout>
</template>

<script>
import BaseElement from '@a/components/atoms/BaseElement'
import HeaderLayout from '@a/components/molecules/layouts/HeaderLayout.vue'

export default {
	components: {
		BaseElement,
		HeaderLayout
	},
	computed: {
		page () {
			return this.$app.router.page
		}
	}
}
</script>
<style lang="scss" scoped>
.center {
	text-align: center;
}
.wrapper {
	display: flex;
	justify-content: space-between;
}
.form ::v-deep {
	.button-submit {
		justify-content: flex-end;
		@include media-breakpoint-up(md) {
			justify-content: flex-start;
		}
	}
}
</style>
