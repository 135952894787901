import { Element } from '@a/core/elements/Element'
import FileInputComponent from '~/fileManager/admin/components/FileInput'
import { FileManagerModal } from './FileManagerModal'

class FileInput extends Element {
	static async create (object) {
		const input = new FileInput(object)
		await input.init()
		return input
	}

	component = FileInputComponent
	modal
	value
	parent
	name
	location

	constructor ({ value = '', name, label, attribute }) {
		super()
		this.value = value
		this.name = name
		this.attribute = attribute
		this.label = this.getLabel(label)
	}

	async init () {
		this.modal = await FileManagerModal.create()
	}

	getLabel (label) {
		if (typeof label === 'object') return label
		const translation = window.app.translator.getTranslation(label)
		if (translation) return translation
		return label
	}

	setInitialValue (value) {
		this.value = this.attribute ? (value ? value[this.attribute] : undefined) : value
	}

	getValue () {
		return {
			[this.attribute]: this.value
		}
	}

	validate () {}

	create (parent) {
		this.parent = parent
	}

	async open () {
		this.modal.open(this.value)
		this.value = await this.modal.getResult()
	}

	clear () {
		this.value = ''
	}
}

const fileInputGenerator = app => async (object) => {
	return async () => {
		const input = FileInput.create(object)

		return input
	}
}

export { FileInput, fileInputGenerator }
