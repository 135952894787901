<template>
	<td>{{column.getValue(object)}}</td>
</template>

<script>
export default {
	props: {
		column: Object,
		object: [Object, Array]
	}
}
</script>

<style lang="scss" scoped>
td {
	white-space: pre;
}
</style>
