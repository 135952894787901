import { Directory } from '~/fileManager/admin/core/fileManager/Directory'
import { ListFileItem } from '~/fileManager/admin/core/fileManager/ListFileItem'
import { ListDirectoryItem } from '~/fileManager/admin/core/fileManager/ListDirectoryItem'
import { Notification, NotificationType } from '@a/core/entities/Notification'
import { Uploader } from '~/fileManager/admin/core/fileManager/Uploader'
import { FileDownloadingStatus } from '~/fileManager/admin/core/fileManager/FileDownloadingStatus'

class Explorer {
	uploader
	sortedItems = []
	choosedItems = []
	maxNumberOfItemsToChoose
	currentDirectory
	app
	orderBy
	orderType = false
	listDirectoryItems = []
	listFilesItems = []
	fileDownloadingStatuses = []
	rootPath

	static async create (app, maxNumberOfItemsToChoose, path, rootPath) {
		const explorer = new Explorer(app, maxNumberOfItemsToChoose, rootPath)
		await explorer.initUploader()
		await explorer.goToPath(path)

		return explorer
	}

	constructor (app, maxNumberOfItemsToChoose, rootPath) {
		this.app = app
		this.maxNumberOfItemsToChoose = maxNumberOfItemsToChoose
		this.rootPath = rootPath

		this.app.on('explorer:refresh', this._refresh.bind(this))
		this.app.on('file:download-start', this.createFileDownloadingStatus.bind(this))
	}

	get canChooseMoreItems () {
		return this.choosedItems.length + 1 <= this.maxNumberOfItemsToChoose
	}

	async initUploader () {
		this.uploader = await Uploader.create(this.app, this)
	}

	async _refresh () {
		await this.currentDirectory.init()
		this.initializeItems()
	}

	async goUp () {
		const parentDirectory = this.currentDirectory.getParent()
		await this.goToDirectory(parentDirectory)
	}

	async goToPath (path) {
		const directory = new Directory({
			app: this.app,
			path
		})
		await this.goToDirectory(directory)
	}

	async goToDirectory (directory) {
		await directory.init(this.rootPath)

		this.currentDirectory = directory
		this.choosedItems = []
		this.uploader.removeAll()
		this.initializeItems()
	}

	initializeItems () {
		const files = [...this.currentDirectory.getFiles()].map(file => new ListFileItem(file, this))
		const directories = [...this.currentDirectory.getDirectories()].map(directory => new ListDirectoryItem(directory, this))
		this.listDirectoryItems = directories
		this.listFilesItems = files

		this.sortItems()
	}

	sortItems (header = null) {
		if (!header) {
			this.orderType = false
			this.orderBy = false

			this.sortedItems = [...this.listDirectoryItems, ...this.listFilesItems]
		} else {
			const orderTypes = ['DESC', 'ASC', false]

			if (this.orderBy === header) {
				const nextIndex = (orderTypes.indexOf(this.orderType) + 1) % orderTypes.length
				this.orderType = orderTypes[nextIndex]
			} else {
				this.orderType = orderTypes[0]
			}

			this.orderBy = header

			this.sortedItems = [...this._sort(this.listDirectoryItems), ...this._sort(this.listFilesItems)]
		}
	}

	_sort (items) {
		const sortMultiplier = this.orderType === 'ASC' ? 1 : -1
		const sorted = items.sort((a, b) => {
			const aAttribute = typeof a.item[this.orderBy] === 'string' ? a.item[this.orderBy].toLowerCase() : a.item[this.orderBy]
			const bAttribute = typeof b.item[this.orderBy] === 'string' ? b.item[this.orderBy].toLowerCase() : b.item[this.orderBy]

			if (typeof aAttribute === 'string' && typeof bAttribute === 'string') {
				if (aAttribute.localeCompare(bAttribute, 'pl') > 0) {
					return 1 * sortMultiplier
				} else {
					return -1 * sortMultiplier
				}
			} else {
				if (aAttribute > bAttribute) return 1 * sortMultiplier
				else if (bAttribute > aAttribute) return -1 * sortMultiplier
			}
			return 0
		})
		return sorted
	}

	chooseItem (item) {
		if (this.canChooseMoreItems) {
			this.choosedItems.push(item)
		}
	}

	unchooseItem (item) {
		this.choosedItems = this.choosedItems.filter(choosedItem => choosedItem !== item)
	}

	chooseAllItems () {
		this.sortedItems.forEach(listItem => listItem.choose())
	}

	unchooseAllItems () {
		this.choosedItems.forEach(listItem => listItem.unchoose())
	}

	async removeChoosedItems () {
		const itemsPaths = this.choosedItems.map(listItem => listItem.item.path)
		if (!itemsPaths.length) {
			Notification.create(NotificationType.ERROR, 'file-manager.no_choosed_files')
			return
		}

		const service = this.app.getService('rext')
		const { error } = await service.removeItems(itemsPaths)
		if (error) Notification.create(NotificationType.ERROR, 'file-manager.errors.remove_error')
		else {
			Notification.create(NotificationType.SUCCESS, 'file-manager.remove_success')
			this.choosedItems = []
			await this._refresh()
		}
	}

	async markForDeletionChoosedItems () {
		const itemsPaths = this.choosedItems.map(listItem => listItem.item.path)
		if (!itemsPaths.length) {
			Notification.create(NotificationType.ERROR, 'file-manager.no_choosed_files')
			return
		}

		const service = this.app.getService('rext')
		const { error } = await service.markForDeletionItems(itemsPaths)
		if (error) Notification.create(NotificationType.ERROR, 'file-manager.errors.remove_error')
		else {
			Notification.create(NotificationType.SUCCESS, 'file-manager.mark_for_deletion_success')
			this.choosedItems = []
			await this._refresh()
		}
	}

	async unmarkItemForDeletion (item) {
		const service = this.app.getService('rext')
		const { error } = await service.unmarkItemForDeletion(item.path)
		if (error) Notification.create(NotificationType.ERROR, 'file-manager.errors.remove_error')
		else {
			Notification.create(NotificationType.SUCCESS, 'file-manager.unmark_for_deletion_success')
			await this._refresh()
		}
	}

	async createDirectory (name) {
		const service = this.app.getService('rext')
		const { error } = await service.createDirectory(this.currentDirectory.path, name)
		if (error) Notification.create(NotificationType.ERROR, 'errors.location_exists')
		else {
			Notification.create(NotificationType.SUCCESS, 'file-manager.directory_create_success')
			await this._refresh()
		}
	}

	async downloadChosen () {
		const service = this.app.getService('rext')

		const ListItem = new ListFileItem(this.currentDirectory, this)

		ListItem.items = this.choosedItems.map(listItem => listItem.item.name)

		const newFileDownloadStatus = this.createFileDownloadingStatus(ListItem)
		const { error, response } = await service.getArchive(this.currentDirectory.path, newFileDownloadStatus)
		if (error) Notification.create(NotificationType.ERROR, 'errors.no_files_selected')
		else {
			Notification.create(NotificationType.SUCCESS, 'file-manager.archive_download_success')

			const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
			const fileName = this.currentDirectory.path.replaceAll('/', '-') + new Date().toISOString() + '.zip'
			const fileLink = document.createElement('a')

			fileLink.href = fileUrl
			fileLink.setAttribute('download', fileName)
			document.body.appendChild(fileLink)

			fileLink.click()
			document.body.removeChild(fileLink)

			this.choosedItems = []
		}
	}

	notifyOnDownload (item) {
		Notification.create(NotificationType.SUCCESS, 'file-manager.file_downloaded', lang => {
			return lang[this.app.language].replace('{}', item.name)
		})
	}

	createFileDownloadingStatus (item) {
		const downloadingStatus = new FileDownloadingStatus(item)

		this.fileDownloadingStatuses.push(downloadingStatus)
		return downloadingStatus
	}

	removeFileDownloadingStatus (downloadingStatus) {
		this.fileDownloadingStatuses = this.fileDownloadingStatuses.filter(status => status !== downloadingStatus)
	}
}

export { Explorer }
