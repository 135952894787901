import _ from 'lodash'
import { ReadEntity } from './ReadEntity'

class CRUDEntity extends ReadEntity {
	static async changeOrder (newOrders) {
		const { status } = await window.app
			.getService('rext')
			.http
			.post(`/${this.alias}/order`, newOrders)

		const success = status === 200

		if (success) window.app.emit(`entity:${this._alias}:changeOrder`)
		else window.app.emit(`error:entity:${this._alias}:changeOrder`)

		return success
	}

	constructor (data = {}) {
		super(data)
	}

	get _alias () {
		return this.constructor.alias
	}

	setData (data) {
		super.setData(data)
		this.createPrevObject(data)
	}

	createPrevObject (data) {
		this.OBJECT_PREV_DATA = {}

		Object.entries(data).map(([key, value]) => {
			if (value && value.id) value = value.id
			if (value && Array.isArray(value) && value.length && value[0].id) {
				value = value.map(record => record.id)
			}
			this.OBJECT_PREV_DATA[key] = _.cloneDeep(value)
		})
	}

	diff (data) {
		const diffs = {}
		const entries = Object.entries(data)
		entries.forEach(([key, value]) => {
			const valueIsEqualPrevious = _.isEqual(value, this.OBJECT_PREV_DATA[key])
			if (!valueIsEqualPrevious) diffs[key] = value
		})
		return diffs
	}

	async create (object) {
		const { status, data } = await window.app
			.getService('rext')
			.http
			.post(`/${this._alias}`, object)

		const success = status === 201

		if (success) window.app.emit(`entity:${this._alias}:create`, data)
		else window.app.emit(`error:entity:${this._alias}:create`, data)
		return { success, data }
	}

	async edit (object) {
		const diffs = this.diff(object)
		const { status, data } = await window.app
			.getService('rext')
			.http
			.patch(`/${this._alias}/${this.id}`, diffs)

		const success = status === 200

		if (success) {
			this.setData(data)
			window.app.emit(`entity:${this._alias}:edit`, { name: this.getName() })
		} else window.app.emit(`error:entity:${this._alias}:edit`, { name: this.getName(), alias: data.alias })

		return { success, data }
	}

	async remove () {
		const { status, data } = await window.app
			.getService('rext')
			.http
			.delete(`/${this._alias}/${this.id}`)

		const success = status === 204

		if (success) window.app.emit(`entity:${this._alias}:delete`, { name: this.getName() })
		else window.app.emit(`error:entity:${this._alias}:delete`, { name: this.getName(), alias: data.alias })

		return success
	}
}

export { CRUDEntity }
