<template>
	<base-col class="float repeater" v-bind="element.gridColumns">
		<span class="repeater-title">{{title}}</span>
		<repeater-item v-for="(item, i) in element.items" :key="item.attribute" :index="i" :elementsLength="element.items.length" :item="item"/>
		<button-wrapper  left>
			<base-button @click="element.addItem()">
				<base-svg name="plusIconList"></base-svg>
				{{l.elements.repeater.add}}
			</base-button>
		</button-wrapper>
	</base-col>
</template>

<script>
import BaseCol from '@a/components/atoms/BaseCol'
import RepeaterItem from './RepeaterItemElement'
import ButtonWrapper from '@a/components/atoms/ButtonWrapper'
import BaseButton from '@a/components/atoms/BaseButton'
import BaseSvg from '@a/components/atoms/BaseSvg'
export default {
	components: {
		RepeaterItem,
		BaseCol,
		ButtonWrapper,
		BaseButton,
		BaseSvg
	},
	props: {
		element: Object
	},
	computed: {
		title () {
			if (typeof this.element.title === 'string') return this.element.title
			return this.element.title[this.$app.translator.active.alias]
		}
	}
}
</script>

<style lang="scss">
.button-wrapper {
	button {
		display: flex;
		align-items: center;
		font-size: 1.3rem;
		text-transform: uppercase;
	}
	svg {
		width: 13px;
		margin-right: 1rem;
	}
}
.float {
	float: left;
	width: 100%;
	&::after {
		content: "";
		clear: both;
		display: table;
	}
}
.repeater {
	padding: 10px 0;
	&-title {
		font-size: 1.4rem;
		display: flex;
		width: 100%;
		margin-bottom: 5px;
		font-weight: bold;
	}
}
.body.open {
	.repeater-title {
		color: $white;
	}
	.button-wrapper button {
		border: 1px solid $white;
	}
}
</style>
