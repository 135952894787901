<template>
	<button-submit :class="classes" @click="element.submit()" :loading="element.processing">
		{{lang}}
	</button-submit>
</template>

<script>
import ButtonSubmit from '@a/components/molecules/ButtonSubmit'
import { getValue } from 'utils/objects'

export default {
	components: {
		ButtonSubmit
	},
	props: {
		element: Object
	},
	computed: {
		lang () {
			return getValue(this.element.label, this.l, '')
		},
		classes () {
			return {
				'button-submit-right': this.element.right
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.button-submit-right {
	justify-content: flex-end;
}
</style>
