import * as path from 'path'

class Item {
	path
	size
	birthDate
	app

	constructor ({ app, path, size, birthDate, markedForDeletion, creatorIp }) {
		this.path = path
		this.size = size
		this.birthDate = birthDate
		this.app = app
		this.markedForDeletion = markedForDeletion
		this.creatorIp = creatorIp
	}

	get name () {
		return decodeURI(path.basename(this.path))
	}

	getSize () {
		return this.size
	}

	getBirthDate () {
		return this.birthDate
	}
}

export { Item }
