import { asyncMap } from 'utils/async'

export function rextServiceExtend (Service) {
	return class RextService extends Service {
		async getDirectoryInformation (path) {
			if (typeof path === 'undefined') return { error: true }
			const response = await this.http.get(`/filemanager/directory/${path}`)

			if (response.status !== 200) return { error: true }

			return response.data
		}

		async createDirectory (path, name) {
			if (typeof path === 'undefined' || !name) return { error: true }
			const { status, data } = await this.http.post(`/filemanager/directory/${path}`, { name })

			if (status !== 201) return { error: true }
			return { error: false, data }
		}

		async uploadFile (file) {
			if (!file) return { error: true }
			const path = file.getFullPath()

			const correctName = await this._getFileCorrectName(path)
			if (file.name !== correctName) file.name = correctName

			const chunkSendingStatuses = await asyncMap(file.chunks, async chunkData => {
				const { error } = await this._uploadChunk(chunkData, file.getFullPath())
				if (!error) file.updateProgress()
				return error
			})
			const isError = Object.values(chunkSendingStatuses).some(error => error === true)

			return { error: isError }
		}

		async _uploadChunk (chunkData, path) {
			if (!chunkData) return { error: true }

			const { status } = await this.http.post(`/filemanager/files/${path}`, chunkData.blob, {
				headers: {
					isChunkLast: chunkData.isLast
				},
				onUploadProgress: progressEvent => {
					chunkData.loaded = progressEvent.loaded
				}
			})

			if (status !== 201) return { error: true }
			return { error: false }
		}

		async _getFileCorrectName (path) {
			if (!path) return { error: true }
			const { data } = await this.http.get(`/filemanager/paths/${path}`)
			if (data.name) return data.name
			return path
		}

		async removeItems (paths) {
			if (!paths) return { error: true }
			const { status } = await this.http.delete('/filemanager/items', {
				data: {
					items: paths
				}
			})

			if (status !== 204) return { error: true }
			return { error: false }
		}

		async markForDeletionItems (paths) {
			if (!paths) return { error: true }
			const { status } = await this.http.patch('/filemanager/mark-for-deletion', {
				items: paths
			})

			if (status !== 200) return { error: true }
			return { error: false }
		}

		async unmarkItemForDeletion (path) {
			if (!path) return { error: true }
			const { status } = await this.http.patch('/filemanager/unmark-for-deletion', {
				item: path
			})

			if (status !== 200) return { error: true }
			return { error: false }
		}

		async getFile (fileDownloadingStatus) {
			const { file } = fileDownloadingStatus

			if (!file.item.path) return { error: true }

			const response = await this.http.get(`/filemanager/files/${file.item.path}`, {
				responseType: 'arraybuffer',
				onDownloadProgress: progressEvent => {
					fileDownloadingStatus.updateProgress(progressEvent)
				}
			})

			if (response.status !== 200) return { error: true }

			return response.data
		}

		async getArchive (path, fileDownloadingStatus) {
			const { items } = fileDownloadingStatus.file
			if (!items) return { error: true }
			const response = await this.http.post(`/filemanager/zip${path}`, {
				items
			}, {
				responseType: 'arraybuffer',
				onDownloadProgress: progressEvent => {
					fileDownloadingStatus.updateProgress(progressEvent)
				}
			})

			if (response.status !== 200) return { error: true }

			return { response }
		}
	}
}
