import ButtonsComponent from '@a/components/modules/forms/ButtonsElement'
import { ButtonSubmit } from './ButtonSubmit'
class Buttons extends ButtonSubmit {
	component = ButtonsComponent
	submitProcessing
	submitCloseProcessing

	async submit () {
		this.submitProcessing = true
		if (!await this.form.validate()) return
		await this.form.submit()
		this.submitProcessing = false
	}

	async submitAndClose () {
		this.submitCloseProcessing = true
		if (!await this.form.validate()) return
		await this.form.submit()
		this.submitCloseProcessing = false
		window.app.vue.$router.go(-1)
	}
}

const buttonsGenerator = app => async (object) => {
	return async () => {
		const button = new Buttons(object)

		return button
	}
}

export { Buttons, buttonsGenerator }
