import InputSearchSelectTemplate from '@a/components/modules/inputs/organisms/InputSearchSelect.vue'
import { InputSelect, Option } from './InputSelect'

class InputSearchSelect extends InputSelect {
	component = InputSearchSelectTemplate
}

const entityInputGenerator = app => async (object) => {
	return async () => {
		const input = new InputSearchSelect({
			...object
		})
		const objects = await object.entity.list()
		const options = objects.map(object => new Option({
			id: object.id,
			name: object.getName()
		}))
		input.setOptions(options)
		return input
	}
}

const inputSearchSelectGenerator = app => async (object) => {
	return async () => {
		const input = new InputSearchSelect({
			...object
		})
		return input
	}
}

export { InputSearchSelect, Option, entityInputGenerator, inputSearchSelectGenerator }
