<template>
	<div class="dropdown-wrapper">
		<div class="dropdown-topbar" @click.stop="toggle">
			<component
				:class="{active: element.isOpen}"
				:is="element.topbarComponent"
			/>
			<base-svg class="dropdown-arrow" name="keyboard_arrow_down"/>
		</div>
		<div
			v-if="element.isOpen"
			class="dropdown-dropdown"
		>
			<component
				:is="element.dropdownComponent"
				:dropdown="element"
			/>
		</div>
	</div>
</template>

<script>
import BaseSvg from '@a/components/atoms/BaseSvg'

export default {
	components: {
		BaseSvg
	},
	props: {
		element: Object
	},
	methods: {
		toggle () {
			this.element.toggle()
		}
	}
}
</script>

<style lang="scss" scoped>
.dropdown-wrapper {
	position: relative;
	display: flex;
	align-items: center;
}
.dropdown.active + .dropdown-arrow {
	transform: translateY(-50%) rotate(180deg);
}
.dropdown-arrow {
	position: absolute;
	height: 25px;
	top: 50%;
	right: 0px;
	fill: $white;
	transform: translateY(-50%);
	transition: .3s ease;
}
.dropdown-topbar {
	cursor: pointer;
	& * {
		text-transform: uppercase;
		font-size: 1.3rem;
	}
}
</style>
