<template>
	<div>
		<component v-if="page" :layout="layout" :is="layout.component">
			<router-view />
		</component>
		<notification-wrapper />
		<base-prompt/>
		<base-modal/>
	</div>
</template>

<script>
import '@a/assets/scss/base/index.scss'
import NotificationWrapper from '@a/components/organisms/Notification/NotificationsWrapper'
import BasePrompt from '@a/components/organisms/BasePrompt'
import BaseModal from '@a/components/organisms/BaseModal'

export default {
	components: {
		NotificationWrapper,
		BasePrompt,
		BaseModal
	},
	computed: {
		layout () {
			return this.$app.router.page.layout
		},
		page () {
			return this.$app.router.page
		}
	},
	created () {
		document.title = 'Magazyn materiałów - RekinySukcesu.pl'
	}
}
</script>
