<template>
	<tr>
		<component v-for="column in columns" :key="column._uid" :is="getComponent(column)" :object="object" :column="column"/>
	</tr>
</template>

<script>
export default {
	props: {
		object: [Object, Array],
		columns: Array
	},
	methods: {
		getComponent (BaseColumn) {
			const columnIndex = this.columns.indexOf(BaseColumn)
			if (!this.object[2]) return BaseColumn.component

			if (columnIndex === 1 && this.object[2].value) {
				return this.object[2].value
			}
			return BaseColumn.component
		}

	}
}
</script>

<style lang="scss" scoped>
tr {
	&:nth-child(2) {
		td:first-child {
			border-top-left-radius: 3px;
		}
		border-top-right-radius: 3px;
		border: none;
	}
	&:last-child {
		border: none;
	}
	&:first-child {
		td {
			&:first-child {
				border-top-left-radius: 10px;
			}
			&:last-child {
				border-top-right-radius: 10px;
			}
		}
	}
	&:last-child {
		td {
			&:first-child {
				border-bottom-left-radius: 10px;
			}
			&:last-child {
				border-bottom-right-radius: 10px;
			}
		}
	}
	td {
		position: relative;
		padding: 20px 45px 20px 25px;
		@include media-breakpoint-up(xl) {
			padding-right: 40px;
			padding-left: 20px;
		}
		&:not(:last-child)::before {
			position: absolute;
			content: '';
			width: 2px;
			height: 85%;
			top: 50%;
			transform: translateY(-50%);
			right: 0;
			background-color: $grey-400;
		}
		&:first-child {
			font-weight: bold;
		}
		&:last-child {
			padding-right: 0;
		}
	}
}
</style>
