import { ReadEntity } from '@a/core/entities/ReadEntity'

class Language extends ReadEntity {
	static alias = 'language'

	getName () {
		const langs = window.app.translator.active.translations.entities
		const entitiLangs = langs[this.constructor.alias]

		return entitiLangs[this.id]
	}
}

export { Language }
