import { Input } from './Input'
import InputTextTemplate from '@a/components/modules/inputs/organisms/InputText.vue'

class InputText extends Input {
	component = InputTextTemplate
	_mask = false

	constructor ({ mask = false, type = 'text', ...data }) {
		super(data)
		this._mask = mask
		this.type = type
	}

	get mask () {
		if (typeof this._mask === 'function') return this._mask()
		return this._mask
	}

	get formValue () {
		if (this.type === 'number') return parseInt(super.formValue)
		return super.formValue
	}
}

const inputTextGenerator = app => async (object) => {
	return async () => {
		const inputText = new InputText({
			...object
		})
		return inputText
	}
}

export { InputText, inputTextGenerator }
