class Emitter {
	_observators = []

	/**
	 * Pozwala nasłuchiwać innym obiektom na event emitowany przez ten obiekt
	 * @param {String} event
	 * @param {Function} callback
	 */
	on (event, callback) {
		if (!Array.isArray(this._observators[event])) this._observators[event] = []
		this._observators[event].push(callback)
	}

	/**
	 * Pozwala wyemitować event do obserwatorów
	 * @param {String} event
	 * @param {Object} data - Obiekt który zostanie przekazany do wywoływanego callbacku
	 */
	_emit (event, data) {
		const events = this._generateEvents(event.split(':'))
		events.map(e => this._tryEmit(e, data, event))
	}

	_generateEvents (parts, prefix) {
		parts = JSON.parse(JSON.stringify(parts))
		const head = parts.shift()

		if (!prefix) {
			return [
				this._generateEvents(parts, head),
				this._generateEvents(parts, '*'),
				'*'
			].flat()
		}

		if (!head) return prefix

		if (parts.length === 0) {
			return [
				prefix + ':' + head,
				prefix + ':*'
			]
		}

		return [
			this._generateEvents(parts, prefix + ':' + head),
			this._generateEvents(parts, prefix + ':*'),
			prefix + ':*'
		].flat()
	}

	_tryEmit (event, data, realEvent) {
		if (!Array.isArray(this._observators[event])) return false
		this._observators[event].map(function (callback) {
			callback(data, realEvent)
		})
	}
}

export {
	Emitter
}
