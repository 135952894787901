<template>
	<div class="dropdown">
		<div class="dropdown-item-wrapper">
			<img :src="userImg"/>
			<base-font color="white" uppercase>{{getUserName}}</base-font>

		</div>
	</div>
</template>
<script>
import BaseFont from '@a/components/atoms/BaseFont'

export default {
	components: {
		BaseFont
	},
	computed: {
		getUserName () {
			return this.$app.authorization.moderator.name
		},
		userImg () {
			return require('@a/assets/img/user-img.png')
		}
	}
}
</script>
<style lang="scss" scoped>
.dropdown {
	display: flex;
	align-items: center;
	padding-right: 3rem;
	&-item-wrapper {
		display: flex;
		align-items: center;
		margin-left: 2rem;
		& img {
			display: none;
			margin-right: 1rem;
		}
		@include media-breakpoint-up (md) {
			margin-left: 3rem;
			& img {
				display: block;
			}
		}
	}
}

</style>
