import { CRUDEntity } from '@a/core/entities/CRUDEntity'

class Project extends CRUDEntity {
	static alias = 'project'

	getName () {
		return super.getName()
	}
}

export { Project }
