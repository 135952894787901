import * as pathLib from 'path'

class UploadableFile {
	progress = 0
	blob
	name
	size
	path
	uploader
	chunks = []

	constructor ({ blob, name, size, path, uploader }) {
		this.blob = blob
		this.name = name
		this.size = size
		this.path = path
		this.uploader = uploader
	}

	getFullPath () {
		return pathLib.join(this.path, this.name)
	}

	isUploaded () {
		return this.progress === 100
	}

	updateProgress () {
		const chunkLoadedProgress = this.chunks.map(chunk => chunk.loaded).reduce((a, b) => a + b)

		this.progress = Math.round(
			(chunkLoadedProgress * 100) / this.blob.byteLength
		)
	}

	remove () {
		this.uploader.remove(this)
	}

	async createChunks () {
		const chunkSize = 20971520
		const chunks = Math.ceil(this.size / chunkSize)
		const blob = new Blob([this.blob])

		for (let i = 0; i < chunks; i++) {
			const slicedBlob = blob.slice(
				i * chunkSize, Math.min(i * chunkSize + chunkSize, this.size)
			)
			const slicedBlobData = await slicedBlob.arrayBuffer()

			this.chunks.push({
				blob: slicedBlobData,
				isLast: chunks - 1 === i,
				loaded: 0
			})
		}
	}
}

export { UploadableFile }
