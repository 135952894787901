<template>
	<base-wrapper
		v-if="input"
		:valid="input.isValidAndTouched"
		:error="error"
		:label="input.label"
		:name="input.name"
		:info="input.info"
		class="input-select treeview-wrapper"
	>
		<treeselect
			v-model="input.value"
			valueFormat="object"
			:multiple="true"
			:options="input.options"
			:flattenSearchResults="true"
		/>
	</base-wrapper>
</template>

<script>
import mixin from '@a/components/modules/inputs/organisms/input.mixin'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
	mixins: [mixin],
	components: {
		Treeselect
	}
}
</script>

<style lang="scss" scoped>
.treeview-wrapper {
	::v-deep .vue-treeselect {
		border: 1px solid #eaeaea;
		height: 45px;
		line-height: 2;
	}

	::v-deep .vue-treeselect--focused {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	::v-deep .vue-treeselect__menu {
		border: 1px solid #eaeaea;
	}

	::v-deep .vue-treeselect__control {
		height: 45px;
		border: none;
	}

	::v-deep .vue-treeselect__placeholder {
		font-size: 18px;
		top: 40%;
		transform: translateY(-50%) !important;
	}
	::v-deep .vue-treeselect__label {
		color: $black!important;
	}
}
</style>
