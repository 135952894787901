import { Page } from '@a/core/pages/Page'
import FileManager from '~/fileManager/admin/components/FileManager/FileManager'
import { checkFileManagerPermissions } from '~/authorization/admin/utils/checkPermissions'
import { Explorer } from '~/fileManager/admin/core/fileManager/Explorer'

class FileManagerPage extends Page {
	static alias = 'file-manager'
	static path = '/file-manager/:projectName'
	static component = FileManager
	static visible = checkFileManagerPermissions('project_owner', 'file_manager')

	explorer
	maxNumberOfFilesToChoose = 100

	get path () {
		return this.params.projectName === 'root' ? '' : `${this.params.projectName}`
	}

	get rootPath () {
		return this.path === '' ? '' : this.path.split('/')[0]
	}

	get downloadStatuses () {
		return this.explorer?.fileDownloadingStatuses
	}

	async afterRedirect () {
		this.explorer = await Explorer.create(this.app, this.maxNumberOfFilesToChoose, this.path, this.rootPath)
	}
}

export { FileManagerPage }
