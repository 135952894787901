import ColumnElementComponent from '../../components/modules/forms/ColumnElement'
import { WrapperElement } from './WrapperElement'
import { asyncForEach } from 'utils/async'

class ColumnElement extends WrapperElement {
	component = ColumnElementComponent

	constructor (gridColumns, attribute) {
		super(attribute)
		this.gridColumns = gridColumns
	}
}

const columnElementGenerator = app => async (object) => {
	return async () => {
		const columnElement = new ColumnElement({
			...object.grid
		}, object.attribute)
		await asyncForEach(object.elements, async el => columnElement.addElement(await app.createElement(el)))
		return columnElement
	}
}

export { ColumnElement, columnElementGenerator }
