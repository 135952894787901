<template>
	<base-wrapper
		:valid="input.isValidAndTouched"
		:error="error"
		:label="input.label"
		:name="input.name"
		:info="input.info"
		class="editor"
	>
		<base-editor
			:input="input"
			:rows="rows"
		/>
	</base-wrapper>
</template>

<script>
import mixin from './input.mixin'
import BaseEditor from '@/components/modules/inputs/atoms/BaseEditor'

export default {
	mixins: [mixin],
	props: {
		rows: String
	},
	components: {
		BaseEditor
	}
}
</script>

<style lang="scss" scoped>
	.editor {
		display: flex;
		flex-direction: column;
		font-size: $font-size-base;
	}
</style>
