
const PromptType = {
	ERROR: 0,
	SUCCESS: 1,
	INFO: 2
}

class Prompt {
	static create (question) {
		const prompt = Prompt.promptCreate({ question })
		prompt.open()
		return prompt
	}

	static promptCreate () {
		const instance = new this(...arguments)
		return instance
	}

	id
	question

	constructor ({ question }) {
		this.question = question
		this.application = window.app
	}

	open () {
		this.application.openPrompt(this)
	}

	close (value) {
		this.application.closePrompt()
		if (this.resolve) this.resolve(value)
	}

	getResult () {
		return new Promise(resolve => {
			this.resolve = resolve
		})
	}
}

export { Prompt, PromptType }
