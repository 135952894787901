class Language {
	name
	alias
	translations
	translator

	constructor ({ name, alias, translations, translator }) {
		this.name = name
		this.alias = alias
		this.translations = translations
		this.translator = translator
		this.translator.addLanguage(this)
	}

	get active () {
		return this.translator.active === this
	}

	change () {
		this.translator.active = this
	}
}

export default Language
