<template>
	<div>
		<explorer-view :explorer="page.explorer" />
		<download-status-item v-for="(status, index) in page.downloadStatuses" :key="index" :status="status" />
	</div>
</template>

<script>
import ExplorerView from '~/fileManager/admin/components/ExplorerView/ExplorerView'
import DownloadStatusItem from '~/fileManager/admin/components/DownloadStatusItem/DownloadStatusItem.vue'

export default {
	components: {
		DownloadStatusItem,
		ExplorerView
	},
	computed: {
		page () {
			return this.$app.router.page
		}
	}
}
</script>
