<template>
	<td ref="clickableTextColumn" class="clickable-text-column" @click.stop="unmark">{{ value }}</td>
</template>

<script>
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

export default {
	props: {
		value: String
	},
	data: () => ({
		tippyInstance: null
	}),
	methods: {
		unmark () {
			this.$emit('unmark')
		}
	},
	mounted () {
		this.tippyInstance = tippy(this.$refs.clickableTextColumn, {
			content: 'Odznacz jeśli nie usuwać',
			theme: 'light'
		})
	},
	destroyed () {
		this.tippyInstance.destroy()
	}
}
</script>

<style scoped lang="scss">
.clickable-text-column {
	&:hover {
		cursor: pointer;
	}
}
</style>
