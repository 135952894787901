import cookies from 'js-cookie'
import { getValue } from 'utils/objects'

class Translator {
	active
	languages = []

	addLanguage (language) {
		this.languages.push(language)
	}

	getLanguage (alias) {
		return this.languages.filter(language => language.alias === alias)[0]
	}

	getTranslation (field, defaultValue) {
		const ret = {}

		this.languages.map(language => {
			ret[language.alias] = getValue(field, language.translations, defaultValue)
		})

		return ret
	}

	setActive (alias) {
		const lang = this.getLanguage(alias)
		if (lang) {
			lang.change()
			cookies.set('lang', alias)
		}
	}

	restoreActive (defaultLang) {
		const lang = cookies.get('lang')
		if (lang) this.setActive(lang)
		else defaultLang.change()
	}
}

export default Translator
