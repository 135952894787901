<template>
	<div class="uploader-list-item">
		<div class="file-name">
			{{ decodeURI(file.name) }}
		</div>
		<div ref="progressBar" class="progress-bar-wrapper">
			<progress id="progress-bar" class="bar" :value="file.progress" max="100"></progress>
			<label for="progress-bar" class="label">{{ file.progress }} %</label>
		</div>
		<div class="file-actions">
			<base-button class="remove-btn" @click="remove" >
				<base-svg name="close" class="icon"></base-svg>
			</base-button>
		</div>
	</div>
</template>

<script>
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import BaseButton from '@a/components/atoms/BaseButton/BaseButton'
import BaseSvg from '@a/components/atoms/BaseSvg/BaseSvg'

export default {
	components: {
		BaseButton,
		BaseSvg
	},
	props: {
		file: Object
	},
	data: () => ({
		tippyInstance: null
	}),
	methods: {
		remove () {
			this.file.remove()
		}
	},
	mounted () {
		this.tippyInstance = tippy(this.$refs.progressBar, {
			content: 'Plik wysłany',
			theme: 'light'
		})
	},
	destroyed () {
		this.tippyInstance.destroy()
	}
}
</script>

<style scoped lang="scss">
.uploader-list-item {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
	padding: 1rem;
	margin: 1rem;
	background: $white;
	border: 1px solid #ACACAC;
	border-radius: 8px;

	@include media-breakpoint-up(lg) {
		flex-direction: row;
	}

	.file-name {
		word-break: break-all;
		align-self: flex-start;

		@include media-breakpoint-up(lg) {
			align-self: auto;
			word-break: normal;
		}
	}
	.file-actions {
		align-self: flex-end;

		.remove-btn {
			padding: 0;
		}
	}

	.progress-bar-wrapper {
		display: flex;
		align-items: center;
		width: 100%;
		gap: 1rem;
		margin-left: auto;

		@include media-breakpoint-up(lg) {
			width: 300px;
		}

		.label {
			flex: 1 0 0;
		}
		.bar {
			flex: 6 0 0;
		}

		progress {
			width: inherit;
		}
	}
}

.remove-btn {
	background: transparent;
	border: none;

	.icon {
		width: 25px;
		height: 25px;
	}
}

</style>
