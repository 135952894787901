import { Form } from './Form'
import ButtonSubmitComponent from '@a/components/modules/forms/ButtonSubmitElement'
import { Element } from './Element'

class ButtonSubmit extends Element {
	component = ButtonSubmitComponent
	label
	right

	constructor ({ label, right = false }) {
		super()
		this.label = label
		this.right = right
	}

	create (parent) {
		this.parent = parent
		this.form = this.getFormFromParent(parent)
	}

	getFormFromParent (element) {
		if (!element) throw new Error('SUBMIT_BUTTON_DONT_HAVE_FORM_PARENT')

		if (element instanceof Form) return element
		return this.getFormFromParent(element.parent)
	}

	get processing () {
		return this.form.processing
	}

	async submit () {
		if (!await this.form.validate()) return
		this.form.submit()
	}
}

const buttonSubmitGenerator = app => async (object) => {
	return async () => {
		const button = new ButtonSubmit(object)

		return button
	}
}

export { ButtonSubmit, buttonSubmitGenerator }
