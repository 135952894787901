import { Input } from '@a/core/elements/inputs/Input'
import InputTreeViewTempalte from '@a/components/modules/inputs/organisms/InputTreeView'
import { objectCopyByJSON, getValue, setValue } from 'utils/objects'

class InputTreeView extends Input {
	component = InputTreeViewTempalte
	options = []
	categoriesWithProducts

	constructor ({ ...data }) {
		super(data)
	}

	getValue () {
		if (!this.attribute) return this.getIdsBySelectedOptions()
		const value = {}
		setValue(this.attribute, value, this.getIdsBySelectedOptions())
		return value
	}

	async create (parent) {
		await super.create(parent)
		await this.getCategoriesWithProducts()
		await this.prepareOptions()
	}

	setInitialValue (values) {
		let value = values
		if (this.attribute) value = getValue(this.attribute, values, [])

		if (value !== undefined) this.value = this.getOptionsByInitialData(value)
		else this.clear()
	}

	prepareOptions () {
		const notReactiveCategories = objectCopyByJSON(this.categoriesWithProducts)
		const options = notReactiveCategories.map(category => this.mapCategoryToOption(category))
		this.options = options
	}

	async validate () {
		if (this.disable) {
			this.error = null
			return true
		}
		for (const rule of this.validation) {
			const isValid = this.value.length !== 0

			if (!isValid) {
				this.error = rule.message()
				return false
			}
		}
		this.error = null
		return true
	}

	getOptionsByInitialData (ids) {
		const options = []

		this.options.forEach(option => {
			const optionChildren = []
			option.children.forEach(child => {
				const childInInitialValue = ids.find(id => id === child.id)
				if (childInInitialValue) {
					optionChildren.push(child)
				}
			})
			if (optionChildren.length === option.children.length) {
				options.push(option)
			} else {
				options.push(...optionChildren)
			}
		})
		return options
	}

	getIdsBySelectedOptions () {
		const ids = []

		this.value.forEach(option => {
			if (typeof option.children !== 'undefined') {
				option.children.forEach(child => {
					ids.push(child.id)
				})
			} else {
				ids.push(option.id)
			}
		})

		return ids
	}

	mapCategoryToOption (category) {
		return {
			id: category.id,
			label: category.name,
			children: category.products.map(product => ({
				label: product.name.pl,
				id: product.id
			}))
		}
	}

	async getCategoriesWithProducts () {
		const Entity = window.app.getEntity('product-category')
		const categoriesWithProducts = await Entity.list({
			relations: ['products']
		})
		this.categoriesWithProducts = categoriesWithProducts
	}
}

const inputTreeViewGenerator = app => async (object) => {
	return async () => {
		const inputTreeView = new InputTreeView({ ...object })
		return inputTreeView
	}
}

export { InputTreeView, inputTreeViewGenerator }
