import { required } from 'utils/inputs/validation'

function generalFormSchema () {
	return [{
		$type: 'tab',
		tabName: 'tab-authorization',
		attribute: 'authorization.remindPasswordTokenExpirationTime',
		elements: [
			{
				$type: 'column',
				grid: {
					col: 12,
					lg: 6
				},
				elements: [
					{
						$type: 'select_input',
						attribute: 'unit',
						name: 'unit',
						label: 'entities.setting.pages.edit.authorization.tokenExpiration.unit',
						options: [
							{ id: 'day', name: 'entities.setting.pages.edit.expirationUnits.day' },
							{ id: 'week', name: 'entities.setting.pages.edit.expirationUnits.week' },
							{ id: 'month', name: 'entities.setting.pages.edit.expirationUnits.month' }
						],
						validation: [required()]
					},
					{
						$type: 'number_input',
						attribute: 'value',
						name: 'value',
						label: 'entities.setting.pages.edit.authorization.tokenExpiration.value',
						validation: [required()]
					}
				]
			}
		]
	},
	{
		$type: 'tab',
		tabName: 'tab-files',
		attribute: 'files',
		elements: [
			{
				$type: 'column',
				grid: {
					col: 12,
					lg: 6
				},
				elements: [
					{
						$type: 'text_input',
						attribute: 'fileManagerFileSize',
						name: 'fileManagerFileSize',
						label: 'entities.setting.pages.edit.files.fileManagerFileSize',
						validation: [required()]
					},
					{
						$type: 'select_input',
						attribute: 'unit',
						name: 'unit',
						label: 'entities.setting.pages.edit.files.unit',
						options: [
							{ id: 'KB', name: 'entities.setting.pages.edit.files.units.KB' },
							{ id: 'MB', name: 'entities.setting.pages.edit.files.units.MB' },
							{ id: 'GB', name: 'entities.setting.pages.edit.files.units.GB' }
						],
						validation: [required()]
					}
				]
			}
		]
	}]
}

export { generalFormSchema }
