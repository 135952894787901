<template>
	<div>
		<div class="header-wrapper">
			<base-font variant="page-header">{{l.pages.sign_in.title}}</base-font>
		</div>
		<base-element :element="page.inputEmail" />
		<base-element :element="page.inputPassword" />
		<div class="wrapper">
			<button-submit @click="page.signIn()" :loading="page.processing">{{l.pages.sign_in.sign_in}}</button-submit>
			<base-button outline @click="page.remindPasswordRedirect()">{{l.pages.sign_in.remind_password}}</base-button>
		</div>
	</div>
</template>

<script>
import BaseElement from '@a/components/atoms/BaseElement'
import ButtonSubmit from '@a/components/molecules/ButtonSubmit'
import BaseButton from '@a/components/atoms/BaseButton'
import BaseFont from '@a/components/atoms/BaseFont'

export default {
	components: {
		BaseElement,
		ButtonSubmit,
		BaseButton,
		BaseFont
	},
	computed: {
		page () {
			return this.$app.router.page
		}
	}
}
</script>
<style lang="scss" scoped>
.wrapper {
	display: flex;
	justify-content: space-between;
}
</style>
