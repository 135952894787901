import { getQuery } from 'utils/query'
class ReadEntity {
	constructor (data) {
		this.setData(data)
	}

	static get pluralName () {
		return this.name + 's'
	}

	setData (data) {
		Object.entries(data).map(([key, value]) => {
			this[key] = value
		})
	}

	getName () {
		if (this.name) return this.name
		return 'no-name'
	}

	static getQuery (object) {
		return getQuery(object)
	}

	static async list (params) {
		const { data } = await window.app
			.getService('rext')
			.http
			.get(`/${this.alias}` + this.getQuery(params))

		return data.map(d => new this(d))
	}

	static async count (params) {
		const { data } = await window.app
			.getService('rext')
			.http
			.get(`/${this.alias}/count` + this.getQuery(params))
		return data
	}

	static async getSingle (id, params) {
		const { data } = await window.app
			.getService('rext')
			.http
			.get(`/${this.alias}/${id}` + this.getQuery(params))

		return new this(data)
	}
}

export { ReadEntity }
