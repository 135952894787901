import { ListElement } from './ListElement'
import { asyncForEach } from 'utils/async'

class EntityListElement extends ListElement {
	constructor ({ Entity, relations, where, orderby, take, pagination = true, page }, init = true) {
		super({ pagination }, false)
		this.Entity = Entity
		this.relations = relations
		this.where = where
		this._take = take
		this.countData()
		this.initOrderby = orderby
		if (page) this.page = page

		if (init) this.initReactive()
	}

	async create () {
		await super.create(...arguments)
		if (this.initOrderby) this.order = this.initOrderby

		this.fetch()
	}

	async countData () {
		const { count } = await this.Entity.count({
			where: this.where,
			relations: this.relations
		})
		this.dataLength = count
		this.maxPage = Math.ceil(count / this.perPage)
	}

	async fetch () {
		const data = await this.Entity.list({
			relations: this.relations,
			order: this.order,
			where: this.where,
			take: this._take ? this._take : this.take,
			skip: this.skip
		})
		this.data = data
	}

	onOrderChanged () {
		this.fetch()
	}

	set data (value) {
		this._data = value
	}

	get data () {
		return this._data
	}

	set page (number) {
		this._page = number
		this.inputPageNumber.value = this._page
		this._emit('page:changed')
		this.fetch()
		return this._page
	}

	get page () {
		return this._page
	}

	get order () {
		const order = {}
		this.columns
			.filter(column => column.sortMode)
			.forEach(column => {
				if (column.order) {
					order[column.sortParam] = column.order
				}
			})
		return order
	}

	set order (order) {
		this.columns
			.filter(column => column.sortMode)
			.forEach(column => {
				if (order[column.sortParam]) {
					column.order = order[column.sortParam]
				}
			})
	}
}

const entityListElementGenerator = app => async (object) => {
	return async () => {
		const list = new EntityListElement({
			Entity: object.Entity,
			relations: object.relations,
			where: object.where,
			orderby: object.orderby,
			take: object.take,
			pagination: object.pagination,
			page: object.page
		})
		await asyncForEach(object.columns, async column => await list.addColumn(column))
		return list
	}
}

export {
	EntityListElement,
	entityListElementGenerator
}
