import { Input } from './Input'
import InputPasswordTemplate from '@a/components/modules/inputs/organisms/InputPassword.vue'
import { ReactiveClass } from '@a/core/ReactiveClass'

class Rule extends ReactiveClass {
	text = ''
	validation = null
	_valid = null
	_input = null

	constructor ({ text, validation }) {
		super()
		this.text = window.app.translator.getTranslation(text)
		this.validation = validation
	}

	set input (input) {
		input.on('value:changed', this.valueChangedHandler.bind(this))
		this._input = input
	}

	get isValid () {
		return this._valid === true
	}

	get isValidAndTouched () {
		return this.isValid && this._input.isTouched
	}

	get isInvalid () {
		return this._valid === false
	}

	get isInvalidAndTouched () {
		return this.isInvalid && this._input.isTouched
	}

	async valueChangedHandler () {
		this.validate()
	}

	async validate () {
		if (!this._input._value) {
			this._valid = false
			return false
		}
		this._valid = await this.validation(this._input._value)
		return this._valid
	}
}

class InputPasswordShowStatus {
	static SHOW = 0
	static HIDE = 1
}

class InputPassword extends Input {
	component = InputPasswordTemplate
	rules = []
	_showStatus = InputPasswordShowStatus.HIDE

	constructor ({ rules, ...data }) {
		super(data)
		rules.map(rule => {
			rule.input = this
			this.rules.push(rule)
		})
	}

	get isHidden () {
		return this._showStatus === InputPasswordShowStatus.HIDE
	}

	show () {
		this._showStatus = InputPasswordShowStatus.SHOW
	}

	hide () {
		this._showStatus = InputPasswordShowStatus.HIDE
	}

	async validate () {
		await super.validate()

		await Promise.all(this.rules.map(rule => rule.validate()))

		for (const rule of this.rules) {
			const valid = rule.isValid
			if (!valid) {
				this.error = true
				return false
			}
		}

		return !this.error
	}
}

const inputPasswordGenerator = app => async (object) => {
	return async () => {
		const inputPassword = new InputPassword({
			...object
		})
		return inputPassword
	}
}

export { InputPassword, Rule, inputPasswordGenerator }
