<template>
	<div class="uploader-list-wrapper" v-if="files.length">
		<div class="clear-btn-wrapper">
			<base-button class="clear-area-btn" @click="$emit('clearUploader')">
				<span>{{ lang.clear_upload_area }}</span>
			</base-button>
		</div>
		<div class="uploader-list">
			<uploader-list-item v-for="file in files" :key="file.name" :file="file" />
		</div>
	</div>
</template>

<script>
import UploaderListItem from '~/fileManager/admin/components/UplaoderListItem/UploaderListItem'
import BaseButton from '@a/components/atoms/BaseButton/BaseButton'

export default {
	components: {
		UploaderListItem,
		BaseButton
	},
	props: {
		files: Array
	},
	computed: {
		lang () {
			return this.$app.translator.active.translations['file-manager']
		}
	}
}
</script>

<style scoped lang="scss">
.uploader-list {
	max-height: 380px;
	width: 100%;
	overflow-y: auto;
	border-bottom: 1px solid #ACACAC;

	@include media-breakpoint-up(xl) {
		padding: 2rem;
	}
}
.clear-btn-wrapper {
	display: flex;
	justify-content: flex-end;
	margin-top: 1rem;
	margin-right: 1rem;
}
</style>
