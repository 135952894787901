import BaseHeaderColumn from '@a/components/atoms/BaseHeaderColumn'
import { ReactiveClass } from '@a/core/ReactiveClass'

class Column extends ReactiveClass {
	name
	component
	list
	headerComponent = BaseHeaderColumn
	order = false
	sortMode
	sortParam
	headerText

	constructor ({ name, sortParam, headerText }, list) {
		super()
		this.name = name
		this.headerText = headerText
		this.sortMode = !!sortParam
		this.sortParam = sortParam
		this.list = list
	}

	changeOrder () {
		if (!this.sortMode) return false
		if (this.order === 'ASC') this.order = 'DESC'
		else if (this.order === 'DESC') this.order = false
		else this.order = 'ASC'

		this._emit('order:changed')
	}
}

export { Column }
