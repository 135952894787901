<template>
	<div class="create-directory-form">
		<base-element class="directory-name-input" :element="this.directoryNameInput" />
		<base-button class="align-button btn-directory-add" @click="create">{{lang.add_folder}}</base-button>
	</div>
</template>

<script>
import mixin from '@a/components/modules/inputs/organisms/input.mixin'
import BaseElement from '@a/components/atoms/BaseElement/BaseElement'
import BaseButton from '@a/components/atoms/BaseButton/BaseButton'
import { InputText } from '@a/core/elements/inputs/InputText'
import { required } from 'utils/inputs/validation'

export default {
	mixins: [mixin],
	components: {
		BaseElement,
		BaseButton
	},
	props: {
		explorer: Object
	},
	computed: {
		lang () {
			return this.$app.translator.active.translations['file-manager']
		}
	},
	data: () => ({
		directoryNameInput: {}
	}),
	methods: {
		async create () {
			console.log(this.directoryNameInput.value.match(/(\S.+\S)/)[0])
			await this.directoryNameInput.touchAndValidate()
			if (!this.directoryNameInput.isValid) return
			await this.explorer.createDirectory(this.directoryNameInput.value.match(/(\S.+\S)/)[0])
			this.directoryNameInput.clear()
		}
	},
	mounted () {
		this.directoryNameInput = new InputText({
			name: 'locationInput',
			label: 'inputs.label.directory_name',
			validation: [required()]
		})
	}
}
</script>

<style scoped lang="scss">
.create-directory-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2rem;
	border: 1px solid #ACACAC;
	position: relative;
	top: -1px;
	min-height: 144px;
	width: inherit;

	@include media-breakpoint-up(lg) {
		flex-direction: row;
		gap: 2rem;
	}

	.directory-name-input {
		width: 300px;
	}
	.btn-directory-add {
		position: relative;
		top: 10px;
		height: 45px;
	}
}
</style>
