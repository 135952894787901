<template>
	<base-wrapper
		:valid="input.isValidAndTouched"
        :error="error"
        :label="name"
        :name="input.name"
		:info="input.info"
		class="input-text"
	>
		<base-input
			:input="input"
			:type="input.type"
		/>
	</base-wrapper>
</template>

<script>
import mixin from './input.mixin'
import BaseInput from '@a/components/modules/inputs/atoms/BaseInputWithoutMask'

export default {
	mixins: [mixin],
	components: {
		BaseInput
	}
}
</script>
