<template>
	<div class="select-option" @click="click">
		{{name}}
	</div>
</template>

<script>
export default {
	props: {
		option: Object
	},
	computed: {
		name () {
			if (typeof this.option.name === 'string') return this.option.name
			return this.option.name[this.$app.translator.active.alias]
		}
	},
	methods: {
		click () {
			if (!this.option.isChoosed) this.option.choose()
		}
	}
}
</script>

<style lang="scss" scoped>
	.select-option {
		cursor: pointer;
		opacity: 0;
		pointer-events: none;
		margin-top: 10px;
		height: 25px;
		overflow: hidden;
		@include transition;
		&:first-child {
			opacity: 1;
			margin-top: 0;
		}
	}
</style>
