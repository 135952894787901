<template>
	<div class="explorer-wrapper" v-if="explorer">
		<explorer-navbar :explorer="explorer" />
		<div class="table-wrapper" :class="{ 'drag-message-wrapper': isDraggedOver }" @dragover="dragOverHandler" @dragleave="dragLeaveHandler">
			<div>
				<table v-if="!isDraggedOver">
					<explorer-view-header :explorer="explorer"  />
					<tbody>
						<list-item-view v-for="listItem in explorer.sortedItems" :key="listItem.item.path"
									:listItem="listItem" />
					</tbody>
				</table>
				<base-font class="drag-message" variant="page-header" v-else>
					{{ lang.not_allowed_drag }}
				</base-font>
			</div>
		</div>
	</div>
</template>

<script>
import ListItemView from '~/fileManager/admin/components/ListItemView/ListItemView'
import ExplorerNavbar from '~/fileManager/admin/components/ExplorerNavbar/ExplorerNavbar'
import ExplorerViewHeader from '~/fileManager/admin/components/ExplorerViewHeader/ExplorerViewHeader'
import BaseFont from '@a/components/atoms/BaseFont/BaseFont'

export default {
	components: {
		ListItemView,
		ExplorerNavbar,
		ExplorerViewHeader,
		BaseFont
	},
	props: {
		explorer: Object
	},
	computed: {
		lang () {
			return this.$app.translator.active.translations['file-manager']
		}
	},
	data: () => ({
		isDraggedOver: false
	}),
	methods: {
		dragOverHandler () {
			this.isDraggedOver = true
		},
		dragLeaveHandler () {
			this.isDraggedOver = false
		}
	}
}
</script>

<style scoped lang="scss">
.table-wrapper {
	margin-top: 2.5rem;
	overflow: auto;
	height: 600px;
	border: 1px solid #ACACAC;
	background: $white;

	table {
		width: 100%;
		box-sizing: content-box;
		border-collapse: collapse;
		font-size: 1.3rem;

		tbody {
			position: relative;
			border-radius: 10px;
			tr {
				background-color: $white;
				cursor: pointer;
				@include transition;
				@include media-breakpoint-up (xl) {
					&:hover {
						background: $grey-400;
					}
				}
				td {
					padding: 10px 20px;
					width: 130px;
					&.birthtime {
						width: 170px;
					}
					&.name {
						width: 185px;
					}
				}
			}
		}
	}
}
.drag-message-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;

	* {
		pointer-events: none;
	}
}
</style>
