import { Input } from './Input'
import { ReactiveClass } from '@/core/ReactiveClass'
import InputManySearchSelectTemplate from '@a/components/modules/inputs/organisms/InputManySearchSelect.vue'
import { getValue } from 'utils/objects'
class Option extends ReactiveClass {
	id = null
	name = ''

	constructor ({ id, name }) {
		super()
		this.id = id
		this.name = this.getTranslationName(name)
	}

	getTranslationName (name) {
		const translation = window.app.translator.getTranslation(name)
		const languageAlias = window.app.translator.active.alias
		return translation[languageAlias] || name
	}

	get isChoosed () {
		return !!~this.select.choosedOptions.indexOf(this)
	}

	choose () {
		this.select.choose(this)
	}

	remove () {
		this.select.remove(this)
	}
}

class InputManySearchSelect extends Input {
	component = InputManySearchSelectTemplate
	options = []
	choosedOptions = []
	_open = false
	ready = false

	constructor ({ options, value, ...data }) {
		super(data)
		if (options) this.setOptions(options)
		this.default = value
		if (value) this.value = value

		this.closeHandler = this.close.bind(this)
	}

	get value () {
		return this.choosedOptions.map(option => option.id)
	}

	set value (ids) {
		if (!ids) return 0
		const idsFlatArrat = ids.map(element => {
			if (typeof element === 'object' && element.id) return element.id
			return element
		})

		if (!this.ready) {
			this._temporaryValue = ids
			return 0
		}
		const options = this.options.filter(option => !!~idsFlatArrat.indexOf(option.id))
		this.choosedOptions = options
	}

	get isOpen () {
		return this._open
	}

	get isClose () {
		return !this._open
	}

	choose (option) {
		this.choosedOptions.push(option)
	}

	remove (option) {
		this.choosedOptions = this.choosedOptions.filter(o => o !== option)
	}

	close () {
		this._open = false
		document.removeEventListener('click', this.closeHandler)
	}

	open () {
		this._open = true
		document.addEventListener('click', this.closeHandler)
	}

	clear () {
		this.value = this.default
	}

	setOptions (data) {
		const options = this.dataToOptionObject(data)
		options.map(option => {
			option.select = this
			this.options.push(option)
		})
		this.ready = true
		let choosedOptions = []

		if (this._temporaryValue) choosedOptions = options.filter(option => !!~this._temporaryValue.indexOf(option.id))

		this.choosedOptions = choosedOptions
	}

	dataToOptionObject (data) {
		return data.map(object => {
			if (object instanceof Option) return object
			return new Option(object)
		})
	}

	getValue () {
		if (!this.attribute) return this.value
		return {
			[this.attribute]: this.value
		}
	}

	setInitialValue (values) {
		let value = values
		if (this.attribute) value = getValue(this.attribute, values, [])

		if (value !== undefined) this.value = value
		else this.clear()
	}
}

const entityManyInputGenerator = app => async (object) => {
	return async () => {
		const input = new InputManySearchSelect({
			...object
		})
		const objects = await object.entity.list()
		const options = objects.map(object => new Option({
			id: object.id,
			name: object.getName()
		}))
		input.setOptions(options)
		return input
	}
}

const inputManySearchSelectGenerator = app => async (object) => {
	return async () => {
		const input = new InputManySearchSelect({
			...object
		})
		return input
	}
}

export { InputManySearchSelect, Option, entityManyInputGenerator, inputManySearchSelectGenerator }
