<template>
	<div class="seo">
		<base-element :element="modal.altInput" />
		<base-element :element="modal.titleInput" />
		<base-button class="sumbit-btn" @click="modal.setAltAndTitle()">
			{{lang.seo_confirm}}
		</base-button>
	</div>
</template>

<script>
import BaseElement from '@a/components/atoms/BaseElement/BaseElement'
import BaseButton from '@a/components/atoms/BaseButton/BaseButton'

export default {
	components: {
		BaseElement,
		BaseButton
	},
	props: ['modal'],
	computed: {
		lang () {
			return this.$app.translator.active.translations['file-manager']
		}
	}
}
</script>

<style scoped lang="scss">
.seo {
	min-width: 500px;
}
</style>
