import { WrapperElement } from './WrapperElement'
import { asyncForEach } from 'utils/async'

class TabElement extends WrapperElement {
	constructor ({ title, tabName, attribute }) {
		super(attribute)
		this.tabName = tabName
		this.tabTitle = title || window.app.translator.active.translations.elements.tabs[tabName]
	}

	get active () {
		return this.tabWrapper.activeTab === this
	}

	setTabWrapper (tabWrapper) {
		this.tabWrapper = tabWrapper
	}

	choose () {
		this.tabWrapper.setTabActive(this)
	}
}

const tabElementGenerator = app => async (object) => {
	return async () => {
		const tabElement = new TabElement(object)
		await asyncForEach(object.elements, async el => tabElement.addElement(await app.createElement(el)))
		return tabElement
	}
}

export { TabElement, tabElementGenerator }
