import { asyncForEach, asyncMap } from 'utils/async'
import { Element } from './Element'
import { TabWrapperElement } from './TabWrapperElement'
import { getValue, setValue } from 'utils/objects'
import merge from 'lodash.merge'
class WrapperElement extends Element {
	elementGenerators = []
	elements = []
	attribute

	constructor (attribute) {
		super()
		this.attribute = attribute
	}

	addElement (element) {
		this.elementGenerators.push(element)

		return this
	}

	async create (parent) {
		this.parent = parent
		await asyncForEach(this.elementGenerators, async generator => {
			const element = await generator()
			await element.create(this)
			this.elements.push(element)
		})
		this.initReactive()
		return this
	}

	async getValue () {
		const values = await asyncMap(this.elements, element => element.getValue())
		const merged = merge(...values)
		if (!this.attribute) return merged
		const value = {}
		setValue(this.attribute, value, merged)
		return value
	}

	async validate () {
		const elementToValidate = this.elements.filter(element => element.touchAndValidate)
		const results = await asyncMap(elementToValidate, async element => await element.touchAndValidate())
		if (results.includes(false)) return false
		return true
	}

	touchAndValidate () {
		return this.validate()
	}

	async setInitialValue (values) {
		if (this.attribute) values = getValue(this.attribute, values)
		await asyncForEach(this.elements, element => element.setInitialValue(values))
	}

	findElements (Instance) {
		return this.elements.reduce((acc, element) => {
			if (element instanceof Instance) acc.push(element)
			if (element instanceof WrapperElement) acc.push(...element.findElements(Instance))
			if (element instanceof TabWrapperElement) acc.push(...this.findElements.call(element, Instance))
			return acc
		}, [])
	}

	clear () {
		this.elements = []
	}
}

export { WrapperElement }
