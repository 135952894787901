<template>
	<div class="table-outer-wrapper">
		<div class="table-wrapper">
			<table v-if="list.data.length" class="table">
				<thead>
					<header-row-list-page :alias="alias" :columns="list.columns" />
				</thead>
				<tbody >
					<component v-for="object in list.data" :key="object.id" :is="list.rowComponent" :object="object" :columns="list.columns"/>
				</tbody>
			</table>
			<div v-else class="empty-list-info">
				<base-font class="page-counter" size="sm" color="black">
					{{l.entities[alias].pages.list.empty_list_info}}
				</base-font>
			</div>
		</div>
		<div v-if="list.pagination" class="bottom">
			<button-wrapper right>
				<base-button v-if="!list.isFirstPage" @click="list.previousPage()">
					&laquo;
				</base-button>
				<base-input v-if="list.inputPageNumber" :input="list.inputPageNumber" type="text" />
				<base-font class="page-counter" color="black">
					{{lang.inputs.label.from}} {{list.maxPage}}
				</base-font>
				<base-button v-if="!list.isLastPage" @click="list.nextPage()">
					&raquo;
				</base-button>
			</button-wrapper>
		</div>
	</div>
</template>

<script>
import { pageMixin } from '@a/pages/page.mixin'
import HeaderRowListPage from './HeaderRowListPage'
import BaseInput from '@a/components/modules/inputs/atoms/BaseInput.vue'
import ButtonSubmit from '@a/components/molecules/ButtonSubmit'
import ButtonWrapper from '@a/components/atoms/ButtonWrapper/ButtonWrapper.vue'
import BaseButton from '@a/components/atoms/BaseButton'
import BaseSvg from '@a/components/atoms/BaseSvg'
import BaseFont from '@a/components/atoms/BaseFont'

export default {
	components: {
		HeaderRowListPage,
		BaseButton,
		ButtonWrapper,
		ButtonSubmit,
		BaseInput,
		BaseSvg,
		BaseFont
	},
	props: {
		element: Object
	},
	computed: {
		list () {
			return this.element
		},
		lang () {
			return this.$app.translator.active.translations
		}
	},
	mixins: [
		pageMixin
	]
}
</script>

<style lang="scss" scoped>
.empty-list-info {
	font-weight: bold;
	margin-top: 2rem;
	text-align: center;
}
.table-wrapper {
	display: block;
    width: 100%;
    overflow-x: auto;
	padding-bottom: 2rem;
}
.table {
	width: 100%;
	box-sizing: content-box;
	border-collapse: collapse;
	margin: 10px 0;
	font-size: 1.3rem;
	tbody {
		position: relative;
		border-radius: 10px;
		box-shadow: 0 0 0 2px $grey-400;
		tr {
			border-radius: 10px;
			box-shadow: 0 0 0 1px $white;
			background-color: $white;
		}
	}
}
.button-wrapper {
	button {
		display: flex;
		align-items: center;
		font-size: 1.3rem;
		text-transform: uppercase;
	}
	svg {
		width: 13px;
		margin-right: 1rem;
	}
}
.bottom {
	display: flex;
	input {
		width: 40px;
		margin-left: 10px;
		padding: 1rem;
		text-align: center;
	}
	span {
		margin-left: 6px;
	}
}
::v-deep .page-counter {
	display: block;
	margin-left: 5px;
}
</style>
