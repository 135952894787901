import { required } from 'utils/inputs/validation'
import { Language } from '~/authorization/admin/entities/Language'

const moderatorFormSchema = async (app) => {
	const getAllProjects = async () => {
		const Entity = app.getEntity('project')
		const projects = await Entity.list({
			take: 1000
		})
		return projects
	}

	return [
		{
			$type: 'column',
			grid: {
				col: 12,
				lg: 6
			},
			elements: [
				{
					$type: 'text_input',
					attribute: 'user.email',
					name: 'email',
					label: 'entities.moderator.pages.form.email',
					validation: [required()]
				},
				{
					$type: 'text_input',
					attribute: 'name',
					name: 'name',
					label: 'entities.moderator.pages.form.name'
				},
				{
					$type: 'many_select_input',
					attribute: 'projects',
					label: 'entities.moderator.pages.form.projects',
					options: await getAllProjects()
				}
			]
		},
		{
			$type: 'column',
			grid: {
				col: 12,
				lg: 6
			},
			elements: [
				{
					$type: 'entity_input',
					attribute: 'user.userGroup',
					name: 'userGroup',
					label: 'entities.moderator.pages.form.user_group',
					entity: app.getEntity('user-group')
				},
				{
					$type: 'entity_input',
					attribute: 'language',
					name: 'language',
					label: 'entities.moderator.pages.form.language',
					entity: Language
				}
			]
		}
	]
}

export { moderatorFormSchema }
