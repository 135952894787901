<template>
	<div class="button-submit">
		<base-button @click="click" :disabled="disabled"><slot /></base-button>
		<base-svg v-if="loading" name="autorenew" />
	</div>
</template>

<script>
import BaseSvg from '@a/components/atoms/BaseSvg'
import BaseButton from '../../atoms/BaseButton'

export default {
	props: {
		loading: Boolean,
		disabled: Boolean
	},
	components: {
		BaseButton,
		BaseSvg
	},
	methods: {
		click () {
			if (!this.loading) this.$emit('click')
		}
	}
}
</script>

<style lang="scss" scoped>
.button-submit {
	display: flex;
	align-items: center;
	padding-bottom: 1rem;
	svg {
		animation: rotate infinite 1s linear;
		fill: $primary;
		margin-left: 5px;
		width: 15px;
	}

	@keyframes rotate {
		from {transform: rotate(0);}
		to {transform: rotate(360deg);}
	}
}
</style>
