<template>
	<td class="cell">
		<div class="actions">
			<span v-if="column.showView" @click="column.view(object)" class="icon-wrapper">
				<base-svg name="visibility"/>
				{{l.entities[alias].pages.list.view_button}}
			</span>
			<span v-if="column.showEdit" @click="column.edit(object)" class="icon-wrapper">
				<base-svg name="pencil"/>
				{{l.entities[alias].pages.list.edit_button}}
			</span>
			<span v-if="column.showRemove" @click="column.remove(object)" class="icon-wrapper">
				<base-svg name="closeIconList"/>
				{{l.entities[alias].pages.list.delete_button}}
			</span>
		</div>
	</td>
</template>

<script>
import { pageMixin } from '@a/pages/page.mixin'
import BaseSvg from '@a/components/atoms/BaseSvg'

export default {
	components: {
		BaseSvg
	},
	props: {
		column: Object,
		object: Object
	},
	mixins: [
		pageMixin
	]
}
</script>

<style lang="scss" scoped>
.cell {
	width: 0%;
}
.actions {
	display: flex;
	padding-right: 2rem;
}
.icon-wrapper {
	display: inline-flex;
	text-transform: uppercase;
	cursor: pointer;
	align-items: center;
	&:first-child {
		margin-right: 3rem;
	}
	&:hover svg {
		opacity: 0.6;
	}
}
svg {
	height: 18px;
	margin-right: .5rem;
	transition: $transition;
}
</style>
