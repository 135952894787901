<template>
	<div class="layout">
		<div class="card">
			<base-svg class="logo" name="logo" />
			<slot />
		</div>
	</div>
</template>
<script>
import BaseSvg from '@a/components/atoms/BaseSvg/BaseSvg'

export default {
	components: {
		BaseSvg
	}
}
</script>
<style lang="scss" scoped>
.layout {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: $bg-gradient;
}
.card {
	background: $grey-400;
	width: 310px;
	padding: 3rem 3rem 1rem 3rem;
	border: 1px solid black;
	border-radius: 5px;
	box-shadow: 4px 3px 10px rgba(#000000, .2);
	@include media-breakpoint-up(md) {
		width: 360px;
	}
	.logo {
		max-width: 100%;
	}
}
.languages-wrapper {
	display: flex;
	justify-content: center;
	margin-top: 1rem;
	& .language {
		position: relative;
		border-radius: .5rem;
		cursor: pointer;
		padding: .5rem;
		@include transition;
		&.active {
			font-weight: bold;
		}
		&::before {
			content: '';
			position: absolute;
			top: 5px;
			width: 1px;
			background-color: black;
			height: 15px;
			right: 0px;
		}
		&:last-child {
			&::before {
				content: none;
			}
		}
	}
}
</style>
