import { EntityEditFormPage } from '@a/core/pages/entity/edit/EntityEditFormPage'
import { checkPermissions } from '~/authorization/admin/utils/checkPermissions'

class SettingEditPage extends EntityEditFormPage {
	static entity = 'setting'
	static types = {}
	static visible = checkPermissions('settings_management')

	static get path () {
		return `/edit/${this.entity}/:module`
	}

	getFullSchema (settingsSchema) {
		return {
			$type: 'form',
			elements: [
				{
					$type: 'tab_wrapper',
					name: 'tab',
					elements: [
						...settingsSchema()
					]
				},
				{
					$type: 'column',
					grid: {
						col: 12
					},
					elements: [
						{
							$type: 'button_submit',
							right: true,
							label: 'entities.setting.pages.edit.button'
						}
					]
				}
			]
		}
	}

	getFormSchema () {
		const specificSettingsSchema = SettingEditPage.types[this.params.module]
		return this.getFullSchema(specificSettingsSchema)
	}

	async afterRedirect () {
		this.object = await this.Entity.findByAlias(this.params.module)
		this.form = await this.getForm()
		await this.setFormData()
		this.form.onSubmit(this.save.bind(this))
		this.ready = true
	}

	async setFormData () {
		await this.form.create()
		await this.form.setInitialValues(this.object.data)
	}

	async save () {
		const values = await this.form.getValue()
		const { success, data } = await this.object.edit({
			...this.object.data,
			...values
		})

		this.notify(success, data)
	}
}

export { SettingEditPage }
