import DefaultLayoutComponent from '@a/layouts/DefaultLayout'
import { Layout } from '@a/core/layouts/Layout'
import { Menu } from './menu/Menu'
import { Topbar } from './topbar/Topbar'

class DefaultLayout extends Layout {
	static alias = 'default'
	component = DefaultLayoutComponent

	constructor () {
		super()
		this.menu = new Menu()
		this.topbar = new Topbar()
	}
}

export { DefaultLayout }
