<template>
	<div v-if="modal">
		<div class="modal">
			<base-svg class="modal-close" @click="modal.close()" name="close"/>
			<div class="content">
				<component :is="modal.component" :modal="modal"/>
			</div>
		</div>
		<div class="overlay"></div>
	</div>
</template>

<script>
import BaseSvg from '@a/components/atoms/BaseSvg'
export default {
	computed: {
		modal () {
			return this.$app.modal
		}
	},
	components: {
		BaseSvg
	}
}
</script>

<style lang="scss" scoped>
.modal {
	position: fixed;
	top: 50%;
    z-index: 50;
	left: 50%;
	min-width: 200px;
	min-height: 200px;
	max-height: 90vh;
	max-width: 90vw;
	width: 100%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	background: white;
	border: 1px solid $primary;
	padding: 40px 10px 10px 10px;
	overflow: hidden;
	border-radius: $border-radius;
	justify-content: space-between;
	align-items: center;
	@include media-breakpoint-up(lg) {
		width: fit-content;
	}
	@include media-breakpoint-up(xl) {
		max-width: 1350px;
	}
	.content {
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		@include media-breakpoint-up(lg) {
			overflow-y: initial;
		}
	}
	&-close {
		width: 30px;
		position: absolute;
		top: 5px;
		right: 5px;
		cursor: pointer;
	}
}
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba($white, 0.5);
	z-index: 15;
}

</style>
